import React from "react";
import { Badge, Button } from "antd";
import { IconLibAntD } from "@components/AppFaIcon/icon-lib";
import HeaderDropdown from "@components/HeaderDropdown";
import NotificationList from "@components/RightContent/NotificationDropDown/NotificationList";
import styles from "../index.module.less";

type Props = {};

export const NotificationIconDropdown: React.FC<Props> = ({}: Props) => {
  return (
    <HeaderDropdown overlay={NotificationList} arrow={false}>
      <span className={`${styles.action} ${styles.account}`}>
        <Badge count={5} size={"small"}>
          <IconLibAntD.BellOutlined />
        </Badge>
      </span>
    </HeaderDropdown>
  );
};
