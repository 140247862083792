import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BranchState } from "@store/branch/type";
import { FETCH_STATUS } from "@api";
import { BranchModel } from "../../models/branch.models";
import { FetchBranchDetailDTO } from "@dto/request/branchRequest";

export const initialState: BranchState = {
  fetchStatus: {},
  data: {},
};

export const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    actFetchBranchDetail: (
      state,
      { payload, type }: PayloadAction<FetchBranchDetailDTO>
    ) => {
      state.fetchStatus = {
        ...state.fetchStatus,
        [payload.id]: FETCH_STATUS.LOADING,
      };
    },
    actFetchBranchDetailSuccess: (
      state,
      { payload }: PayloadAction<BranchModel>
    ) => {
      state.fetchStatus = {
        ...state.fetchStatus,
        [payload.id]: FETCH_STATUS.SUCCESS,
      };
      state.data = {
        ...state.data,
        [payload.id]: payload,
      };
    },
    actFetchBranchDetailFailed: (state, { payload }: PayloadAction<string>) => {
      state.fetchStatus = {
        ...state.fetchStatus,
        [payload]: FETCH_STATUS.FAILED,
      };
    },
  },
});

export const {
  actFetchBranchDetail,
  actFetchBranchDetailSuccess,
  actFetchBranchDetailFailed,
} = branchSlice.actions;

export default branchSlice.reducer;
