import React, { useState } from "react";
import { Switch } from "antd";
import { actUpdateCompanySuccess } from "@store/company/companySlice";
import { useAppDispatch } from "@app/hook";
import { asyncUpdateCompany } from "@actions/company.action";

type Props = {
  id: string;
  active: boolean;
};

const SwitchCompanyOnOff: React.FC<Props> = ({ id, active }: Props) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <Switch
      loading={loading}
      defaultChecked={active}
      onChange={async (checked) => {
        setLoading(true);
        try {
          const result = await asyncUpdateCompany(id, { active: checked });
          dispatch(actUpdateCompanySuccess(result));
        } catch (e) {
        } finally {
          setLoading(false);
        }
      }}
    />
  );
};

export default SwitchCompanyOnOff;
