import defaultTranslation from "@ant-design/pro-provider/es/locale/vi_VN";

const translation = defaultTranslation;

translation.tableToolBar = {
  leftPin: "Ghim trái",
  rightPin: "Ghim phải",
  noPin: "Bỏ ghim",
  leftFixedTitle: "Cố định trái",
  rightFixedTitle: "Cố định phải",
  noFixedTitle: "Chưa cố định",
  reset: "Làm lại",
  columnDisplay: "Cột hiển thị",
  columnSetting: "Cấu hình",
  fullScreen: "Chế độ toàn màn hình",
  exitFullScreen: "Thoát chế độ toàn màn hình",
  reload: "Làm mới",
  density: "Mật độ hiển thị",
  densityDefault: "Mặc định",
  densityLarger: "Lớn",
  densityMiddle: "Vừa phải",
  densitySmall: "Thu gọn",
};

translation.pagination = {
  total: {
    range: " ",
    total: "trên",
    item: "mục",
  },
};

translation.moneySymbol = "VNĐ";

translation.tableForm = {
  search: "Tìm kiếm",
  reset: "Làm lại",
  submit: "Gửi đi",
  collapsed: "Mở rộng",
  expand: "Thu gọn",
  inputPlaceholder: "nhập dữ liệu",
  selectPlaceholder: "Vui lòng chọn",
};

translation.alert = {
  clear: "Xóa",
  selected: "đã chọn",
  item: "mục",
};

export default translation;
