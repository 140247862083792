import * as React from "react";
import { Alert, Card, Layout, Result, Typography } from "antd";
import AppFooter from "@components/AppFooter/appFooter";
import { Trans, useTranslation } from "react-i18next";
import AppLogo from "@components/AppLogo";
import { Steps } from "antd";
import {
  MailOutlined,
  KeyOutlined,
  EditOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import styles from "./index.module.less";
import { Link, useSearchParams } from "react-router-dom";
import { pathConfig } from "@config/PathConfig";

const { Footer } = Layout;
const { Step } = Steps;

type Props = {};

type ProcessStepProps = {
  step: number;
};

export const ResetPasswordProcessStep: React.FC<ProcessStepProps> = ({
  step,
}: ProcessStepProps) => {
  const { t } = useTranslation();
  const getStepStatus = (currentStep: number, processingStep: number) =>
    processingStep > currentStep - 1
      ? "finish"
      : processingStep === currentStep - 1
      ? "wait"
      : "wait";
  return (
    <Card bordered={false}>
      <Steps>
        <Step
          status={getStepStatus(1, step)}
          title={t("login.text.step_password_reset_request", {
            ns: "pages",
            defaultValue: "Gửi yêu cầu",
          })}
          icon={<KeyOutlined />}
        />
        <Step
          status={getStepStatus(2, step)}
          title={t("login.text.step_email_verification", {
            ns: "pages",
            defaultValue: "Xác nhận email",
          })}
          icon={<MailOutlined />}
        />
        <Step
          status={getStepStatus(3, step)}
          title={t("login.text.step_enter_new_password_reset_password", {
            ns: "pages",
            defaultValue: "Đặt lại mật khẩu",
          })}
          icon={<EditOutlined />}
        />
        <Step
          status={getStepStatus(4, step)}
          title={t("login.text.step_final_reset_password", {
            ns: "pages",
            defaultValue: "Hoàn thành",
          })}
          icon={<CheckCircleOutlined />}
        />
      </Steps>
    </Card>
  );
};

const AcceptedPasswordResetRequestPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  let [params] = useSearchParams();
  const email = params.get("email") || "";
  const { Text } = Typography;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Layout>
          <div style={{ margin: "50px auto" }}>
            <Card>
              <Result
                // icon={<AppLogo height={100} width={100} />}
                icon={<AppLogo height={80} width={80} />}
                title={
                  <div>
                    Welab
                    <ResetPasswordProcessStep step={2} />
                  </div>
                }
                subTitle={
                  <Alert
                    style={{ width: "90%", margin: "0 auto" }}
                    icon={<MailOutlined />}
                    message={
                      <Trans>
                        {t("login.message.request-password-reset-success", {
                          ns: "pages",
                          defaultValue: "Bạn vừa gửi yêu cầu đổi mật khẩu!",
                        })}
                      </Trans>
                    }
                    description={
                      <Trans>
                        {t(
                          "login.message.check_mail_##user.email##_to_reset_password",
                          {
                            ns: "pages",
                            defaultValue:
                              "Email xác nhận đã được gửi đến:<br><strong>##user.email##</strong><br>Vui lòng kiểm email và làm theo hướng dẫn.",
                            user: {
                              email: email || "you@mail.com",
                            },
                          }
                        )}
                      </Trans>
                    }
                    type="success"
                    showIcon
                  />
                }
                // Style need to be as same as Result in reset-password-screen
                style={{ marginTop: "0", padding: 0 }}
                extra={
                  <>
                    <Text type={"secondary"}>
                      {t("login.text.click-here-to-close-tab", {
                        ns: "pages",
                        defaultValue: "Bạn có thể đóng cửa sổ này",
                      })}
                    </Text>
                    <Link to={`/${pathConfig.account.login.getFullPath}`}>
                      {t("login.text.or-click-here-navigate-to-login-page", {
                        ns: "pages",
                        defaultValue: "hoặc chuyển đến trang đăng nhập",
                      })}
                    </Link>
                  </>
                }
              />
            </Card>
          </div>
          <Footer>
            <AppFooter />
          </Footer>
        </Layout>
      </div>
    </div>
  );
};

export default AcceptedPasswordResetRequestPage;
