import {
  LoadingOutlined,
  LockOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { ProFormCheckbox, ProFormText, LoginForm } from "@ant-design/pro-form";
import styles from "./index.module.less";
import AppFooter from "@components/AppFooter/appFooter";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@app/hook";
import { LoginRequestDTO, RegisterRequestDTO } from "@dto/request/authRequest";
import {
  actLogin,
  actRegister,
  actResetError,
  actSetLoading,
} from "@store/auth/authSlice";
import { getAuthState } from "@selectors";
import useNotAuthenticated from "@hooks/useNotAuthenticated";
import WelcomePage from "@layouts/welcome";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { history } from "../../router/history/browser";
import { Alert, Button } from "antd";
import { pathConfig } from "@config/PathConfig";
import { AuthService } from "@services";
import { AxiosResponse } from "axios";
import { asyncRequestRestPasswordFormSubmit, emailPattern } from "@utils";

type Props = {
  [key: string]: any;
};

const AccountPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const AuthState = useAppSelector(getAuthState);
  const location = useLocation();
  let [params] = useSearchParams();
  const navigate = useNavigate();

  const [isLoginPage, setIsLoginPage] = useState(
    location.pathname !== `/${pathConfig.account.register.getFullPath}` &&
      location.pathname !== `/${pathConfig.account.forgotPassword.getFullPath}`
  );

  const [isForgotPasswordPage, setIsForgotPasswordPage] = useState(
    location.pathname === `/${pathConfig.account.forgotPassword.getFullPath}`
  );

  const [initialForm, setInitialForm] = useState({
    email: params.get("email") || "",
    // password: "",
    displayName: "",
  });

  const [isValidatingEmail, setIsValidatingEmail] = useState(false);

  const handleSubmit = async (request: any) => {
    setInitialForm({
      email: request.email,
      // password: request.password,
      displayName: request.displayName,
    });
    if (isLoginPage) {
      // If the page is login page --> dispatch action login
      dispatch(actLogin(request as LoginRequestDTO));
    } else if (!isForgotPasswordPage) {
      dispatch(actRegister(request as RegisterRequestDTO));
    } else {
      // Send reset password request
      try {
        dispatch(actSetLoading(true));
        await asyncRequestRestPasswordFormSubmit(request.email);
        // history.push(
        //   `/${pathConfig.account.passwordResetRequestAccepted.getFullPath}?email=${request.email}`
        // );
        navigate(
          `/${pathConfig.account.passwordResetRequestAccepted.getFullPath}?email=${request.email}`
        );
      } finally {
        dispatch(actSetLoading(false));
      }
    }
  };

  useNotAuthenticated(
    history.location.pathname +
      (history.location.search ? history.location.search : "")
  );

  if (AuthState.loading) return <WelcomePage />;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LoginForm
          logo={
            <img
              alt="logo"
              src={`${process.env.PUBLIC_URL}/assets/images/logo280.png`}
            />
          }
          title="Welab"
          subTitle="Inspiring the future"
          initialValues={{
            ...initialForm,
          }}
          submitter={{
            submitButtonProps: {
              size: "large",
              style: { width: "100%" },
              loading: AuthState.loading,
            },
            render: (props) => {
              return (
                <>
                  {props.resetButtonProps && (
                    <Button {...props.resetButtonProps} htmlType={"reset"}>
                      {props.searchConfig?.resetText}
                    </Button>
                  )}
                  <Button
                    {...props.submitButtonProps}
                    type={"primary"}
                    htmlType={"submit"}
                  >
                    {props.searchConfig?.submitText}
                  </Button>
                  <Link
                    style={{ lineHeight: 3, float: "right" }}
                    to={
                      isLoginPage
                        ? `/${pathConfig.account.register.getFullPath}`
                        : `/${pathConfig.account.login.getFullPath}`
                    }
                    onClick={() => {
                      setIsLoginPage(!isLoginPage);
                      setIsForgotPasswordPage(false);
                      dispatch(actResetError());
                    }}
                  >
                    {isLoginPage
                      ? t(
                          "login.label.link_have_no_account_click_to_register",
                          {
                            ns: "login",
                            defaultValue:
                              "Chưa có tài khoản? Bấm vào đây để đăng ký",
                          }
                        )
                      : t(
                          "login.label.link_have_no_account_click_to_register",
                          {
                            ns: "login",
                            defaultValue:
                              "Đã có tài khoản? Bấm vào đây để đăng nhập",
                          }
                        )}
                  </Link>
                  {isForgotPasswordPage && (
                    <Link
                      style={{ float: "right" }}
                      to={`/${pathConfig.account.register.getFullPath}`}
                      onClick={() => {
                        setIsLoginPage(false);
                        setIsForgotPasswordPage(false);
                        dispatch(actResetError());
                      }}
                    >
                      {t("login.label.link_have_no_account_click_to_register", {
                        ns: "login",
                        defaultValue:
                          "Chưa có tài khoản? Bấm vào đây để đăng ký",
                      })}
                    </Link>
                  )}
                </>
              );
            },
            resetButtonProps: false,
            searchConfig: {
              resetText: "Nhập lại",
              submitText: isLoginPage
                ? t("login.text.signIn", {
                    ns: "pages",
                    defaultValue: "Đăng nhập",
                  })
                : !isForgotPasswordPage
                ? t("login.text.signUp", {
                    ns: "pages",
                    defaultValue: "Đăng ký",
                  })
                : t("login.text.forgotPassword", {
                    ns: "pages",
                    defaultValue: "Gửi yêu cầu đặt lại mật khẩu",
                  }),
            },
          }}
          onFinish={(value) => handleSubmit(value as LoginRequestDTO)}
        >
          {AuthState.error && (
            <Alert
              style={{ marginBottom: 20 }}
              message={AuthState.error}
              type="error"
              showIcon
              closable
            />
          )}
          <>
            <ProFormText
              name="email"
              fieldProps={{
                size: "large",
                prefix: isValidatingEmail ? (
                  <LoadingOutlined />
                ) : (
                  <MailOutlined className={styles.prefixIcon} />
                ),
              }}
              placeholder={t("login.text.email", {
                ns: "pages",
                defaultValue: "Email",
              })}
              rules={[
                {
                  required: true,
                  message: t("login.rule.email.required", {
                    ns: "pages",
                    defaultValue: "Email là bắt buộc",
                  }),
                },
                {
                  pattern: emailPattern,
                  message: t("login.rule.invalid-email.required", {
                    ns: "pages",
                    defaultValue: "Email không hợp lệ",
                  }),
                },
                {
                  validator: async (rule, value) => {
                    if (isLoginPage || isForgotPasswordPage)
                      return Promise.resolve();
                    if (!emailPattern.test(value)) return Promise.resolve();
                    try {
                      setIsValidatingEmail(true);
                      const { data } = await AuthService.checkEmailAvailable({
                        email: value,
                      });
                      if (data.isValid) return Promise.resolve();
                      return Promise.reject(
                        new Error(
                          t(
                            "login.rule.email-is-not-available-to-register.required",
                            {
                              ns: "pages",
                              defaultValue: "Email này đã được sử dụng",
                            }
                          )
                        )
                      );
                    } catch (e) {
                      return Promise.reject(
                        new Error(
                          t(
                            "login.rule.check-email-available-failed.required",
                            {
                              ns: "pages",
                              defaultValue:
                                "Đã xảy ra lỗi trong quá trình kiểm tra email",
                            }
                          )
                        )
                      );
                    } finally {
                      setIsValidatingEmail(false);
                    }
                  },
                },
              ]}
            />
            {!isLoginPage && !isForgotPasswordPage && (
              <ProFormText
                name="displayName"
                fieldProps={{
                  size: "large",
                  prefix: <UserOutlined className={styles.prefixIcon} />,
                }}
                placeholder={t("login.text.displayName", {
                  ns: "pages",
                  defaultValue: "Tên hiển thị",
                })}
                rules={[
                  {
                    required: true,
                    message: t("login.rule.displayName.required", {
                      ns: "pages",
                      defaultValue: "Tên hiển thị là trường bắt buộc",
                    }),
                  },
                ]}
              />
            )}
            {!isForgotPasswordPage && (
              <ProFormText.Password
                name="password"
                fieldProps={{
                  size: "large",
                  prefix: <LockOutlined className={styles.prefixIcon} />,
                }}
                placeholder={t("login.text.password", {
                  ns: "pages",
                  defaultValue: "Mật khẩu",
                })}
                rules={[
                  {
                    required: true,
                    message: t("login.rule.password.required", {
                      ns: "pages",
                      defaultValue: "Mật khẩu là trường Bắt buộc",
                    }),
                  },
                  {
                    min: 8,
                    message: t("rule.min-##min##-required", {
                      ns: "common",
                      defaultValue: "Tối thiểu ##min## ký tự",
                      min: 8,
                    }),
                  },
                ]}
              />
            )}
            {!isLoginPage && !isForgotPasswordPage && (
              <ProFormText.Password
                name="confirmPassword"
                fieldProps={{
                  size: "large",
                  prefix: <LockOutlined className={styles.prefixIcon} />,
                }}
                placeholder={t("login.placeholder.retype_password_to_confirm", {
                  ns: "pages",
                  defaultValue: "Xác nhận lại mật khẩu",
                })}
                rules={[
                  {
                    required: true,
                    message: t("login.rule.password-confirm.required", {
                      ns: "pages",
                      defaultValue: "Bắt buộc",
                    }),
                  },
                  {
                    min: 8,
                    message: t("rule.min-##min##-required", {
                      ns: "common",
                      defaultValue: "Tối thiểu ##min## ký tự",
                      min: 8,
                    }),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        t("rule.confirm_password_does_not_match", {
                          ns: "common",
                          defaultValue: "Mật khẩu không khớp",
                          min: 8,
                        })
                      );
                    },
                  }),
                ]}
              />
            )}
          </>
          <div
            style={{
              marginBottom: 24,
            }}
          >
            {isLoginPage && !isForgotPasswordPage && (
              <>
                <ProFormCheckbox noStyle name="remember">
                  {t("login.text.remember-me", {
                    ns: "pages",
                    defaultValue: "Ghi nhớ",
                  })}
                </ProFormCheckbox>
                <Link
                  to={`/${pathConfig.account.forgotPassword.getFullPath}`}
                  style={{
                    float: "right",
                  }}
                  onClick={() => {
                    setIsForgotPasswordPage(true);
                    setIsLoginPage(false);
                    dispatch(actResetError());
                  }}
                >
                  {t("login.text.forgot-password", {
                    ns: "pages",
                    defaultValue: "Quên mật khẩu?",
                  })}
                </Link>
              </>
            )}
          </div>
        </LoginForm>
      </div>
      <AppFooter />
    </div>
  );
};

export default AccountPage;
