import * as React from "react";
import { Badge, Card, List, Skeleton } from "antd";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import viVN from "antd/lib/locale/vi_VN";
import { PlanService } from "@services";
import { PaginationDefaultConfig } from "@config/LayoutConfig";
import { PageContainer } from "@ant-design/pro-layout";
import NumberFormat from "react-number-format";
import useSWR from "swr";
import { Fragment } from "react";

type Props = {
  children?: JSX.Element;
};

const FeatureList: React.FC<{ featureIds: string[] }> = ({ featureIds }) => {
  const { data, error } = useSWR(
    "GET/api/v1/statistics/company/",
    () =>
      PlanService.findFeature({
        pageSize: 9999,
        orderBy: "name",
        direction: "ascend",
      }).then((res) => res.data.data),
    {
      revalidateOnFocus: false,
    }
  );
  if (error) return <Badge status="error" text={"Đã có lỗi xảy ra"} />;
  if (!data) return <Skeleton />;
  return (
    <Fragment>
      {data.map(({ id, name }) => (
        <Fragment>
          <Badge
            status={featureIds.includes(id) ? "success" : "default"}
            text={name}
          />
          <br />
        </Fragment>
      ))}
    </Fragment>
  );
};

const columns = (
  handleDeleteRecord?: (id: string) => Promise<any>
): ProColumns[] => [
  {
    title: "STT",
    width: 50,
    align: "center",
    search: false,
    fixed: "left",
    render: (text, record, _) => _ + 1,
  },
  {
    title: "Tên gói dịch vụ",
    dataIndex: "name",
    fixed: "left",
  },
  {
    title: "Người dùng",
    dataIndex: "limits",
    width: 80,
    align: "center",
    renderText: (text, record) => record.limits.user,
  },
  {
    title: "Chi nhánh",
    dataIndex: "limits",
    width: 80,
    align: "center",
    renderText: (text, record) => record.limits.branch,
  },
  {
    title: "Khoa",
    dataIndex: "limits",
    width: 80,
    align: "center",
    renderText: (text, record) => record.limits.department,
  },
  {
    title: "Phòng",
    dataIndex: "limits",
    width: 80,
    align: "center",
    renderText: (text, record) => record.limits.office,
  },
  {
    title: "Các tính năng",
    dataIndex: "features",
    width: 300,
    render: (text, record, _) => (
      <FeatureList
        featureIds={record.features.map((item: any) => item.feature.id)}
      />
    ),
  },
  {
    title: "Giá hàng tháng",
    dataIndex: "monthlyPrice",
    render: (text, record, _) => (
      <NumberFormat
        displayType={"text"}
        value={record.monthlyPrice}
        suffix={" đ/tháng"}
        thousandSeparator={true}
      />
    ),
  },
  {
    title: "Giá hàng năm",
    dataIndex: "yearlyPrice",
    render: (text, record, _) => (
      <NumberFormat
        displayType={"text"}
        value={record.yearlyPrice}
        suffix={" đ/năm"}
        thousandSeparator={true}
      />
    ),
  },
];

export const SubscriptionPage: React.FC<Props> = () => {
  return (
    <PageContainer content="Dashboard" breadcrumbRender={false}>
      <Card>
        <ProTable
          scroll={{ x: 1000 }}
          headerTitle={"Danh sách các gói đăng ký"}
          locale={viVN.Table}
          columnEmptyText={false}
          form={{
            syncToUrl: (values) => {
              values.current = values.current ? values.current : 1;
              values.pageSize = values.pageSize ? values.pageSize : 10;
              return values;
            },
            syncToInitialValues: true,
          }}
          request={async ({ pageSize, current, ...params }, sort) =>
            PlanService.findPlan({
              pageSize,
              current,
              ...params,
            })
              .then((response) => {
                return Promise.resolve(response.data as any);
              })
              .catch((error) => {
                console.log("axios error in fetch hospital", error);
              })
          }
          columns={columns()}
          rowKey={"id"}
          pagination={{
            locale: {
              items_per_page: "mục/trang",
            },
            pageSizeOptions: PaginationDefaultConfig.pageSizeOptions,
          }}
        />
      </Card>
    </PageContainer>
  );
};
