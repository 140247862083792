import * as React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { Card } from "antd";
import ProTable, { ActionType } from "@ant-design/pro-table";
import viVN from "antd/lib/locale/vi_VN";
import { UserService } from "@services";
import { PaginationDefaultConfig } from "@config/LayoutConfig";
import { useRef } from "react";
import { columns } from "@layouts/owner/user/home/table-user";
import { useAppSelector } from "@app/hook";
import { getAuthState } from "@selectors";
import { getSortQuery } from "@utils";

type Props = {};

const AllUserPage: React.FC<Props> = () => {
  const AuthState = useAppSelector(getAuthState);
  const ref = useRef<ActionType>();
  const handleDeleteUser = async (id: string): Promise<any> => {
    console.log(id);
  };

  return (
    <PageContainer content="Người dùng">
      <Card>
        <ProTable
          actionRef={ref}
          headerTitle={"Danh sách User"}
          scroll={{ x: 1200 }}
          locale={viVN.Table}
          columnEmptyText={false}
          request={async ({ pageSize, current, ...params }, sort) =>
            UserService.find({
              pageSize,
              current,
              ...getSortQuery(
                sort["photoURL"] ? { displayName: sort["photoURL"] } : sort
              ),
            })
              .then((response) => {
                return Promise.resolve(response.data as any);
              })
              .catch((error) => {
                console.log("axios error in fetch hospital", error);
              })
          }
          form={{
            syncToUrl: (values) => {
              values.current = values.current ? values.current : 1;
              values.pageSize = values.pageSize ? values.pageSize : 10;
              return values;
            },
            syncToInitialValues: true,
          }}
          columns={columns(handleDeleteUser, AuthState.currentUser)}
          rowKey={"uid"}
          pagination={{
            locale: {
              items_per_page: "mục/trang",
            },
            pageSizeOptions: PaginationDefaultConfig.pageSizeOptions,
          }}
        />
      </Card>
    </PageContainer>
  );
};

export default AllUserPage;
