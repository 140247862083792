import React from "react";
import { Layout, Result } from "antd";
import AppSpinner from "@components/AppSpinner";
import WelabFooter from "@components/AppFooter/appFooter";
import { useTranslation } from "react-i18next";

const { Footer, Content } = Layout;
const WelcomePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout style={{ height: "100vh" }}>
      <Content>
        <Result
          icon={<AppSpinner size="large" />}
          title={"Welab"}
          subTitle={t("layout.redirecting", {
            ns: "common",
            defaultValue: "Đang chuyển hướng...",
          })}
          style={{ marginTop: "5%" }}
        />
      </Content>
      <Footer>
        <WelabFooter />
      </Footer>
    </Layout>
  );
};

export default WelcomePage;
