import { Avatar, Space, Typography } from "antd";
import { blue } from "@ant-design/colors";
import React from "react";
import { APP_NAME } from "@config/AppConfig";

export const HeaderLogo = () => (
  <Space>
    <Avatar src={`/assets/images/logo.png`} />
    <Typography.Text
      strong
      type={"secondary"}
      style={{ color: blue.primary, fontSize: 16, cursor: "pointer" }}
    >
      {APP_NAME}
    </Typography.Text>
  </Space>
);
