import * as React from "react";
import { Routes, Route } from "react-router-dom";
import AllUserPage from "@layouts/owner/user/home";

type Props = {
  children?: JSX.Element;
};

export const UserRoute: React.FC<Props> = () => {
  return (
    <Routes>
      <Route path="" element={<AllUserPage />} />
    </Routes>
  );
};
