import { combineReducers } from "@reduxjs/toolkit";
import AuthReducer from "@store/auth/authSlice";
import CompanyReducer from "@store/company/companySlice";
import BranchReducer from "@store/branch/branchSlice";
import DepartmentReducer from "@store/department/departmentSlice";
import OrganizationReducer from "@store/organization/organizationSlice";
import LayoutReducer from "@store/layout/layoutSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const appReducer = combineReducers({
  Auth: persistReducer(
    {
      key: "auth",
      storage: storage,
      blacklist: ["loading"],
    },
    AuthReducer
  ),
  Company: CompanyReducer,
  Branch: BranchReducer,
  Department: DepartmentReducer,
  Organization: OrganizationReducer,
  Layout: persistReducer(
    {
      key: "layout",
      storage,
    },
    LayoutReducer
  ),
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export default rootReducer;
