import { getAuthState } from "@selectors";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@app/hook";
import { TokenService } from "@services";
import { actGetProfile, actLogout } from "@store/auth/authSlice";
import { pathConfig } from "@config/PathConfig";
import { getPathFromUrl } from "@utils";

const useNotAuthenticated = (returnUrl?: string | undefined) => {
  const AuthState = useAppSelector(getAuthState);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const access_token = TokenService.getLocalAccessToken();

  useEffect(() => {
    if (location.pathname !== `/${pathConfig.account.login.getFullPath}`)
      dispatch(actGetProfile());
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (!AuthState.isAuthenticated && AuthState.registeredUser) {
      navigate(`/${pathConfig.account.welcome.getFullPath}`);
    }
    if (
      location.pathname !== `/${pathConfig.account.login.getFullPath}` &&
      location.pathname !==
        `/${pathConfig.account.forgotPassword.getFullPath}` &&
      location.pathname !== `/${pathConfig.account.register.getFullPath}` &&
      returnUrl ===
        `/${pathConfig.account.passwordResetRequestAccepted.getFullPath}`
    ) {
      navigate(returnUrl);
    }

    if (
      (!access_token || !AuthState.isAuthenticated) &&
      location.pathname !== `/${pathConfig.account.login.getFullPath}` &&
      location.pathname !== `/${pathConfig.account.register.getFullPath}` &&
      location.pathname !== `/${pathConfig.account.forgotPassword.getFullPath}`
    ) {
      if (AuthState.isAuthenticated) dispatch(actLogout());
      navigate(`/${pathConfig.account.login.getFullPath}`);
      return;
    }

    if (AuthState.isAuthenticated && returnUrl) {
      if (
        ![
          `/${pathConfig.account.login.getFullPath}`,
          `/${pathConfig.account.forgotPassword.getFullPath}`,
          `/${pathConfig.account.register.getFullPath}`,
          `/${pathConfig.account.passwordResetRequestAccepted.getFullPath}`,
          `/${pathConfig.account.welcome.getFullPath}`,
          `/${pathConfig.account.resetPassword.getFullPath}`,
          `/${pathConfig.account.welcomeEmailConfirm.getFullPath}`,
        ].includes(getPathFromUrl(returnUrl))
      ) {
        navigate(returnUrl);
      } else {
        AuthState.isOwner &&
          navigate(`/${pathConfig.owner.dashboard.getFullPath}`);
        AuthState.isAdmin &&
          navigate(`/${pathConfig.admin.dashboard.getFullPath}`);
      }
    }
  }, [
    AuthState.isAuthenticated,
    navigate,
    dispatch,
    location.pathname,
    returnUrl,
    AuthState.registeredUser,
    access_token,
    AuthState.isOwner,
    AuthState.isAdmin,
  ]);
};

export default useNotAuthenticated;
