import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Props = {
  icon: IconProp;
  style?: React.CSSProperties;
  className?: string;
};

const AppIcon: React.FC<Props> = ({ icon, style, className }: Props) => {
  return (
    <FontAwesomeIcon
      icon={icon}
      className={(className + " anticon").trim()}
      style={{
        paddingTop: 4,
        ...style,
      }}
    />
  );
};

export default AppIcon;
