import * as React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { Card } from "antd";
import ProTable, { ActionType } from "@ant-design/pro-table";
import viVN from "antd/lib/locale/vi_VN";
import { CompanyService } from "@services";
import { PaginationDefaultConfig } from "@config/LayoutConfig";
import ModalFormAddCompany from "./modal-add-company";
import { columns } from "@layouts/owner/company/sub-pages/table-company";
import { systemMessage } from "@utils";
import { useRef } from "react";

type Props = {};

const AllCompanyPage: React.FC<Props> = () => {
  const ref = useRef<ActionType>();
  const handleDeleteCompany = async (id: string): Promise<any> => {
    const formKey = "ASYNC_ACTION_DELETE_COMPANY";
    try {
      systemMessage.showLoading(formKey);
      const result = await CompanyService.delete(id);
      systemMessage.showSuccess(formKey);
      ref.current?.reload();
      return result.data;
    } catch (error) {
      systemMessage.showError(formKey);
      return Promise.reject(error);
    }
  };

  return (
    <PageContainer
      content="Dashboard"
      extra={[
        <ModalFormAddCompany
          onSuccess={() => ref.current?.reload()}
          key="button-add"
        />,
      ]}
    >
      <Card>
        <ProTable
          actionRef={ref}
          scroll={{ x: 1500 }}
          headerTitle={"Danh sách công ty"}
          locale={viVN.Table}
          columnEmptyText={false}
          request={async ({ pageSize, current, ...params }) =>
            CompanyService.find({
              pageSize,
              current,
              name: params["name"],
              address: params["address"],
              active: params["active"],
              phone: params["phone"],
            })
              .then((response) => Promise.resolve(response.data))
              .catch((error) => {
                console.log("axios error in fetch hospital", error);
              })
          }
          form={{
            syncToUrl: (values) => {
              values.current = values.current ? values.current : 1;
              values.pageSize = values.pageSize ? values.pageSize : 10;
              return values;
            },
            syncToInitialValues: true,
          }}
          columns={columns(handleDeleteCompany)}
          rowKey={"id"}
          pagination={{
            locale: {
              items_per_page: "mục/trang",
            },
            pageSizeOptions: PaginationDefaultConfig.pageSizeOptions,
          }}
        />
      </Card>
    </PageContainer>
  );
};

export default AllCompanyPage;
