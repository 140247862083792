import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { CompanyService } from "@services";
import {
  actFetchOrganizationTree,
  actFetchOrganizationTreeFailed,
  actFetchOrganizationTreeSuccess,
} from "@store/organization/organizationSlice";
import { IOrganizationTree } from "@models/company-organization.type";
import { FetchCompanyDetailDTO } from "@dto/request/companyRequest";

function* fetchOrganization({ payload }: PayloadAction<FetchCompanyDetailDTO>) {
  const { id } = payload;
  try {
    const { data } = yield call(CompanyService.getTreeView, id);
    yield put(
      actFetchOrganizationTreeSuccess({
        id,
        data: data as IOrganizationTree[],
      })
    );
  } catch (error) {
    yield put(actFetchOrganizationTreeFailed(id));
  }
}

function* watchFetchOrganizationTree() {
  yield takeLatest<any>(actFetchOrganizationTree.type, fetchOrganization);
}

export default function* organizationSaga() {
  yield all([fork(watchFetchOrganizationTree)]);
}
