import React from "react";
import ProForm, {
  ProFormDatePicker,
  ProFormDependency,
  ProFormDigit,
  ProFormField,
  ProFormRadio,
} from "@ant-design/pro-form";
import { DATE_TIME_UNIT, MASTERDATA } from "@constants/index";
import { Select } from "antd";
import locale from "antd/es/date-picker/locale/vi_VN";
import { APP_DATE_FORMAT } from "@config/AppConfig";
import moment from "moment";
import { calculateExpirationDate } from "@utils";

type Props = {
  contractTimeUnit: DATE_TIME_UNIT;
  setContractTimeUnit: (timeUnit: DATE_TIME_UNIT) => void;
};

const FormAddContract: React.FC<Props> = ({
  contractTimeUnit,
  setContractTimeUnit,
}: Props) => {
  return (
    <>
      <ProForm.Group>
        <ProFormRadio.Group
          name="licenseType"
          label="Chọn gói đăng ký"
          initialValue={MASTERDATA.LICENSE_TYPE()[0].value}
          request={async () => MASTERDATA.LICENSE_TYPE()}
        />
        <ProFormField>
          <ProFormDigit
            label="Thời hạn"
            name="periodLength"
            // className="custom-width-40"
            initialValue={1}
            min={1}
            max={30}
            style={{ width: 30, textAlign: "center" }}
            rules={[
              {
                required: true,
                message: "Vui lòng nhập khoảng thời gian",
              },
            ]}
            fieldProps={{
              style: { width: 150 },
              addonAfter: (
                <Select
                  // defaultValue={"days" as DATE_TIME_UNIT}
                  value={contractTimeUnit as DATE_TIME_UNIT}
                  style={{ width: 85 }}
                  onChange={(value) =>
                    setContractTimeUnit(value as DATE_TIME_UNIT)
                  }
                >
                  {MASTERDATA.DATE_UNIT().map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              ),
            }}
          />
        </ProFormField>
        <ProFormDatePicker
          fieldProps={{
            locale: locale,
            format: APP_DATE_FORMAT,
            defaultValue: moment(),
            disabledDate: (current) =>
              current && current < moment().startOf("day"),
          }}
          initialValue={moment()}
          name="effectiveDate"
          label="Kể từ ngày"
          placeholder="dd/mm/yyyy"
          rules={[
            {
              required: true,
              message: "Đây là trường bắt buộc",
            },
          ]}
        />
        <ProFormDependency
          name={["effectiveDate", "periodLength"]}
          ignoreFormListField={false}
        >
          {({ effectiveDate, periodLength }) => {
            return (
              <ProFormDatePicker
                fieldProps={{
                  panelRender: () => <></>,
                  locale: locale,
                  format: APP_DATE_FORMAT,
                  value: calculateExpirationDate(
                    moment(effectiveDate, APP_DATE_FORMAT).startOf("day"),
                    periodLength,
                    contractTimeUnit
                  ),
                  disabledDate: (current) =>
                    current && current < moment().startOf("day"),
                  // inputReadOnly: true,
                }}
                allowClear={false}
                name={"expirationDate"}
                label="Đến hết ngày"
                placeholder="dd/mm/yyyy"
                tooltip="Ứng dụng sẽ tự động tính ngày hết hạn hợp đồng"
                readonly
              />
            );
          }}
        </ProFormDependency>
      </ProForm.Group>
    </>
  );
};

export default FormAddContract;
