import React, { useEffect, useRef } from "react";
import { Button, Empty, Popconfirm, Tag } from "antd";
import ProList from "@ant-design/pro-list";
import { useAppDispatch, useAppSelector } from "@app/hook";
import { Link, useNavigate, useParams } from "react-router-dom";
import isUUID from "validator/lib/isUUID";
import OrgBreadcrumb, {
  getLink,
} from "@layouts/owner/company/sub-pages/detail-company/organization/org-breadcrumb";
import { DepartmentService, OfficeService } from "@services";
import { actFetchDepartmentDetail } from "@store/department/departmentSlice";
import { getBranchState, getOrganizationState } from "@selectors";
import { actFetchBranchDetail } from "@store/branch/branchSlice";
import { ActionType } from "@ant-design/pro-table";
import DepartmentTreeSelector from "@layouts/owner/company/sub-pages/detail-company/organization/sub-component/department-tree-selector";
import { IconLibAntD } from "@components/AppFaIcon/icon-lib";
import { vietLocale } from "../../../../../../i18n/vi/lang";
import { OrgType } from "@constants/index";
import ProForm from "@ant-design/pro-form";
import ModalUpsertDepartment from "@layouts/owner/company/sub-pages/detail-company/organization/sub-component/modal-upsert-department";
import { systemMessage } from "@utils";
import { AxiosResponse } from "axios";
import { DepartmentModel } from "@models/department.model";
import { actFetchOrganizationTree } from "@store/organization/organizationSlice";
import ModalUpsertOffice from "@layouts/owner/company/sub-pages/detail-company/organization/sub-component/modal-upsert-office";
import { OfficeModel } from "@models/office.model";

type Props = {
  companyId: string;
  tabKey: string;
};

export const handleDeleteDepartmentOrOffice = async (
  id: string,
  orgType: OrgType.Office | OrgType.Department,
  parentRef?: React.MutableRefObject<ActionType | undefined>,
  reloadOrganizationTree?: () => void | undefined
): Promise<any> => {
  const formKey = `ASYNC_ACTION_DELETE_${orgType}`;

  try {
    systemMessage.showLoading(formKey);
    const result =
      orgType === OrgType.Department
        ? await DepartmentService.delete(id)
        : await OfficeService.delete(id);
    systemMessage.showSuccess(formKey);
    parentRef?.current?.reload();
    reloadOrganizationTree && reloadOrganizationTree();
    return result.data;
  } catch (error) {
    systemMessage.showError(formKey);
    return Promise.reject(error);
  }
};

export const DepartmentOrgActions = (
  row: any,
  companyId: string,
  handleDelete: (
    id: string,
    orgType: OrgType.Office | OrgType.Department,
    parentRef?: React.MutableRefObject<ActionType | undefined>,
    reloadOrganizationTree?: () => void | undefined
  ) => void,
  ref?: React.MutableRefObject<ActionType | undefined>,
  branchId?: string,
  reloadOrganizationTree?: () => void | undefined,
  departmentId?: string
) => {
  return [
    row.org_type === OrgType.Department && (
      <ModalUpsertDepartment
        key={`modal_upsert_department_${row.id}`} // FORM EDIT
        id={row.id}
        trigger={<Button icon={<IconLibAntD.EditOutlined />} />}
        companyId={companyId}
        branchId={branchId}
        parentRef={ref}
        data={row as DepartmentModel}
        reloadOrganizationTree={reloadOrganizationTree}
        departmentId={departmentId}
      />
    ),
    row.org_type === OrgType.Office && (
      <ModalUpsertOffice
        key={`modal_upsert_office_${row.id}`} // FORM EDIT
        id={row.id}
        trigger={<Button icon={<IconLibAntD.EditOutlined />} />}
        companyId={companyId}
        branchId={branchId}
        parentRef={ref}
        data={row as OfficeModel}
        reloadOrganizationTree={reloadOrganizationTree}
        departmentId={departmentId}
      />
    ),
    <Popconfirm
      key={`delete_${row.id}`}
      title={"Bạn có chắc chắn muốn xoá mục này?"}
      placement={"topRight"}
      onConfirm={() => {
        return handleDelete(row.id, row.org_type, ref, reloadOrganizationTree);
      }}
    >
      <Button danger icon={<IconLibAntD.DeleteOutlined />} />
    </Popconfirm>,
  ];
};

const DepartmentOrganization: React.FC<Props> = ({
  companyId,
  tabKey,
}: Props) => {
  const ref = useRef<ActionType>();
  const navigate = useNavigate();
  const { branchId, departmentId } = useParams();
  const dispatch = useAppDispatch();
  const { fetchStatus: branchFetchStatus } = useAppSelector(getBranchState);
  const { fetchStatus: organizationFetchStatus } =
    useAppSelector(getOrganizationState);

  useEffect(() => {
    if (departmentId && isUUID(departmentId))
      dispatch(actFetchDepartmentDetail({ id: departmentId }));
  }, [departmentId, dispatch]);

  useEffect(() => {
    if (branchId && isUUID(branchId) && !branchFetchStatus[branchId])
      dispatch(actFetchBranchDetail({ id: branchId }));
  }, [dispatch, branchFetchStatus, branchId]);

  useEffect(() => {
    ref.current?.reload();
  }, [departmentId]);

  useEffect(() => {
    if (companyId && isUUID(companyId) && !organizationFetchStatus[companyId]) {
      dispatch(actFetchOrganizationTree({ id: companyId }));
    }
  }, [dispatch, organizationFetchStatus, companyId]);

  if (departmentId && !isUUID(departmentId)) return <p>Incorrect object id</p>;

  const treeSelectorOnChange = (value: any, label: any, extra: any) => {
    if (branchId)
      navigate("/" + getLink(tabKey, companyId, branchId, value.value));
  };

  const reloadOrganizationTree = () => {
    dispatch(actFetchOrganizationTree({ id: companyId }));
  };

  return (
    <ProList<any>
      actionRef={ref}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Chưa có đơn vị"
          >
            <Button type="primary" icon={<IconLibAntD.PlusOutlined />}>
              Tạo mới
            </Button>
          </Empty>
        ),
      }}
      toolbar={{
        title: (
          <OrgBreadcrumb
            tabKey={tabKey}
            companyId={companyId}
            branchId={branchId}
            departmentId={departmentId}
          />
        ),
        actions: [
          <DepartmentTreeSelector
            branchId={branchId}
            onChange={treeSelectorOnChange}
            id={departmentId}
          />,
          <ProForm.Item>
            <Button.Group>
              <ModalUpsertOffice
                companyId={companyId}
                departmentId={departmentId}
                branchId={branchId}
                parentRef={ref}
                trigger={
                  <Button icon={<IconLibAntD.PlusOutlined />} type={"primary"}>
                    Phòng
                  </Button>
                }
              />
              <ModalUpsertDepartment
                departmentId={departmentId}
                companyId={companyId}
                parentRef={ref}
                parentId={departmentId}
                branchId={branchId}
                reloadOrganizationTree={reloadOrganizationTree}
                trigger={
                  <Button icon={<IconLibAntD.PlusOutlined />} type={"primary"}>
                    Khoa
                  </Button>
                }
              />
            </Button.Group>
          </ProForm.Item>,
        ],
      }}
      pagination={{
        defaultPageSize: 50,
        showSizeChanger: true,
        hideOnSinglePage: true,
        pageSizeOptions: ["10", "25", "50", "100"],
        locale: {
          ...vietLocale.paginationLocale,
        },
        showTotal: (total, range) => (
          <div>{`Hiển thị ${range[0]}-${range[1]} trong tổng ${total} đơn vị trực thuộc`}</div>
        ),
      }}
      request={async ({ pageSize, current, ...params }) => {
        if (!departmentId) return Promise.resolve([]);
        return DepartmentService.getListChildren(departmentId, {
          pageSize,
          current,
        }).then((response) => response.data);
      }}
      rowKey={(row) => row.id}
      showActions="hover"
      grid={{
        gutter: 16,
        column: 3,
        xs: 1,
        sm: 1,
        md: 2,
        lg: 1,
        xl: 3,
        xxl: 4,
      }}
      onItem={(record: any) => {
        return {
          onMouseEnter: () => {
            // TODO
          },
          onClick: () => {
            // navigate("/" + getLink(tabKey, companyId, branchId, record.id));
            // ref.current?.reload();
          },
        };
      }}
      size={"large"}
      editable={{}}
      metas={{
        title: {
          dataIndex: "name",
          render: (_, row) => {
            if (row.org_type === OrgType.Department)
              return (
                <Link to={"/" + getLink(tabKey, companyId, branchId, row.id)}>
                  {row.name}
                </Link>
              );
            if (row.org_type === OrgType.Office)
              return (
                <Link
                  to={
                    "/" +
                    getLink(tabKey, companyId, branchId, departmentId, row.id)
                  }
                >
                  {row.name}
                </Link>
              );
            return undefined;
          },
        },
        subTitle: {
          render: (_, row) => {
            switch (row.org_type) {
              case OrgType.Office:
                return <Tag color="#5BD8A6">Phòng</Tag>;
              default:
                return undefined;
            }
          },
        },
        type: {},
        avatar: {
          render: (_, row) =>
            "https://gw.alipayobjects.com/zos/antfincdn/UCSiy1j6jx/xingzhuang.svg",
        },
        content: {},
        actions: {
          cardActionProps: "extra",
          render: (text, row) =>
            DepartmentOrgActions(
              row,
              companyId,
              handleDeleteDepartmentOrOffice,
              ref,
              branchId,
              reloadOrganizationTree,
              departmentId
            ),
        },
      }}
    />
  );
};

export default DepartmentOrganization;
