import React from "react";
import { EnumLayout } from "@store/layout/type";
import { actSetLayoutSideMenuCollapsed } from "@store/layout/layoutSlice";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "@app/hook";
import { getLayoutState } from "@selectors";
import styles from "./style.module.less";

type Props = {
  layoutName: EnumLayout;
  children?: React.ReactNode;
};

const HeaderSideMenuCollapseButton: React.FC<Props> = ({
  layoutName,
  children,
}: Props) => {
  const dispatch = useAppDispatch();
  const Layout = useAppSelector(getLayoutState);
  const collapseButtonStyle = { fontSize: 20, marginRight: 15 };
  return (
    <div
      style={{
        cursor: "pointer",
      }}
    >
      <div
        className={`${styles.action}`}
        style={{ marginRight: 15 }}
        onClick={() =>
          dispatch(
            actSetLayoutSideMenuCollapsed({
              layoutName,
            })
          )
        }
      >
        {Layout[layoutName].sideMenu.collapsed ? (
          <MenuUnfoldOutlined style={collapseButtonStyle} />
        ) : (
          <MenuFoldOutlined style={collapseButtonStyle} />
        )}
        {children}
      </div>
    </div>
  );
};

export default HeaderSideMenuCollapseButton;
