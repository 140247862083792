import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EnumLayout, LayoutState } from "@store/layout/type";

export const initialState: LayoutState = {
  [EnumLayout.ADMIN]: {
    sideMenu: {
      collapsed: false,
    },
  },
  [EnumLayout.OWNER]: {
    sideMenu: {
      collapsed: false,
    },
  },
};

export const layoutSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    actSetLayoutSideMenuCollapsed: (
      state,
      { payload }: PayloadAction<{ layoutName: EnumLayout }>
    ) => {
      state[payload.layoutName].sideMenu.collapsed =
        !state[payload.layoutName].sideMenu.collapsed;
    },
  },
});

export const { actSetLayoutSideMenuCollapsed } = layoutSlice.actions;

export default layoutSlice.reducer;
