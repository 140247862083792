import React from "react";
import { DashboardOutlined } from "@ant-design/icons";
import { APP_NAME } from "@config/AppConfig";
import {
  buildPath,
  pathConfig,
  SECOND_LEVEL,
  THIRD_LEVEL,
  TOP_LEVEL,
} from "@config/PathConfig";
import i18n from "@i18n";
import MenuIcon from "@components/MenuIcon";
import AppFaIcon from "@components/AppFaIcon";
import { iconLib, IconLibAntD } from "@components/AppFaIcon/icon-lib";
import { faHospitalUser } from "@fortawesome/free-solid-svg-icons";
import { Route } from "@ant-design/pro-layout/es/typings";

const defaultProps = {
  title: APP_NAME,
  logo: false,
  route: {
    path: pathConfig.admin.getFullPath,
    routes: [
      {
        path: pathConfig.admin.dashboard.getFullPath,
        name: "Bảng điều khiển",
        icon: <DashboardOutlined />,
      },
      {
        path: buildPath([TOP_LEVEL.ADMIN, "branch"]).getFullPath,
        name: "Chi nhánh",
        icon: <AppFaIcon icon={iconLib.faSitemap} />,
      },
      {
        path: buildPath([TOP_LEVEL.ADMIN, SECOND_LEVEL.CATEGORY]).getFullPath,
        name: "Danh mục",
        icon: <IconLibAntD.UnorderedListOutlined />,
        navigateTo: buildPath([SECOND_LEVEL.CATEGORY, THIRD_LEVEL.TITLE])
          .getFullPath,
      },
      {
        path: buildPath([TOP_LEVEL.ADMIN, SECOND_LEVEL.GALLERY]).getFullPath,
        name: "Thư viện",
        icon: <IconLibAntD.FileImageOutlined />,
      },
      {
        path: buildPath([TOP_LEVEL.ADMIN, "billings"]).getFullPath,
        name: "Thanh toán & hoá đơn",
        icon: <AppFaIcon icon={iconLib.faReceipt} />,
      },
      {
        path: pathConfig.admin.settings.getFullPath,
        name: "Cấu hình",
        icon: <IconLibAntD.SettingOutlined />,
      },
      {
        path: pathConfig.admin.profile.getFullPath,
        name: i18n.t("screen.profile", {
          ns: "common",
          defaultValue: "Tài khoản",
        }),
        icon: (
          <MenuIcon children={<AppFaIcon icon={iconLib.faAddressCard} />} />
        ),
      },
    ],
  } as Route,
  location: {
    pathname: pathConfig.admin.getFullPath,
  },
};

export default defaultProps;
