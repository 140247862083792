import React from "react";
import { Tabs } from "antd";
import ProList from "@ant-design/pro-list";
import styles from "./NotificationList.module.less";

const { TabPane } = Tabs;

const dataSource = [
  {
    name: "A1",
    image:
      "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
    desc: "B1",
  },
  {
    name: "A2",
    image:
      "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
    desc: "B2",
  },
];

type Props = {};
const NotificationList = (
  <ProList<any>
    className={styles.list}
    onRow={(record: any) => {
      return {
        onMouseEnter: () => {
          console.log(record);
        },
        onClick: () => {
          console.log(record);
        },
      };
    }}
    rowKey="name"
    headerTitle="Thông báo"
    tooltip="Thông báo"
    dataSource={dataSource}
    showActions="hover"
    showExtra="hover"
    metas={{
      title: {
        dataIndex: "name",
      },
      avatar: {
        dataIndex: "image",
      },
    }}
  />
);

export default NotificationList;
