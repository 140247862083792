import * as React from "react";
import ProForm, { ProFormText } from "@ant-design/pro-form";
import { useTranslation } from "react-i18next";
import { asyncChangePasswordFormSubmit } from "@utils";
import { actLogout } from "@store/auth/authSlice";
import { useState } from "react";
import { ChangePasswordDTO } from "@dto/request/authRequest";
import { useAppDispatch } from "@app/hook";

type Props = {};

const TabSecutiry: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const handleFormSubmit = async (formData: Record<string, any>) => {
    try {
      setLoading(true);
      await asyncChangePasswordFormSubmit(formData as ChangePasswordDTO);
      dispatch(actLogout());
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <ProForm
      layout="vertical"
      onFinish={handleFormSubmit}
      submitter={{
        searchConfig: {
          submitText: t("layout.text.change_password", {
            ns: "common",
            defaultValue: "Đổi mật khẩu",
          }),
          resetText: t("layout.text.reset_form", {
            ns: "common",
            defaultValue: "Nhập lại",
          }),
        },
      }}
    >
      <ProFormText.Password
        width="md"
        name="currentPassword"
        requiredMark
        disabled={loading}
        placeholder={t("layout.placeholder.enter_current_password", {
          ns: "common",
          defaultValue: "Nhập mật khẩu hiện tại",
        })}
        label={t("layout.label.current_password", {
          ns: "common",
          defaultValue: "Mật khẩu hiện tại",
        })}
        rules={[
          {
            required: true,
            message: t("rule.current-password-required", {
              ns: "common",
              defaultValue: "Bắt buộc",
            }),
          },
        ]}
      />
      <ProFormText.Password
        width="md"
        name="password"
        requiredMark
        disabled={loading}
        label={t("layout.label.new_password", {
          ns: "common",
          defaultValue: "Mật khẩu mới",
        })}
        placeholder={t("layout.place_holder.new_password", {
          ns: "common",
          defaultValue: "Nhập mật khẩu mới",
        })}
        rules={[
          {
            required: true,
            message: t("rule.new-password-required", {
              ns: "common",
              defaultValue: "Bắt buộc",
            }),
          },
          {
            min: 8,
            message: t("rule.min-##min##-required", {
              ns: "common",
              defaultValue: "Tối thiểu ##min## ký tự",
              min: 8,
            }),
          },
        ]}
      />
      <ProFormText.Password
        width="md"
        name="confirm_password"
        requiredMark
        required
        disabled={loading}
        label={t("layout.label.confirm_new_password", {
          ns: "common",
          defaultValue: "Xác nhận mật khẩu mới",
        })}
        placeholder={t("layout.place_holder.confirm_new_password", {
          ns: "common",
          defaultValue: "Nhập lại mật khẩu mới để xác nhận",
        })}
        rules={[
          {
            required: true,
            message: t("rule.password-confirm-required", {
              ns: "common",
              defaultValue: "Bắt buộc",
            }),
          },
          {
            min: 8,
            message: t("rule.min-##min##-required", {
              ns: "common",
              defaultValue: "Tối thiểu ##min## ký tự",
              min: 8,
            }),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                t("rule.confirm_password_does_not_match", {
                  ns: "common",
                  defaultValue: "Mật khẩu không khớp",
                })
              );
            },
          }),
        ]}
      />
    </ProForm>
  );
};

export default TabSecutiry;
