import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FETCH_STATUS } from "@api";
import { OrganizationState } from "@store/organization/type";
import { FetchCompanyDetailDTO } from "@dto/request/companyRequest";
import { IOrganizationTree } from "@models/company-organization.type";

export const initialState: OrganizationState = {
  fetchStatus: {},
  data: {},
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    actFetchOrganizationTree: (
      state,
      { payload }: PayloadAction<FetchCompanyDetailDTO>
    ) => {
      state.fetchStatus = {
        ...state.fetchStatus,
        [payload.id]: FETCH_STATUS.LOADING,
      };
    },
    actFetchOrganizationTreeSuccess: (
      state,
      { payload }: PayloadAction<{ id: string; data: IOrganizationTree[] }>
    ) => {
      state.fetchStatus = {
        ...state.fetchStatus,
        [payload.id]: FETCH_STATUS.SUCCESS,
      };
      state.data = {
        ...state.data,
        [payload.id]: {
          ...state.data[payload.id],
          ...payload.data.reduce(
            (obj, item) => ({
              ...obj,
              [item.id]: item,
            }),
            {}
          ),
        },
      };
    },
    actFetchOrganizationTreeFailed: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.fetchStatus = {
        ...state.fetchStatus,
        [payload]: FETCH_STATUS.FAILED,
      };
    },
  },
});

export const {
  actFetchOrganizationTree,
  actFetchOrganizationTreeSuccess,
  actFetchOrganizationTreeFailed,
} = organizationSlice.actions;

export default organizationSlice.reducer;
