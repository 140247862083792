import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyState } from "@store/company/type";
import { FetchCompanyDetailDTO } from "@dto/request/companyRequest";
import { CompanyModel } from "../../models/company.model";
import { FETCH_STATUS } from "@api";

export const initialState: CompanyState = {
  fetchStatus: {},
  data: {},
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    actFetchCompanyDetail: (
      state,
      { payload, type }: PayloadAction<FetchCompanyDetailDTO>
    ) => {
      state.fetchStatus = {
        ...state.fetchStatus,
        [payload.id]: FETCH_STATUS.LOADING,
      };
    },
    actFetchCompanySuccess: (
      state,
      { payload, type }: PayloadAction<CompanyModel>
    ) => {
      state.fetchStatus = {
        ...state.fetchStatus,
        [payload.id]: FETCH_STATUS.SUCCESS,
      };
      state.data = {
        ...state.data,
        [payload.id]: payload,
      };
    },
    actFetchCompanyFailed: (state, { payload }: PayloadAction<string>) => {
      state.fetchStatus = {
        ...state.fetchStatus,
        [payload]: FETCH_STATUS.FAILED,
      };
      state.data = {
        ...state.data,
      };
    },
    actUpdateCompanySuccess: (
      state,
      { payload, type }: PayloadAction<CompanyModel>
    ) => {
      state.fetchStatus = {
        ...state.fetchStatus,
        [payload.id]: FETCH_STATUS.SUCCESS,
      };
      state.data = {
        ...state.data,
        [payload.id]: payload,
      };
    },
  },
});

export const {
  actFetchCompanyDetail,
  actFetchCompanySuccess,
  actUpdateCompanySuccess,
  actFetchCompanyFailed,
} = companySlice.actions;

export default companySlice.reducer;
