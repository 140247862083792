import React from "react";
import styles from "./style.module.less";
import { Avatar, Badge, Skeleton, Statistic, Tag } from "antd";
import { useAppSelector } from "@app/hook";
import { getAuthState } from "@selectors";
import { AuthUser } from "@store/auth/type";
import useSWR from "swr";
import { StatisticsService } from "@services";

type Props = {};

const RoleTags: React.FC<{ user: AuthUser }> = ({ user }) => {
  const roles = [];
  if (user.isOwner) {
    roles.push({
      text: "Owner",
      color: "red",
    });
  }
  if (user.isAdmin) {
    roles.push({
      text: "Admin",
      color: "green",
    });
  }
  return (
    <>
      {roles.map((item) => (
        <Tag key={item.text} style={{ marginLeft: 10 }} color={"green"}>
          {item.text}
        </Tag>
      ))}
    </>
  );
};

const PageHeaderContent: React.FC<Props> = () => {
  const { currentUser, loading } = useAppSelector(getAuthState);
  if (!currentUser) {
    return <Skeleton avatar paragraph={{ rows: 1 }} active />;
  }

  return (
    <div className={styles.pageHeaderContent}>
      <div className={styles.avatar}>
        <Avatar size="large" src={currentUser.photoURL} />
      </div>
      <div className={styles.content}>
        <div className={styles.contentTitle}>
          <Badge color="green" />
          Xin chào, {currentUser.displayName}
          <RoleTags user={currentUser} />
        </div>
        <div>To do</div>
      </div>
    </div>
  );
};

export const HeaderProfile = {
  PageHeaderContent,
};
