import React from "react";
import { DefaultFooter } from "@ant-design/pro-layout";
import { useTranslation } from "react-i18next";
import { APP_ABOUT_US_URL, APP_TERM_OF_SERVICE_URL } from "@config/AppConfig";

const AppFooter: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <DefaultFooter
      copyright={`${currentYear} Thien Nguyen Pro`}
      links={[
        {
          key: "about-us",
          title: t("label.about-us", {
            ns: "common",
            defaultValue: "Về chúng tôi",
          }),
          href: APP_ABOUT_US_URL,
          blankTarget: true,
        },
        {
          key: "term",
          title: t("label.terms-of-service", {
            ns: "common",
            defaultValue: "Điều khoản sử dụng",
          }),
          href: APP_TERM_OF_SERVICE_URL,
          blankTarget: true,
        },
      ]}
    />
  );
};

export default AppFooter;
