import React from "react";
import "./App.less";
import { Routes, Route, Navigate } from "react-router-dom";
import PageError404 from "@layouts/404";
import AccountPage from "@layouts/account";
import {
  RequireAdminPermission,
  RequireAuth,
  RequireOwnerPermission,
} from "./router/RequireAuth";
import AdminLayout from "@layouts/admin";
import OwnerLayout from "@layouts/owner";
import { pathConfig } from "@config/PathConfig";
import WelcomeUserPage from "@layouts/account/welcome-user";
import AcceptedPasswordResetRequestPage from "@layouts/account/accepted-request-password-reset";
import ResetPasswordPage from "@layouts/account/password-reset-screen";
import PasswordChangedSuccessPage from "@layouts/account/announce-password-reset-success";
import UnauthorizedAccessPage from "@layouts/401_unauthorized";
import { useAppSelector } from "@app/hook";
import { getAuthState } from "@selectors";

function App() {
  const AuthState = useAppSelector(getAuthState);
  return (
    <div>
      <Routes>
        <Route path={`/${pathConfig.account.getFullPath}`}>
          <Route
            path={`${pathConfig.account.login.getSortPath}_after`}
            element={<AccountPage />}
          />
          <Route
            path={`${pathConfig.account.login.getSortPath}`}
            element={<AccountPage />}
          />
          <Route
            path={`${pathConfig.account.register.getSortPath}`}
            element={<AccountPage />}
          />
          <Route
            path={`${pathConfig.account.forgotPassword.getSortPath}`}
            element={<AccountPage />}
          />
          <Route
            path={`${pathConfig.account.welcome.getSortPath}`}
            element={<WelcomeUserPage step={2} />}
          />
          <Route
            path={`${pathConfig.account.welcomeEmailConfirm.getSortPath}`}
            element={<WelcomeUserPage step={3} />}
          />
          <Route
            path={`${pathConfig.account.passwordResetRequestAccepted.getSortPath}`}
            element={<AcceptedPasswordResetRequestPage />}
          />
          <Route
            path={`${pathConfig.account.resetPassword.getSortPath}`}
            element={<ResetPasswordPage />}
          />
          <Route
            path={`${pathConfig.account.passwordResetSuccess.getSortPath}`}
            element={<PasswordChangedSuccessPage />}
          />
        </Route>
        <Route
          path={`/${pathConfig.admin.getFullPath}/*`}
          element={
            <RequireAuth>
              <RequireAdminPermission>
                <AdminLayout />
              </RequireAdminPermission>
            </RequireAuth>
          }
        />
        <Route
          path={`/${pathConfig.owner.getFullPath}/*`}
          element={
            <RequireAuth>
              <RequireOwnerPermission>
                <OwnerLayout />
              </RequireOwnerPermission>
            </RequireAuth>
          }
        />
        <Route
          path={`/${pathConfig.error.unauthorized.getFullPath}/*`}
          element={<UnauthorizedAccessPage />}
        />
        <Route
          path="/*"
          element={
            AuthState.isOwner || AuthState.isAdmin ? (
              <Navigate
                replace
                to={`/${
                  pathConfig[AuthState.isOwner ? "owner" : "admin"].dashboard
                    .getFullPath
                }`}
              />
            ) : (
              <PageError404 />
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;
