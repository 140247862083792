import React from "react";
import ProLayout, { PageContainer } from "@ant-design/pro-layout";
import { iconLib, IconLibAntD } from "@components/AppFaIcon/icon-lib";
import { buildPath, SECOND_LEVEL, TOP_LEVEL } from "@config/PathConfig";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Menu } from "antd";
import { TabHospitalInformation } from "@layouts/admin/setting/system/general-settings-page";
import AppFaIcon from "@components/AppFaIcon";

type Props = {};
const tabList = [
  {
    key: "hospital",
    tab: "Bệnh viện",
    element: <TabHospitalInformation />,
    icon: <AppFaIcon icon={iconLib.faHouseChimneyMedical} />,
  },
  {
    key: "roles-and-permissions",
    tab: "Nhóm & Phân quyền",
    element: <p>Trang 2</p>,
    icon: <AppFaIcon icon={iconLib.faUserShield} />,
  },
  {
    key: "system",
    tab: "Hệ thống",
    element: <IconLibAntD.ToolOutlined />,
    icon: <AppFaIcon icon={iconLib.faScrewdriverWrench} />,
  },
];

export const SettingGeneralPage: React.FC<Props> = () => {
  const { tabKey } = useParams();
  const navigate = useNavigate();

  const handleTabChange = (key: string) => {
    navigate(
      `/${buildPath([TOP_LEVEL.ADMIN, SECOND_LEVEL.SETTINGS]).getFullPath}${
        key !== tabList[0].key ? `/${key}` : ""
      }`
    );
  };

  const getActiveTabKey = (): { key: string; tab: string; icon?: any } => {
    const activeTabKey = tabList.find((item) => tabKey?.includes(item.key));
    if (activeTabKey)
      return {
        ...activeTabKey,
      };
    return { ...tabList[0] };
  };

  return (
    <ProLayout
      logo={<IconLibAntD.SettingOutlined style={{ width: 32 }} />}
      title={"Cấu hình"}
      navTheme={"light"}
      headerRender={false}
      disableContentMargin={true}
      disableMobile={true}
      isChildrenLayout={true}
      menuItemRender={(item, dom) => (
        <Link key={item.path} to={item.path || ""}>
          {dom}
        </Link>
      )}
      menuContentRender={() => (
        <Menu
          onClick={(e) => {
            handleTabChange(e.key);
          }}
          selectedKeys={[getActiveTabKey().key]}
          mode="inline"
          items={tabList.map(({ tab, key, icon }) => ({
            label: tab,
            key,
            icon: icon || <IconLibAntD.PlusOutlined />,
          }))}
        />
      )}
      contentStyle={{
        // background: "white",
        borderLeft: "1px solid rgb(0 21 41 / 10%)",
      }}
    >
      <PageContainer
        title={getActiveTabKey().tab}
        avatar={{
          icon: getActiveTabKey().icon || <IconLibAntD.SettingOutlined />,
        }}
        breadcrumbRender={false}
        // ghost={true}
        tabProps={{
          type: "card",
          size: "small",
        }}
        tabActiveKey={getActiveTabKey().key}
        onTabChange={handleTabChange}
        style={{
          height: "100%",
        }}
        ghost={true}
        // content={}
      >
        <Routes>
          {tabList.map(({ key, element }) => (
            <Route
              path={key === tabList[0].key ? "/" : key}
              element={element}
              key={key}
            />
          ))}
        </Routes>
      </PageContainer>
    </ProLayout>
  );
};
