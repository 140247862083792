import api, { IApiResponse } from "./api";
import {
  CreateCompanyRequestDTO,
  FindCompanyRequestDTO,
  UpdateCompanyRequestDTO,
} from "@dto/request/companyRequest";
import { IGetCompanyDataResponseDTO } from "@dto/response/company.response";

export const CompanyService = {
  find: (data: FindCompanyRequestDTO) => {
    return api
      .callWithToken()
      .get("/api/v1/company", {
        params: data,
      })
      .then((res) => res.data);
  },
  get: async (
    id: string
  ): Promise<IApiResponse<IGetCompanyDataResponseDTO>> => {
    return api
      .callWithToken()
      .get(`/api/v1/company/${id}`)
      .then((res) => res.data);
  },
  getTreeView: (id: string) => {
    return api
      .callWithToken()
      .get(`/api/v1/company/${id}/tree-view`)
      .then((res) => res.data);
  },
  create: (data: CreateCompanyRequestDTO) => {
    return api
      .callWithToken()
      .post("/api/v1/company", {
        ...data,
      })
      .then((res) => res.data);
  },
  delete: (id: string) => {
    return api
      .callWithToken()
      .delete(`/api/v1/company/${id}`)
      .then((res) => res.data);
  },
  update: (id: string, data: UpdateCompanyRequestDTO) => {
    return api
      .callWithToken()
      .patch(`/api/v1/company/${id}`, data)
      .then((res) => res.data);
  },
};
