import * as React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import ProLayout from "@ant-design/pro-layout";
import defaultProps from "./_defaultProps";
import GlobalHeaderRight from "@components/RightContent";
import {
  buildPath,
  pathConfig,
  SECOND_LEVEL,
  TOP_LEVEL,
} from "@config/PathConfig";
import useNotAuthenticated from "@hooks/useNotAuthenticated";
import { ProfilePage } from "@pages/common/ProfilePage";
import { CompanyRoute } from "@layouts/owner/company";
import { UserRoute } from "@layouts/owner/user";
import AppFooter from "@components/AppFooter/appFooter";
import { useAppDispatch, useAppSelector } from "@app/hook";
import { getLayoutState } from "@selectors";
import HeaderSideMenuCollapseButton from "@components/HeaderSideMenuCollapseButton";
import { EnumLayout } from "@store/layout/type";
import { actSetLayoutSideMenuCollapsed } from "@store/layout/layoutSlice";
import DashboardPage from "@layouts/owner/dashboard";
import { SubscriptionPage } from "@layouts/owner/subscription";

type Props = {
  children?: JSX.Element;
};

const OwnerLayout: React.FC<Props> = () => {
  useNotAuthenticated();
  const location = useLocation();
  const Layout = useAppSelector(getLayoutState);
  const dispatch = useAppDispatch();

  return (
    <ProLayout
      {...defaultProps}
      menuItemRender={(item, dom) => <Link to={item.path || ""}>{dom}</Link>}
      fixSiderbar
      fixedHeader
      rightContentRender={() => <GlobalHeaderRight />}
      locale="en-US"
      location={{ pathname: location.pathname }}
      footerRender={() => <AppFooter />}
      navTheme={"light"}
      collapsed={Layout.owner.sideMenu.collapsed}
      onCollapse={() =>
        dispatch(
          actSetLayoutSideMenuCollapsed({
            layoutName: EnumLayout.OWNER,
          })
        )
      }
      headerContentRender={() => (
        <HeaderSideMenuCollapseButton layoutName={EnumLayout.OWNER} />
      )}
      collapsedButtonRender={false}
    >
      <Routes>
        <Route
          path={pathConfig.owner.dashboard.getSortPath}
          element={<DashboardPage />}
        />
        <Route
          path={pathConfig.owner.company.getSortPath + "/*"}
          element={<CompanyRoute />}
          // element={<p>Text</p>}
        />
        <Route
          path={
            buildPath([TOP_LEVEL.OWNER, SECOND_LEVEL.SUBSCRIPTION])
              .getSortPath + "/*"
          }
          element={<SubscriptionPage />}
        />
        <Route
          path={pathConfig.owner.user.getSortPath + "/*"}
          element={<UserRoute />}
          // element={<p>Text</p>}
        />
        <Route
          path={pathConfig.owner.profile.getSortPath}
          element={<ProfilePage />}
        />
      </Routes>
    </ProLayout>
  );
};

export default OwnerLayout;
