import { Badge, Dropdown, Space } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import React from "react";
import Avatar from "./AvatarDropdown";
import HeaderSearch from "../HeaderSearch";
import styles from "./index.module.less";
import { useTranslation } from "react-i18next";
import { APP_HELP_URL } from "@config/AppConfig";
import AppClock from "@components/AppClock";
import AppFaIcon from "@components/AppFaIcon";
import { iconLib, IconLibAntD } from "@components/AppFaIcon/icon-lib";
import { NotificationIconDropdown } from "@components/RightContent/NotificationDropDown";

const GlobalHeaderRight: React.FC = () => {
  const { t } = useTranslation();
  let className = styles.right;
  return (
    <Space className={className}>
      <HeaderSearch
        className={`${styles.action} ${styles.search}`}
        placeholder={t("layout.enter_keyword_to_search", {
          ns: "common",
          defaultValue: "Nhập từ khóa để tìm kiếm",
        })}
        // defaultValue="umi ui"
        options={[
          {
            label: <a href="/admin/settings">Cài đặt</a>,
            value: "Hướng dẫn sử dụng",
          },
        ]}
        // onSearch={value => {
        //   console.log('input', value);
        // }}
      />
      <span
        className={styles.action}
        onClick={() => {
          window.open(APP_HELP_URL);
        }}
      >
        <QuestionCircleOutlined />
      </span>
      <AppClock hideDate={true} />
      <NotificationIconDropdown />
      <Avatar menu={true} />
    </Space>
  );
};
export default GlobalHeaderRight;
