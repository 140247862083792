import * as React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { HeaderProfile } from "@components/HeaderProfile";

type Props = {};

const DashboardPage: React.FC<Props> = () => (
  <PageContainer title={false} content={<HeaderProfile.PageHeaderContent />} />
);

export default DashboardPage;
