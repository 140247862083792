import React from "react";
import { APP_LOGO_URL, APP_NAME } from "@config/AppConfig";
import {
  buildPath,
  pathConfig,
  SECOND_LEVEL,
  THIRD_LEVEL,
  TOP_LEVEL,
} from "@config/PathConfig";
import i18n from "@i18n";
import MenuIcon from "@components/MenuIcon";
import { iconLib } from "@components/AppFaIcon/icon-lib";
import AppFaIcon from "@components/AppFaIcon";

const defaultProps = {
  title: APP_NAME,
  logo: APP_LOGO_URL || `/assets/images/logo.png`,
  style: {
    minHeight: "100vh",
  },
  route: {
    path: pathConfig.owner.getFullPath,
    routes: [
      {
        path: pathConfig.owner.dashboard.getFullPath,
        name: i18n.t("screen.dashboard", {
          ns: "common",
          defaultValue: "Trang chủ",
        }),
        icon: <MenuIcon children={<AppFaIcon icon={iconLib.faHouse} />} />,
      },
      {
        path: pathConfig.owner.company.getFullPath,
        name: i18n.t("screen.hospital", {
          ns: "common",
          defaultValue: "Công ty",
        }),
        icon: <MenuIcon children={<AppFaIcon icon={iconLib.faBuilding} />} />,
      },
      {
        path: buildPath([TOP_LEVEL.OWNER, SECOND_LEVEL.SUBSCRIPTION])
          .getFullPath,
        name: "Gói đăng ký",
        icon: <MenuIcon children={<AppFaIcon icon={iconLib.faCubes} />} />,
      },
      {
        path: pathConfig.owner.user.getFullPath,
        name: i18n.t("screen.user", {
          ns: "common",
          defaultValue: "Người dùng",
        }),
        icon: <MenuIcon children={<AppFaIcon icon={iconLib.faUsers} />} />,
      },
      {
        path: pathConfig.owner.profile.getFullPath,
        name: i18n.t("screen.profile", {
          ns: "common",
          defaultValue: "Tài khoản",
        }),
        icon: (
          <MenuIcon children={<AppFaIcon icon={iconLib.faAddressCard} />} />
        ),
      },
      {
        path: pathConfig.owner.settings.getFullPath,
        name: i18n.t("screen.settings", {
          ns: "common",
          defaultValue: "Cài đặt",
        }),
        icon: <MenuIcon children={<AppFaIcon icon={iconLib.faGear} />} />,
      },
    ],
  },
  location: {
    pathname: pathConfig.owner.getFullPath,
  },
};

export default defaultProps;
