import api, { IApiResponse } from "./api";
import { IGetCompanyStatisticsData } from "@dto/response/statistics.response";

export const StatisticsService = {
  getCompanyStatisticsData: async (
    id: string
  ): Promise<IApiResponse<IGetCompanyStatisticsData>> => {
    return api
      .callWithToken()
      .get(`/api/v1/statistics/company/${id}`)
      .then((res) => res.data);
  },
};
