import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import ProLayout, { PageContainer } from "@ant-design/pro-layout";
import useNotAuthenticated from "@hooks/useNotAuthenticated";
import {
  Affix,
  Avatar,
  Breadcrumb,
  Button,
  Divider,
  Empty,
  Image,
  Layout,
  Menu,
  Space,
  Tooltip,
  Tree,
  Typography,
} from "antd";
import { IconLibAntD } from "@components/AppFaIcon/icon-lib";
import ProCard from "@ant-design/pro-card";
import HeaderSearch from "@components/HeaderSearch";
import ProList from "@ant-design/pro-list";

type Props = {
  children?: JSX.Element;
};

const CommonGalleryPage: React.FC<Props> = () => {
  useNotAuthenticated();
  const location = useLocation();
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  useLayoutEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
  });
  return (
    <ProLayout
      logo={false}
      fixSiderbar
      locale="en-US"
      fixedHeader={true}
      location={{ pathname: location.pathname }}
      headerRender={false}
      // footerRender={() => <AppFooter />}
      navTheme={"light"}
      disableMobile={true}
      disableContentMargin={true}
      isChildrenLayout={false}
      layout={"top"}
    >
      <ProLayout
        title={"Thư viện"}
        logo={
          <Space>
            <IconLibAntD.FileImageOutlined style={{ width: 16 }} />
          </Space>
        }
        style={{ zIndex: 0 }}
        navTheme={"light"}
        fixedHeader={true}
        headerRender={(props, defaultDom) => (
          <div style={{ marginTop: 48, marginLeft: 48 }}>{defaultDom}</div>
        )}
        rightContentRender={() => [
          <HeaderSearch key={"header-search-toolbar-button"} options={[]} />,
          <Menu
            key={"menu-toolbar"}
            style={{ maxWidth: 90 }}
            mode={"horizontal"}
            items={[
              {
                key: "thumbnail",
                icon: <IconLibAntD.AppstoreOutlined />,
                style: { width: "25%" },
              },
              {
                key: "sort_by",
                icon: <IconLibAntD.SortAscendingOutlined />,
                style: { width: "25%" },
                children: [
                  {
                    type: "group",
                    children: [
                      {
                        key: "sort-by-name",
                        label: "Theo tên",
                        icon: <IconLibAntD.CheckOutlined />,
                      },
                      {
                        key: "sort-by-uploaded-date",
                        label: "Theo ngày tải lên",
                        icon: (
                          <Avatar
                            style={{
                              opacity: 0,
                            }}
                            size={10}
                          />
                        ),
                      },
                    ],
                  },

                  {
                    dashed: true,
                    type: "divider",
                  },
                  {
                    type: "group",
                    children: [
                      {
                        key: "sort-asc",
                        label: "Tăng dần",
                        icon: <IconLibAntD.SortAscendingOutlined />,
                      },
                    ],
                  },
                ],
              },
            ]}
            selectable={false}
            // select
          ></Menu>,
        ]}
        headerContentRender={() => (
          <Menu
            selectable={false}
            mode="horizontal"
            items={[
              {
                key: "create_new_folder",
                icon: (
                  <Tooltip
                    title="Tạo thư mục mới"
                    key="FolderAddOutlined"
                    placement={"bottom"}
                  >
                    <IconLibAntD.FolderAddOutlined />
                  </Tooltip>
                ),
                style: { width: 50 },
              },
              {
                // label: "Tải lên",
                key: "upload",
                icon: (
                  <Tooltip
                    title="Tải lên"
                    key="CloudUploadOutlined"
                    placement={"bottom"}
                  >
                    <IconLibAntD.CloudUploadOutlined />
                  </Tooltip>
                ),
                style: { width: 50 },
              },
              {
                // label: "Tải lên",
                key: "rename",
                icon: (
                  <Tooltip
                    title="Đổi tên"
                    key="EditOutlined"
                    placement={"bottom"}
                  >
                    <IconLibAntD.EditOutlined />
                  </Tooltip>
                ),
                style: { width: 50 },
              },
              {
                // label: "Xoá",
                key: "app",
                icon: (
                  <Tooltip
                    title="Xoá"
                    key="DeleteOutlined"
                    placement={"bottom"}
                  >
                    <IconLibAntD.DeleteOutlined />
                  </Tooltip>
                ),
                danger: true,
                style: { width: 50 },
              },
            ]}
          />
        )}
        breakpoint={"sm"}
        menuRender={(props, defaultDom) => (
          <Affix offsetTop={48}>
            <div style={{ minHeight: "100vh" }}>{defaultDom}</div>
          </Affix>
        )}
        menuContentRender={(props, defaultDom) => (
          <div
            style={{
              marginLeft: 5,
              marginRight: 5,
              minHeight: windowHeight - 120,
              maxHeight: windowHeight - 120,
            }}
          >
            <Tree.DirectoryTree
              // height={window.innerHeight - 120}
              defaultExpandedKeys={["0-0-0"]}
              treeData={[
                {
                  title: "parent 1",
                  key: "0-0",
                  children: [
                    {
                      title: "parent 1-0",
                      key: "0-0-0",
                      children: [
                        {
                          title: "leaf",
                          key: "0-0-0-0",
                        },
                        {
                          title: "leaf",
                          key: "0-0-0-1",
                        },
                        {
                          title: "leaf",
                          key: "0-0-0-2",
                        },
                      ],
                    },
                    {
                      title: "parent 1-1",
                      key: "0-0-1",
                      children: [
                        {
                          title: "leaf",
                          key: "0-0-1-0",
                        },
                      ],
                    },
                    {
                      title: "parent 1-2",
                      key: "0-0-2",
                      children: [
                        {
                          title: "leaf",
                          key: "0-0-2-0",
                        },
                        {
                          title: "leaf",
                          key: "0-0-2-1",
                        },
                      ],
                    },
                  ],
                },
              ]}
            />
          </div>
        )}
        disableContentMargin={true}
      >
        <PageContainer
          title={false}
          breadcrumbRender={() => (
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <IconLibAntD.HomeFilled />
              </Breadcrumb.Item>
              <Breadcrumb.Item href="">Ảnh gia đình</Breadcrumb.Item>
              <Breadcrumb.Item href="">Lễ vu lan 2022</Breadcrumb.Item>
            </Breadcrumb>
          )}
        >
          <ProList
            rowKey={"id"}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Chưa có đơn vị"
                >
                  <Button type="primary" icon={<IconLibAntD.PlusOutlined />}>
                    Tạo mới
                  </Button>
                </Empty>
              ),
            }}
            grid={{
              gutter: 1,
              column: 3,
              xs: 2,
              sm: 2,
              md: 3,
              lg: 3,
              xl: 5,
              xxl: 6,
            }}
            ghost={true}
            dataSource={[
              {
                id: "1",
                name: "Team building 2022",
                image:
                  "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
                desc: "我是一条测试的描述",
              },
              {
                id: "2",
                name: "Thiện",
                image:
                  "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
                desc: "我是一条测试的描述",
              },
              {
                id: "3",
                name: "CMC Global",
                image:
                  "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
                desc: "我是一条测试的描述",
              },
              {
                id: "4",
                name: "FPT Software",
                image:
                  "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
                desc: "我是一条测试的描述",
              },
              {
                id: "5",
                name: "Linh tinh",
                image:
                  "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
                desc: "我是一条测试的描述",
              },
              {
                id: "6",
                name: "Ghi chú",
                image:
                  "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
                desc: "我是一条测试的描述",
              },
              {
                id: "7",
                name: "Cà rốt",
                image:
                  "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
                desc: "我是一条测试的描述",
              },
              {
                id: "8",
                name: "Company Trip",
                image:
                  "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
                desc: "我是一条测试的描述",
              },
              {
                id: "9",
                name: "Tài liệu",
                image:
                  "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
                desc: "我是一条测试的描述",
              },
              {
                id: "10",
                name: "Công nghệ",
                image:
                  "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
                desc: "我是一条测试的描述",
              },
              {
                id: "11",
                name: "Học tập",
                image:
                  "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
                desc: "我是一条测试的描述",
              },
              {
                id: "12",
                name: "Lập trình JS",
                image:
                  "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
                desc: "我是一条测试的描述",
              },
            ]}
            itemCardProps={{
              ghost: true,
              hoverable: false,
            }}
            metas={{
              content: {
                dataIndex: "name",
                render: (dom, entity, index, action, schema) => (
                  <ProCard
                    layout={"center"}
                    ghost={true}
                    hoverable={false}
                    // style={{ width: 100, height: 100 }}
                  >
                    <Space direction={"vertical"}>
                      <Image
                        src={`${process.env.PUBLIC_URL}/assets/icons/folder.svg`}
                        preview={{
                          mask: entity.name,
                          onVisibleChange: (value, prevValue) =>
                            console.log(entity),
                          visible: false,
                        }}
                      />
                      <p style={{ textAlign: "center" }}>{entity.name}</p>
                    </Space>
                  </ProCard>
                ),
              },
              description: {
                dataIndex: "name",
              },
            }}
          ></ProList>
        </PageContainer>
      </ProLayout>
    </ProLayout>
  );
};

export default CommonGalleryPage;
