import React, { useEffect } from "react";
import { Alert, Card, Layout, Result } from "antd";
import WelabFooter from "@components/AppFooter/appFooter";
import { Trans, useTranslation } from "react-i18next";
import AppLogo from "@components/AppLogo";
import { Steps } from "antd";
import {
  UserOutlined,
  LoadingOutlined,
  SmileOutlined,
  MailOutlined,
} from "@ant-design/icons";
import styles from "./index.module.less";
import { useAppDispatch, useAppSelector } from "@app/hook";
import { getAuthState } from "@selectors";
import { Link, useSearchParams } from "react-router-dom";
import { pathConfig } from "@config/PathConfig";
import { actConfirmEmail, actRegisterWelcome } from "@store/auth/authSlice";
import { Spin } from "antd";

const { Footer } = Layout;
const { Step } = Steps;

type ProcessStepProps = {
  step: number;
  loading: boolean;
  isError: boolean;
};

const SignUpAccountProgress: React.FC<ProcessStepProps> = ({
  step,
  loading,
  isError = false,
}: ProcessStepProps) => {
  const getStepStatus = (currentStep: number, processingStep: number) =>
    isError && currentStep === processingStep
      ? "error"
      : processingStep > currentStep - 1
      ? "finish"
      : processingStep === currentStep - 1
      ? "wait"
      : "wait";
  return (
    <Card bordered={false}>
      <Steps>
        <Step
          status={getStepStatus(1, step)}
          title="Tạo tài khoản"
          icon={<UserOutlined />}
        />
        <Step
          status={getStepStatus(2, step)}
          title="Xác nhận email"
          icon={step === 2 && loading ? <LoadingOutlined /> : <MailOutlined />}
        />
        <Step
          status={getStepStatus(3, step)}
          title="Hoàn thành"
          icon={step === 3 && loading ? <LoadingOutlined /> : <SmileOutlined />}
        />
      </Steps>
    </Card>
  );
};

type Props = {
  step: number;
};

const WelcomeUserPage: React.FC<Props> = ({ step }) => {
  const { t } = useTranslation();
  const AuthState = useAppSelector(getAuthState);
  let [params] = useSearchParams();
  const email = params.get("email") || "";
  const uid = params.get("uid") || "";
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (uid && email) dispatch(actConfirmEmail({ uid }));
  }, [dispatch, uid, email]);

  const ConfirmingEmailSubComponent: React.FC = () => (
    <>
      <Spin
        tip={t("login.text.confirming-email", {
          ns: "pages",
          defaultValue: "##app.name## đang kích hoạt tài khoản của bạn...",
        })}
      />
    </>
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Layout>
          {/*<Content>
          <Result
            // icon={<AppLogo height={100} width={100} />}
            icon={<SignUpAccountProgress />}
            title={"Welab"}
            subTitle={<SignUpAccountProgress />}
            style={{ marginTop: "5%" }}
          />
        </Content>*/}
          <div style={{ margin: "50px auto" }}>
            <Card>
              <Result
                // icon={<AppLogo height={100} width={100} />}
                icon={<AppLogo height={80} width={80} />}
                title={
                  <div>
                    Welab
                    <SignUpAccountProgress
                      step={step}
                      loading={AuthState.loading}
                      isError={!!AuthState.error}
                    />
                  </div>
                }
                subTitle={
                  <>
                    {step === 2 && (
                      <Alert
                        style={{ width: "90%", margin: "0 auto" }}
                        icon={<MailOutlined />}
                        message={
                          <Trans>
                            {t(
                              "login.message.welcome_user_##user.displayName##_to_##app.name##",
                              {
                                ns: "pages",
                                defaultValue:
                                  "Chào mừng <strong>##user.displayName##</strong> đến với ##app.name##",
                                user: {
                                  displayName:
                                    AuthState?.registeredUser?.displayName ||
                                    "displayName",
                                },
                              }
                            )}
                          </Trans>
                        }
                        description={
                          <Trans>
                            {t(
                              "login.message.check_mail##user.email##_to_##app.name##",
                              {
                                ns: "pages",
                                defaultValue:
                                  "Email xác nhận đăng ký đã được gửi đến:<br><strong>##user.email##</strong><br>Kiểm tra email làm theo hướng dẫn để kích hoạt.",
                                user: {
                                  email:
                                    AuthState?.registeredUser?.email ||
                                    "you@mail.com",
                                },
                              }
                            )}
                          </Trans>
                        }
                        type="success"
                        showIcon
                      />
                    )}
                    {step === 3 && !AuthState.loading && !AuthState.error && (
                      <Alert
                        style={{ width: "90%", margin: "0 auto" }}
                        icon={<MailOutlined />}
                        message={
                          <Trans>
                            {t(
                              "login.message.welcome_user_title_after_confirmation",
                              {
                                ns: "pages",
                                defaultValue:
                                  "Xin chào <strong>##user.displayName##</strong>, <br> Tài khoản của bạn đã được kích hoạt thành công",
                                user: {
                                  displayName:
                                    AuthState?.confirmedUser?.displayName ||
                                    "displayName",
                                },
                              }
                            )}
                          </Trans>
                        }
                        /*description={
                          <Trans>
                            {t(
                              "login.message.welcome_message_after_confirmation",
                              {
                                ns: "pages",
                                defaultValue:
                                  "Email xác nhận đăng ký đã được gửi đến:<br><strong>##user.email##</strong><br>Kiểm tra email làm theo hướng dẫn để kích hoạt.",
                                user: {
                                  email:
                                    AuthState?.registeredUser?.email ||
                                    "you@mail.com",
                                },
                              }
                            )}
                          </Trans>
                        }*/
                        type="success"
                        showIcon
                      />
                    )}
                    {step === 3 && !AuthState.loading && AuthState.error && (
                      <Alert
                        style={{ width: "90%", margin: "0 auto" }}
                        message={AuthState.error}
                        type="error"
                        showIcon
                        closable
                      />
                    )}
                  </>
                }
                style={{ marginTop: "5%" }}
                extra={
                  AuthState.loading ? (
                    <ConfirmingEmailSubComponent />
                  ) : (
                    <Link
                      to={`/${pathConfig.account.login.getFullPath}?email=${
                        AuthState?.[
                          step === 2 ? "registeredUser" : "confirmedUser"
                        ]?.email ||
                        email ||
                        ""
                      }`}
                      onClick={() => dispatch(actRegisterWelcome())}
                    >
                      {step === 2 &&
                        t("", {
                          ns: "login.text.email-confirm-take-to-login",
                          defaultValue:
                            "Tôi đã kích hoạt tài khoản email, hãy đưa tôi đến trang đăng nhập",
                        })}
                      {step === 3 && !AuthState.error
                        ? t("", {
                            ns: "login.text.email-confirm-success-navigate-to-sign-in",
                            defaultValue: "Bấm vào đây để đăng nhập",
                          })
                        : t("", {
                            ns: "login.text.email-confirm-failed",
                            defaultValue: "",
                          })}
                    </Link>
                  )
                }
              />
            </Card>
          </div>
          <Footer>
            <WelabFooter />
          </Footer>
        </Layout>
      </div>
    </div>
  );
};

export default WelcomeUserPage;
