import { UpdateCompanyRequestDTO } from "@dto/request/companyRequest";
import { systemMessage } from "@utils";
import { AxiosResponse } from "axios";
import { CompanyService } from "@services";

export const asyncUpdateCompany = async (
  id: string,
  data: UpdateCompanyRequestDTO
) => {
  const formKey = "ASYNC_UPDATE_COMPANY" + new Date().getTime();
  try {
    systemMessage.showLoading(formKey);
    const result = await CompanyService.update(id, {
      active: data.active,
    });
    systemMessage.showSuccess(formKey);
    return Promise.resolve(result.data);
  } catch (error) {
    systemMessage.showError(formKey);
    return Promise.reject(error);
  }
};
