import { PageContainer } from "@ant-design/pro-layout";
import React, { useRef } from "react";
import { Button, Popconfirm, Typography } from "antd";
import { ActionType, EditableProTable } from "@ant-design/pro-table";
import viVN from "antd/lib/locale/vi_VN";
import { IconLibAntD } from "@components/AppFaIcon/icon-lib";
import { CategoryService } from "@services";
import { PaginationDefaultConfig } from "@config/LayoutConfig";
import { NEW_RECORD_PREFIX } from "@constants/index";
import { getSortQuery, systemMessage } from "@utils";
import {
  CreateCategoryTitleRequestDTO,
  UpdateCategoryTitleRequestDTO,
} from "@dto/request/categoryRequest";

interface IUpdateFormData {
  name?: string;
}

interface ICreateFormData {
  name: string;
}

export const CategoryTabTitle = () => {
  const ref = useRef<ActionType>();
  const handleUpdateTitle = async (id: string, { name }: IUpdateFormData) => {
    const formKey = `ASYNC_ACTION_UPDATE_TITLE_${id}`;
    try {
      systemMessage.showLoading(formKey);
      await CategoryService.title.update(id, {
        name,
      });
      systemMessage.showSuccess(formKey);
    } catch (error) {
      systemMessage.showError(formKey);
    }
    ref.current?.reload();
  };

  const handleCreateTitle = async ({ name }: ICreateFormData) => {
    const formKey = `ASYNC_ACTION_CREATE_TITLE`;
    try {
      systemMessage.showLoading(formKey);
      await CategoryService.title.create({
        name: name,
      });
      systemMessage.showSuccess(formKey);
    } catch (error) {
      systemMessage.showError(formKey);
    }
    ref.current?.reload();
  };

  const handleDeleteTitle = async (id: string) => {
    const formKey = `ASYNC_ACTION_CREATE_TITLE`;
    try {
      systemMessage.showLoading(formKey);
      await CategoryService.title.delete(id);
      systemMessage.showSuccess(formKey);
    } catch (error) {
      systemMessage.showError(formKey);
    }
    ref.current?.reload();
  };

  return (
    <PageContainer
      title={"Chức vụ nhân viên"}
      // ghost={false}
      content={
        <Typography.Text type="secondary">
          Để việc quản lý nhân sự được thuận tiện và dẽ dàng, hãy liệt kê các
          chức vụ tại đây.
          <br />
          Ví dụ: Nhân viên tư vấn, Kỹ thuật viên, Bác sĩ, Bảo vệ, Thu ngân, ...
        </Typography.Text>
      }
    >
      <EditableProTable
        actionRef={ref}
        scroll={{ x: 700 }}
        rowKey={"id"}
        headerTitle="Danh sách chức vụ nhân viên"
        request={async ({ pageSize, current, ...params }, sort) =>
          CategoryService.title
            .find({
              pageSize,
              current,
              ...getSortQuery(sort),
              name: params["name"],
            })
            .then((response) => Promise.resolve(response.data))
            .catch((error) => {
              console.log("axios error in fetch category title", error);
            })
        }
        columns={[
          {
            title: "STT",
            width: 50,
            align: "center",
            editable: false,
            render: (text, record, _, action) => _ + 1,
          },
          {
            dataIndex: "name",
            title: "Tên chức vụ",
            align: "left",
            valueType: "text",
            sorter: true,
            formItemProps: {
              required: true,
              rules: [
                {
                  required: true,
                  message: "Trường bắt buộc",
                },
              ],
            },
          },
          {
            title: "Ngày tạo",
            key: "createdAt",
            dataIndex: "createdAt",
            valueType: "dateTime",
            editable: false,
          },
          {
            title: "Ngày sửa đổi",
            key: "updatedAt",
            dataIndex: "updatedAt",
            valueType: "dateTime",
            editable: false,
          },
          {
            title: "Thao tác",
            valueType: "option",
            align: "left",
            width: 150,
            render: (text, record, _, action) => [
              <Button
                type="link"
                key="editable"
                className="padding-0"
                onClick={() => {
                  action?.startEditable?.(record.id);
                }}
                icon={<IconLibAntD.EditOutlined />}
              />,
              <Popconfirm
                key={`delete_${record.id}`}
                title={"Bạn có chắc chắn muốn xoá mục này?"}
                placement={"topRight"}
                onConfirm={() => handleDeleteTitle(record.id)}
              >
                <Button
                  className="padding-0"
                  type="link"
                  danger={true}
                  key="delete"
                  icon={<IconLibAntD.DeleteOutlined />}
                />
              </Popconfirm>,
            ],
          },
        ]}
        locale={{ ...viVN.Table }}
        options={{
          reload: true,
        }}
        recordCreatorProps={{
          position: "bottom",
          record: () => ({
            id: `${NEW_RECORD_PREFIX}_uuidv4()`,
            name: "",
            createdAt: new Date(),
          }),
          creatorButtonText: "Thêm mới",
          type: "dashed",
        }}
        pagination={{
          locale: {
            items_per_page: "mục/trang",
          },
          position: ["bottomRight"], //'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight'
          pageSizeOptions: PaginationDefaultConfig.pageSizeOptions,
        }}
        editable={{
          onlyAddOneLineAlertMessage: "Chỉ được thêm một dòng",
          onlyOneLineEditorAlertMessage: "Chỉ được sửa một dòng",
          cancelText: (
            <Button
              type="link"
              key="button-cancel"
              className="padding-0"
              icon={<IconLibAntD.RollbackOutlined />}
            />
          ),
          deleteText: (
            <Button
              type="link"
              key="button-delete"
              danger={true}
              className="padding-0"
              icon={<IconLibAntD.DeleteOutlined />}
            />
          ),
          saveText: (
            <Button
              type="link"
              key="button-save"
              className="padding-0"
              icon={<IconLibAntD.SaveOutlined />}
            />
          ),
          // actionRender: (row, config, defaultDom) => [
          //   defaultDom.save,
          //   defaultDom.cancel,
          // ],
          deletePopconfirmMessage: "Bạn có muốn xoá mục này",
          onDelete: async (key, row) => {
            await handleDeleteTitle(row.id);
          },
          onSave: async (key, record, originRow, newLineConfig) => {
            if (record.id.includes(NEW_RECORD_PREFIX)) {
              // New record
              await handleCreateTitle(record);
            } else {
              // Update current record
              await handleUpdateTitle(record.id, record);
            }
          },
        }}
      ></EditableProTable>
    </PageContainer>
  );
};
