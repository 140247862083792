import { message } from "antd";
import { AuthService } from "@services";
import { t } from "i18next";
import { AxiosError, AxiosResponse } from "axios";
import { HTTP_COMM } from "@constants/api";
import { ChangePasswordDTO } from "@dto/request/authRequest";
import { keyValuePairTranslation } from "@constants/translation";
import moment from "moment";
import { DATE_TIME_UNIT } from "@constants/index";
import { SortingQuery } from "../interfaces/ModalMeta.interface";

export const systemMessage = {
  showLoading: (key: string, customMessage?: string) => {
    message.loading({
      content: customMessage
        ? customMessage
        : t("message.updating_data", {
            ns: "common",
            defaultValue: "Đang cập nhật dữ liệu!",
          }),
      key,
    });
  },
  showError: (key: string, customMessage?: string) => {
    message.error({
      content: customMessage
        ? customMessage
        : t("message.updating_data_failed", {
            ns: "common",
            defaultValue: "Cập nhật dữ liệu thất bại...",
          }),
      key,
    });
  },
  showSuccess: (key: string, customMessage?: string) => {
    message.success({
      content: customMessage
        ? customMessage
        : t("message.updating_data_success", {
            ns: "common",
            defaultValue: "Đã cập nhật",
          }),
      key,
    });
  },
  showWarning: (key: string, customMessage?: string) => {
    message.warning({
      content: customMessage
        ? customMessage
        : t("message.updating_data_warning", {
            ns: "common",
            defaultValue: "Dữ liệu cập nhật không cho phép",
          }),
      key,
    });
  },
};

const unknownErrorMessage = t("error.unknown", {
  ns: "common",
  defaultValue:
    "Xảy ra lỗi không xác định, vui lòng liên hệ quản trị viên để khắc phục",
});

export const getTranslation = (key: string, defaultValue?: string) => {
  if (keyValuePairTranslation[key]) {
    return keyValuePairTranslation[key];
  }
  return defaultValue ? defaultValue : unknownErrorMessage;
};

export const asyncUpdateProfileFormSubmit = async (
  formData: Record<string, any>
) => {
  const formKey = "USER_SUBMIT_FORM_UPDATE_PROFILE";
  try {
    systemMessage.showLoading(formKey);
    const data = await AuthService.updateProfile(formData);
    systemMessage.showSuccess(formKey);
    return data;
  } catch (e) {
    systemMessage.showError(formKey);
    return Promise.reject(e);
  }
};

export const asyncChangePasswordFormSubmit = async (
  formData: ChangePasswordDTO
) => {
  const formKey = "USER_SUBMIT_FORM_CHANGE_PASSWORD";
  try {
    systemMessage.showLoading(formKey);
    await AuthService.changePassword(formData as ChangePasswordDTO);
    //
    systemMessage.showSuccess(
      formKey,
      t("message.change_password_success", {
        ns: "common",
        defaultValue: "Mật khẩu được cập nhật, vui lòng đăng nhập lại",
      })
    );
  } catch (error: any) {
    const key = error?.response?.data?.error || "unknown";
    systemMessage.showError(formKey, getTranslation(key));
    return Promise.reject(error);
  }
};

export const asyncRequestRestPasswordFormSubmit = async (email: string) => {
  const formKey = "USER_SUBMIT_FORM_REQUEST_PASSWORD_RESET";
  try {
    systemMessage.showLoading(
      formKey,
      t("message.sending_password_reset_request", {
        ns: "common",
        defaultValue: "Đang gửi yêu cầu đặt lại mật khẩu...",
      })
    );
    const result = await AuthService.requestPasswordReset(email);
    //
    systemMessage.showSuccess(
      formKey,
      t("message.request_password_reset_success", {
        ns: "common",
        defaultValue:
          "Hoàn tất yêu cầu! một email hướng dẫn đổi mật khẩu sẽ gửi vào hộp thư của bạn",
      })
    );
    return result;
  } catch (error: any) {
    const key = error?.response?.data?.error || "unknown";
    systemMessage.showError(formKey, getTranslation(key));
    return Promise.reject(error as AxiosError);
  }
};

export const asyncResetPasswordFormSubmit = async (
  code: string,
  password: string
) => {
  const formKey = "USER_SUBMIT_FORM_RESET_PASSWORD";
  try {
    systemMessage.showLoading(
      formKey,
      t("message.password-resetting", {
        ns: "common",
        defaultValue: "Đang lưu mật khẩu mới...",
      })
    );
    const result: AxiosResponse = await AuthService.resetPassword(
      code,
      password
    );
    //
    systemMessage.showSuccess(
      formKey,
      t("message.reset-password-success", {
        ns: "common",
        defaultValue:
          "Đã cập nhật dữ liệu, bạn có thể đăng nhập bằng mật khẩu mới",
      })
    );
    return result;
  } catch (error: any) {
    const key = error?.response?.data?.error || "unknown";
    const errorMessage = getTranslation(key);
    systemMessage.showError(formKey);
    return Promise.reject(errorMessage);
  }
};

export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getPathFromUrl = (url: string) => url.split(/[?#]/)[0];

export const calculateExpirationDate = (
  effectiveDate: any,
  periodLength: number,
  contractTimeUnit: DATE_TIME_UNIT
) => {
  return (
    (effectiveDate.isValid() ? effectiveDate : moment().startOf("days"))
      .add(periodLength || 1, contractTimeUnit)
      .subtract(1, "days") as moment.Moment
  ).endOf("day");
};

export const linkify = (url: string) => {
  //URLs starting with http://, https://, or ftp://
  // var replacePattern1 =
  //   /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  // //URLs starting with www. (without // before it, or it'd re-link the ones done above)
  const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
  const replacedText = url.replace(replacePattern2, "https://$2");

  return replacedText;
};

export const getContractStatus = (
  effectiveDate: string,
  expirationDate: string
) => {
  if (moment().isBefore(effectiveDate)) return "Chưa hiệu lực";
  if (moment().isAfter(expirationDate)) return "Hết hiệu lực";
  return "Đang hiệu lực";
};

export const renderCascaderData = (
  arr: any[],
  level: number = 0,
  valueKeyName: string = "id",
  labelKeyName: string = "name",
  childrenKeyName: string = "children"
): any[] => {
  const result = arr.map((item) => {
    return {
      label: item[labelKeyName],
      value: item[valueKeyName],
      children: renderCascaderData(item[childrenKeyName], level),
    };
  });
  return result;
};

export const getSortQuery = (sort: Record<string, any>): SortingQuery => {
  return {
    orderBy: Object.keys(sort)[0],
    direction: Object.values(sort)[0],
  };
};

export const removeEmpty = (obj: any) => {
  let newObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};

export const truncateWithEllipses = (
  text: String,
  max: number,
  hellip: string = "..."
) => {
  return text.substr(0, max - 1) + (text.length > max ? hellip : "");
};
