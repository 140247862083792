import api, { clientURL, IApiResponse } from "./api";
import {
  ChangePasswordDTO,
  CheckEmailAvailableDTO,
  confirmEmailRequestDTO,
  LoginRequestDTO,
  RegisterRequestDTO,
} from "@dto/request/authRequest";
import { pathConfig } from "@config/PathConfig";

export const AuthService = {
  login: (data: LoginRequestDTO) => {
    return api
      .call()
      .post("/api/v1/account/sign-in", data)
      .then((res) => res.data);
  },
  register: (data: RegisterRequestDTO) => {
    return api
      .call()
      .post("/api/v1/account/sign-up", {
        ...data,
        clientURL: `${clientURL}/${pathConfig.account.confirmEmail.getFullPath}`,
      })
      .then((res) => res.data);
  },
  confirmEmail: (data: confirmEmailRequestDTO) => {
    return api
      .call()
      .get("/api/v1/account/confirm-email", {
        params: data,
      })
      .then((res) => res.data);
  },
  getProfile: (): Promise<IApiResponse<any>> => {
    return api
      .callWithToken()
      .get("/api/v1/account/profile")
      .then((res) => res.data);
  },
  updateProfile: (data: Record<any, string>) => {
    return api
      .callWithToken()
      .put("api/v1/account/profile", data)
      .then((res) => res.data);
  },
  changePassword: (data: ChangePasswordDTO) => {
    return api
      .callWithToken()
      .put("api/v1/account/change-password", data)
      .then((res) => res.data);
  },
  requestPasswordReset: (email: string) => {
    return api
      .call()
      .post("api/v1/account/forgot-password", {
        email,
        clientURL: `${clientURL}/${pathConfig.account.resetPassword.getFullPath}`,
      })
      .then((res) => res.data);
  },
  resetPassword: (code: string, password: string) => {
    return api
      .call()
      .post("api/v1/account/reset-password", {
        code,
        password,
      })
      .then((res) => res.data);
  },
  checkEmailAvailable: ({ email }: CheckEmailAvailableDTO) => {
    return api
      .call()
      .get("api/v1/account/check-email-available", {
        params: {
          email,
        },
      })
      .then((res) => res.data);
  },
};
