import React from "react";
import { HeaderProfile } from "@components/HeaderProfile";
import { PageContainer } from "@ant-design/pro-layout";
import { useAppSelector } from "@app/hook";
import { getAuthState } from "@selectors";
import useSWR from "swr";
import { StatisticsService } from "@services";
import styles from "@components/HeaderProfile/style.module.less";
import { Statistic } from "antd";

type Props = {};
const ExtraContent: React.FC<Record<string, any>> = () => {
  const { currentUser, loading } = useAppSelector(getAuthState);
  const { data, error } = useSWR(
    () =>
      currentUser?.company?.id
        ? `GET/api/v1/statistics/company/${currentUser.company.id}`
        : null,
    () =>
      currentUser?.company?.id
        ? StatisticsService.getCompanyStatisticsData(currentUser.company.id)
        : null
  );

  return (
    <div className={styles.extraContent}>
      <div className={styles.statItem}>
        <Statistic
          loading={!data?.data?._count_branch}
          title="Chi nhánh"
          value={data?.data?._count_branch.toString().padStart(2, "0")}
        />
      </div>
      <div className={styles.statItem}>
        <Statistic
          loading={!data?.data?._count_department}
          title="Khoa"
          value={data?.data?._count_department.toString().padStart(2, "0")}
        />
      </div>
      <div className={styles.statItem}>
        <Statistic
          loading={!data?.data?._count_office}
          title="Phòng"
          value={data?.data?._count_office.toString().padStart(2, "0")}
        />
      </div>
    </div>
  );
};
const DashboardPage: React.FC<Props> = () => (
  <PageContainer
    title={false}
    content={<HeaderProfile.PageHeaderContent />}
    extraContent={<ExtraContent />}
  />
);

export default DashboardPage;
