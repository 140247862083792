import { all, fork } from "redux-saga/effects";
import authSaga from "@sagas/authSaga";
import companySaga from "@sagas/companySaga";
import branchSaga from "@sagas/branchSaga";
import departmentSaga from "@sagas/departmentSaga";
import organizationSaga from "@sagas/organizationSaga";

export default function* rootSaga() {
  yield all([
    fork(companySaga),
    fork(authSaga),
    fork(branchSaga),
    fork(departmentSaga),
    fork(organizationSaga),
  ]);
}
