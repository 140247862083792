import api from "./api";
import {
  CreateDepartmentDTO,
  GetChildDepartmentDTO,
  GetDepartmentOfficeDTO,
  GetListChildrenDTO,
  UpdateDepartmentDTO,
} from "@dto/request/departmentRequest";

export const DepartmentService = {
  get: (id: string) => {
    return api
      .callWithToken()
      .get(`/api/v1/department/${id}`)
      .then((res) => res.data);
  },
  create: (data: CreateDepartmentDTO) => {
    return api
      .callWithToken()
      .post(`/api/v1/department/`, data)
      .then((res) => res.data);
  },
  delete: (id: string) => {
    return api
      .callWithToken()
      .delete(`/api/v1/department/${id}`)
      .then((res) => res.data);
  },
  update: (id: string, data: UpdateDepartmentDTO) => {
    return api
      .callWithToken()
      .patch(`/api/v1/department/${id}`, data)
      .then((res) => res.data);
  },
  getListChildDepartment: (id: string, data: GetChildDepartmentDTO) => {
    return api
      .callWithToken()
      .get(`/api/v1/department/${id}/child-department`, {
        params: data,
      })
      .then((res) => res.data);
  },
  getListChildren: (id: string, data: GetListChildrenDTO) => {
    return api
      .callWithToken()
      .get(`/api/v1/department/${id}/children`, {
        params: data,
      })
      .then((res) => res.data);
  },
  getListOffice: (id: string, data: GetDepartmentOfficeDTO) => {
    return api
      .callWithToken()
      .get(`/api/v1/department/${id}/office`, {
        params: data,
      })
      .then((res) => res.data);
  },
};
