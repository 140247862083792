import { ACCESS_TOKEN, REFRESH_TOKEN } from "@constants/api";

export const TokenService = {
  getLocalRefreshToken: () => localStorage.getItem(REFRESH_TOKEN) || undefined,
  getLocalAccessToken: () => localStorage.getItem(ACCESS_TOKEN) || undefined,
  updateLocalAccessToken: (accessToken: string, refreshToken?: string) => {
    localStorage.setItem(ACCESS_TOKEN, accessToken || "");
    localStorage.setItem(REFRESH_TOKEN, refreshToken || "");
  },
  removeAllLocalTokens: () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  },
};
