export enum EnumLayout {
  ADMIN = "admin",
  OWNER = "owner",
}

export type LayoutState = {
  [layoutName in EnumLayout]: {
    sideMenu: {
      collapsed: boolean;
    };
  };
};
