import React, { useEffect, useRef } from "react";
import { Button, Empty } from "antd";
import ProList from "@ant-design/pro-list";
import { useAppDispatch, useAppSelector } from "@app/hook";
import { Link, useNavigate, useParams } from "react-router-dom";
import isUUID from "validator/lib/isUUID";
import OrgBreadcrumb, {
  getLink,
} from "@layouts/owner/company/sub-pages/detail-company/organization/org-breadcrumb";
import { BranchService } from "@services";
import { actFetchBranchDetail } from "@store/branch/branchSlice";
import DepartmentTreeSelector from "@layouts/owner/company/sub-pages/detail-company/organization/sub-component/department-tree-selector";
import ProForm from "@ant-design/pro-form";
import ModalUpsertDepartment from "@layouts/owner/company/sub-pages/detail-company/organization/sub-component/modal-upsert-department";
import { ActionType } from "@ant-design/pro-table";
import { PlusOutlined } from "@ant-design/icons";
import {
  DepartmentOrgActions,
  handleDeleteDepartmentOrOffice,
} from "@layouts/owner/company/sub-pages/detail-company/organization/department-organization";
import { actFetchOrganizationTree } from "@store/organization/organizationSlice";
import { getOrganizationState } from "@selectors";
import { vietLocale } from "../../../../../../i18n/vi/lang";

type Props = {
  companyId: string;
  tabKey: string;
};

const BranchOrganization: React.FC<Props> = ({ companyId, tabKey }: Props) => {
  const ref = useRef<ActionType>();
  const navigate = useNavigate();
  const { branchId } = useParams();
  const dispatch = useAppDispatch();
  const { fetchStatus: organizationFetchStatus } =
    useAppSelector(getOrganizationState);

  useEffect(() => {
    if (branchId && isUUID(branchId))
      dispatch(actFetchBranchDetail({ id: branchId }));
  }, [branchId, dispatch]);

  useEffect(() => {
    if (companyId && isUUID(companyId) && !organizationFetchStatus[companyId]) {
      dispatch(actFetchOrganizationTree({ id: companyId }));
    }
  }, [dispatch, organizationFetchStatus, companyId]);

  if (branchId && !isUUID(branchId)) return <p>Incorrect object id</p>;

  const treeSelectorOnChange = (value: any) => {
    if (branchId)
      navigate("/" + getLink(tabKey, companyId, branchId, value.value));
  };

  const reloadOrganizationTree = () => {
    dispatch(actFetchOrganizationTree({ id: companyId }));
  };

  return (
    <ProList<any>
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
      }}
      actionRef={ref}
      toolbar={{
        title: (
          <OrgBreadcrumb
            tabKey={tabKey}
            companyId={companyId}
            branchId={branchId}
          />
        ),
        actions: [
          <DepartmentTreeSelector
            branchId={branchId}
            onChange={treeSelectorOnChange}
          />,
          <ProForm.Item>
            <ModalUpsertDepartment
              companyId={companyId}
              branchId={branchId}
              parentRef={ref}
              reloadOrganizationTree={reloadOrganizationTree}
              trigger={
                <Button type="primary">
                  <PlusOutlined />
                  Khoa
                </Button>
              }
            />
          </ProForm.Item>,
        ],
      }}
      pagination={{
        defaultPageSize: 50,
        showSizeChanger: true,
        hideOnSinglePage: true,
        pageSizeOptions: ["10", "25", "50", "100"],
        locale: {
          ...vietLocale.paginationLocale,
        },
        showTotal: (total, range) => (
          <div>{`Hiển thị ${range[0]}-${range[1]} trong tổng ${total} đơn vị trực thuộc`}</div>
        ),
      }}
      request={async ({ pageSize, current, ...params }) => {
        if (!branchId) return Promise.resolve([]);
        return BranchService.getBranchListChildren(branchId, {
          pageSize,
          current,
        }).then((response) => response.data);
      }}
      rowKey={(row) => row.id}
      showActions="hover"
      grid={{
        gutter: 16,
        column: 3,
        xs: 1,
        sm: 1,
        md: 2,
        lg: 1,
        xl: 3,
        xxl: 4,
      }}
      onItem={(record: any) => {
        return {
          onMouseEnter: () => {
            // TODO
          },
          onClick: () => {
            navigate(record.id);
          },
        };
      }}
      metas={{
        title: {
          dataIndex: "name",
          render: (_, row) => (
            <Link to={"/" + getLink(tabKey, companyId, branchId, row.id)}>
              {row.name}
            </Link>
          ),
        },
        subTitle: {},
        type: {},
        avatar: {
          render: (_, row) =>
            "https://gw.alipayobjects.com/zos/antfincdn/UCSiy1j6jx/xingzhuang.svg",
        },
        content: {},
        actions: {
          cardActionProps: "extra",
          render: (text, row) =>
            DepartmentOrgActions(
              row,
              companyId,
              handleDeleteDepartmentOrOffice,
              ref,
              branchId,
              reloadOrganizationTree
            ),
        },
      }}
    />
  );
};

export default BranchOrganization;
