import api, { IApiResponse, IApiResponseList } from "./api";
import { FindContractRequestDTO } from "@dto/request/contractRequest";

export const ContractService = {
  find: (data: FindContractRequestDTO): Promise<IApiResponseList<any>> => {
    return api
      .callWithToken()
      .get("/api/v1/contract", {
        params: data,
      })
      .then((res) => res.data);
  },
  delete: (id: string) => {
    return api
      .callWithToken()
      .delete(`api/v1/contract/${id}`)
      .then((res) => res.data);
  },
  create: (data: any) => {
    return api
      .callWithToken()
      .post("api/v1/contract", data)
      .then((res) => res.data);
  },
};
