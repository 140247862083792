import * as React from "react";
import ProTable, { ActionType } from "@ant-design/pro-table";
import { columns } from "@layouts/owner/company/sub-pages/detail-company/contract/table-contract";
import { AxiosResponse } from "axios";
import { ContractService } from "@services";
import viVN from "antd/lib/locale/vi_VN";
import { PaginationDefaultConfig } from "@config/LayoutConfig";
import moment from "moment";
import { systemMessage } from "@utils";
import { useRef } from "react";
import ModalFormModalAddContract from "@layouts/owner/company/sub-pages/detail-company/contract/modal-add-contract";

type Props = {
  companyId: string;
};

const TabContract: React.FC<Props> = ({ companyId }: Props) => {
  // const CompanyState = useAppSelector(getCompanyState);
  // if (!CompanyState[companyId].detail)
  const ref = useRef<ActionType>();
  const handleDeleteContract = async (id: string): Promise<any> => {
    const formKey = "ASYNC_ACTION_DELETE_CONTRACT";
    try {
      systemMessage.showLoading(formKey);
      const result = await ContractService.delete(id);
      systemMessage.showSuccess(formKey);
      ref.current?.reload();
      return result.data;
    } catch (error) {
      systemMessage.showError(formKey);
      return Promise.reject(error);
    }
  };
  return (
    <ProTable
      actionRef={ref}
      scroll={{ x: 900 }}
      locale={viVN.Table}
      columns={columns(handleDeleteContract)}
      rowKey={"id"}
      request={async ({ pageSize, current, ...params }, sort, filter) => {
        try {
          const data = await ContractService.find({
            pageSize,
            current,
            companyId,
            contractNo: params["contractNo"],
            type: params["type"],
            effectiveDate:
              params["effectiveDate"] &&
              moment(params["effectiveDate"]).toISOString(),
            expirationDate:
              params["expirationDate"] &&
              moment(params["expirationDate"]).toISOString(),
          });
          return data.data as any;
        } catch (error) {
          console.log("axios error in fetch hospital", error);
        }
      }}
      search={{
        filterType: "query",
        labelWidth: "auto",
        span: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 4,
          xl: 4,
          xxl: 4,
        },
        defaultColsNumber: 1,
      }}
      pagination={{
        locale: {
          items_per_page: "hợp đồng/trang",
        },
        pageSizeOptions: PaginationDefaultConfig.pageSizeOptions,
      }}
      form={{
        syncToUrl: (values) => {
          return values;
        },
        syncToInitialValues: true,
      }}
      toolBarRender={() => [
        <ModalFormModalAddContract
          onSuccess={() => ref.current?.reload()}
          companyId={companyId}
          key={"button-add-contract"}
        />,
      ]}
    />
  );
};

export default TabContract;
