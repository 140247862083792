import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { ConfigProvider as OldConfigProvider, Empty } from "antd";
import viVN from "antd/lib/locale-provider/vi_VN";
import { store } from "./store";
import { ConfigProvider } from "@ant-design/pro-provider";
import intlMap from "@locales/index";
import "moment/locale/vi";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import moment from "moment-timezone";

viVN.Table = {
  emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
};

viVN.global = {
  placeholder: "Vui lòng chọn",
};

viVN.Pagination = {};
viVN.Modal = {
  okText: "Đồng ý",
  cancelText: "",
  justOkText: "",
};

viVN.DatePicker = {
  lang: {
    locale: "vi_VN",
    today: "Hôm nay",
    clear: "Xoá",
    backToToday: "Trở lại ngày hôm nay",
    dateFormat: "dd/mm/yyyy",
    dateSelect: "none",
    dateTimeFormat: "",
    dayFormat: "",
    decadeSelect: "",
    month: "Tháng",
    monthBeforeYear: true,
    placeholder: "Chọn ngày",
    now: "Bây giờ",
    ok: "OK",
    monthSelect: "Chọn tháng",
    nextCentury: "Thế kỷ sau",
    nextDecade: "Thập kỷ sau",
    nextMonth: "Tháng sau",
    nextYear: "Năm sau",
    previousCentury: "Thế kỷ trước",
    previousDecade: "Thập kỷ trước",
    previousMonth: "Tháng trước",
    previousYear: "Năm trước",
    year: "Năm",
    timeSelect: "Chọn thời gian",
    yearFormat: "yyyy",
    yearSelect: "Chọn năm",
  },
  timePickerLocale: {
    placeholder: "Chọn thời gian",
  },
};

viVN.Table.cancelSort = "Huỷ sắp xếp";
viVN.Table.triggerAsc = "Từ A-Z";
viVN.Table.triggerDesc = "Từ Z-A";

moment.tz.setDefault("Asia/Ho_Chi_minh");

let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <ConfigProvider
            value={{
              intl: intlMap["vi-VN"],
              valueTypeMap: {},
            }}
          >
            <OldConfigProvider locale={viVN}>
              <App />
            </OldConfigProvider>
          </ConfigProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
