import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Common
import common_en from "./i18n/en/common.json";
import common_vi from "./i18n/vi/common.json";

// Pages
import pages_en from "./i18n/en/pages.json";
import pages_vi from "./i18n/vi/pages.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    common: common_en,
    pages: pages_en,
  },
  vi: {
    common: common_vi,
    pages: pages_vi,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "vi",
    interpolation: {
      escapeValue: false, // react already safes from xss
      prefix: "##",
      suffix: "##",
      defaultVariables: {
        app: {
          name: "Welab",
        },
      },
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    },
  });

export default i18n;
