import { BranchService } from "@services";
import { TNPEmpty } from "@components/TNPComponent";
import { ProFormTreeSelect } from "@ant-design/pro-form";
import React from "react";

type Props = {
  branchId?: string;
  onChange: (value: any, label: any, extra: any) => void;
  id?: string; // departmentId
};

const DepartmentTreeSelector: React.FC<Props> = ({
  branchId,
  onChange,
  id, // departmentId
}: Props) => {
  return (
    <ProFormTreeSelect
      key={"department-tree-select"}
      // label={"Tìm kiếm"}
      request={async () =>
        branchId
          ? BranchService.getBranchListDepartmentTreeView(branchId)
              .then((response) => {
                return response.data;
              })
              .catch((error) => [])
          : Promise.resolve([])
      }
      fieldProps={{
        showArrow: true,
        placeholder: "Chuyển nhanh đến",
        placement: "bottomRight",
        bordered: false,
        dropdownMatchSelectWidth: false,
        autoClearSearchValue: true,
        labelInValue: true,
        style: {
          minWidth: 300,
          textAlign: "right",
        },
        notFoundContent: <TNPEmpty description={"Trống"} />,
        treeDefaultExpandAll: true,
        defaultValue: id,
        // value:
        //   id && DepartmentState.data[id]
        //     ? {
        //         value: DepartmentState.data[id].id,
        //         label: DepartmentState.data[id].name,
        //       }
        //     : undefined,
        onChange,
        treeLine: true,
        listHeight: 512,
        showSearch: true,
        treeNodeFilterProp: "name",
        filterTreeNode: true,
        fieldNames: {
          value: "id",
          label: "name",
        },
      }}
    />
  );
};

export default DepartmentTreeSelector;
