import * as React from "react";
import { Alert, Button, Card, Layout, Result } from "antd";
import WelabFooter from "@components/AppFooter/appFooter";
import { useTranslation } from "react-i18next";
import AppLogo from "@components/AppLogo";
import { LockOutlined } from "@ant-design/icons";
import styles from "./index.module.less";
import { useNavigate, useSearchParams } from "react-router-dom";
import { pathConfig } from "@config/PathConfig";
import { ResetPasswordProcessStep } from "@layouts/account/accepted-request-password-reset";
import { LoginForm, ProFormText } from "@ant-design/pro-form";
import { useState } from "react";
import { asyncResetPasswordFormSubmit } from "@utils";
import { AxiosResponse } from "axios";
import { HTTP_COMM } from "@constants/api";

const { Footer } = Layout;

type Props = {};

const ResetPasswordPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  let [params] = useSearchParams();
  const email = params.get("email") || "";
  const code = params.get("code") || "";
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (request: any) => {
    try {
      setIsLoading(true);
      const result = await asyncResetPasswordFormSubmit(code, request.password);
      if (result.status === HTTP_COMM.RESPONSE_STATUS_CODE.OK) {
        navigate(
          `/${pathConfig.account.passwordResetSuccess.getFullPath}?email=${email}`
        );
      }
    } catch (error: any) {
      setError(error || "");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Layout>
          <div style={{ margin: "50px auto" }}>
            <Card>
              <Result
                // icon={<AppLogo height={100} width={100} />}
                icon={<AppLogo height={80} width={80} />}
                title={
                  <div>
                    Welab
                    <ResetPasswordProcessStep step={3} />
                  </div>
                }
                style={{ marginTop: 0, padding: 0 }}
              />
              <LoginForm
                onFinish={(value) => handleSubmit(value)}
                style={{
                  paddingTop: 0,
                }}
                submitter={{
                  submitButtonProps: {
                    size: "large",
                    style: { width: "100%" },
                  },
                  render: (props) => {
                    return (
                      <Button
                        loading={loading}
                        {...props.submitButtonProps}
                        type={"primary"}
                        htmlType={"submit"}
                      >
                        {props.searchConfig?.submitText}
                      </Button>
                    );
                  },
                  searchConfig: {
                    resetText: t("layout.text.reset_form", {
                      ns: "common",
                      defaultValue: "Nhập lại",
                    }),
                    submitText: t("layout.text.save", {
                      ns: "common",
                      defaultValue: "Lưu",
                    }),
                  },
                }}
              >
                {error && (
                  <Alert
                    style={{ marginBottom: 20 }}
                    message={error}
                    type="error"
                    showIcon
                    closable
                  />
                )}
                <ProFormText.Password
                  disabled={loading}
                  name="password"
                  fieldProps={{
                    size: "large",
                    prefix: <LockOutlined className={styles.prefixIcon} />,
                  }}
                  placeholder={t("login.text.password", {
                    ns: "pages",
                    defaultValue: "Mật khẩu",
                  })}
                  rules={[
                    {
                      required: true,
                      message: t("login.rule.password.required", {
                        ns: "pages",
                        defaultValue: "Mật khẩu là trường Bắt buộc",
                      }),
                    },
                    {
                      min: 8,
                      message: t("rule.min-##min##-required", {
                        ns: "common",
                        defaultValue: "Tối thiểu ##min## ký tự",
                        min: 8,
                      }),
                    },
                    // Nếu url thiếu secret code từ server thì ko cho submit form reset password
                    () => ({
                      validator() {
                        if (!code)
                          return Promise.reject(
                            t("rule.cannot_change_password_with_secret_code", {
                              ns: "common",
                              defaultValue: "Biểu mẫu không hợp lệ",
                              min: 8,
                            })
                          );
                        return Promise.resolve();
                      },
                    }),
                  ]}
                />
                <ProFormText.Password
                  disabled={loading}
                  name="confirmPassword"
                  fieldProps={{
                    size: "large",
                    prefix: <LockOutlined className={styles.prefixIcon} />,
                  }}
                  placeholder={t(
                    "login.placeholder.retype_password_to_confirm",
                    {
                      ns: "pages",
                      defaultValue: "Xác nhận lại mật khẩu",
                    }
                  )}
                  rules={[
                    {
                      required: true,
                      message: t("login.rule.password-confirm.required", {
                        ns: "pages",
                        defaultValue: "Bắt buộc",
                      }),
                    },
                    {
                      min: 8,
                      message: t("rule.min-##min##-required", {
                        ns: "common",
                        defaultValue: "Tối thiểu ##min## ký tự",
                        min: 8,
                      }),
                    },
                    // Nếu url thiếu secret code từ server thì ko cho submit form reset password
                    () => ({
                      validator() {
                        if (!code)
                          return Promise.reject(
                            t("rule.cannot_change_password_with_secret_code", {
                              ns: "common",
                              defaultValue: "Biểu mẫu không hợp lệ",
                              min: 8,
                            })
                          );
                        return Promise.resolve();
                      },
                    }),
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          t("rule.confirm_password_does_not_match", {
                            ns: "common",
                            defaultValue: "Mật khẩu không khớp",
                            min: 8,
                          })
                        );
                      },
                    }),
                  ]}
                />
              </LoginForm>
            </Card>
          </div>
          <Footer>
            <WelabFooter />
          </Footer>
        </Layout>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
