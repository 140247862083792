import api from "./api";
import { CreateOfficeDTO, UpdateOfficeDTO } from "@dto/request/officeRequest";

export const OfficeService = {
  delete: (id: string) => {
    return api
      .callWithToken()
      .delete(`/api/v1/office/${id}`)
      .then((res) => res.data);
  },
  create: (data: CreateOfficeDTO) => {
    return api
      .callWithToken()
      .post(`/api/v1/office/`, data)
      .then((res) => res.data);
  },
  update: (id: string, data: UpdateOfficeDTO) => {
    return api
      .callWithToken()
      .patch(`/api/v1/office/${id}`, data)
      .then((res) => res.data);
  },
};
