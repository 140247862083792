import * as React from "react";
import { Routes, Route } from "react-router-dom";
import AllCompanyPage from "@layouts/owner/company/sub-pages/all-companies";
import DetailedCompanyPage from "@layouts/owner/company/sub-pages/detail-company";

type Props = {
  children?: JSX.Element;
};

export const CompanyRoute: React.FC<Props> = () => {
  return (
    <Routes>
      {/*<Route*/}
      {/*  path={pathConfig.owner.hospital.add.getSortPath}*/}
      {/*  element={<PageCreateCompany />}*/}
      {/*/>*/}
      <Route path="" element={<AllCompanyPage />} />
      <Route path=":id/*" element={<DetailedCompanyPage />}>
        <Route path=":tabKey/*" element={<></>} />
      </Route>
    </Routes>
  );
};
