import React from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import ProLayout from "@ant-design/pro-layout";
import defaultProps from "./_defaultProps";
import GlobalHeaderRight from "@components/RightContent";
import useNotAuthenticated from "@hooks/useNotAuthenticated";
import {
  buildPath,
  pathConfig,
  SECOND_LEVEL,
  THIRD_LEVEL,
  TOP_LEVEL,
} from "@config/PathConfig";
import { ProfilePage } from "@pages/common/ProfilePage";
import { HospitalRoute } from "@layouts/admin/hospital";
import AdminDashboardPage from "./dashboard/index";
import { useAppDispatch, useAppSelector } from "@app/hook";
import { getLayoutState } from "@selectors";
import { SettingGeneralPage } from "@layouts/admin/setting/system/index";
import { HeaderLogo } from "@components/HeaderLogo";
import AdminGalleryPage from "@layouts/admin/gallery";
import CategoryPage from "@layouts/admin/category";
import { IconLibAntD } from "@components/AppFaIcon/icon-lib";
import { Button } from "antd";

type Props = {
  children?: JSX.Element;
};

const AdminLayout: React.FC<Props> = () => {
  useNotAuthenticated();
  const location = useLocation();
  const Layout = useAppSelector(getLayoutState);
  const dispatch = useAppDispatch();
  return (
    <ProLayout
      {...defaultProps}
      menuItemRender={(item, dom) => (
        <Link to={item["navigateTo"] || item.path || ""}>{dom}</Link>
      )}
      style={{
        minHeight: "100vh",
      }}
      fixSiderbar
      locale="en-US"
      fixedHeader
      location={{ pathname: location.pathname }}
      rightContentRender={() => <GlobalHeaderRight />}
      // footerRender={() => <AppFooter />}
      navTheme={"dark"}
      disableMobile={true}
      collapsed={true}
      headerContentRender={() => <HeaderLogo />}
      // headerRender={false}
      isChildrenLayout={true}
      collapsedButtonRender={() => (
        <Link to={buildPath([TOP_LEVEL.ADMIN, SECOND_LEVEL.HELP]).getSortPath}>
          <IconLibAntD.QuestionCircleOutlined />
        </Link>
      )}
      disableContentMargin={
        ![`/${pathConfig.admin.profile.getFullPath}`].includes(
          location.pathname
        )
      }
    >
      <Routes>
        <Route
          path={pathConfig.admin.dashboard.getSortPath}
          element={<AdminDashboardPage />}
        />
        <Route
          path={`${
            buildPath([TOP_LEVEL.ADMIN, SECOND_LEVEL.CATEGORY]).getSortPath
          }/*`}
          element={<CategoryPage />}
        />
        <Route
          path={`${
            buildPath([TOP_LEVEL.ADMIN, SECOND_LEVEL.SETTINGS]).getSortPath
          }/*`}
          element={<SettingGeneralPage />}
        >
          <Route path=":tabKey/*" element={<></>} />
        </Route>
        <Route
          path={`${
            buildPath([TOP_LEVEL.ADMIN, SECOND_LEVEL.GALLERY]).getSortPath
          }/*`}
          element={<AdminGalleryPage />}
        />
        <Route
          path={pathConfig.admin.profile.getSortPath}
          element={<ProfilePage />}
        />
      </Routes>
    </ProLayout>
  );
};

export default AdminLayout;
