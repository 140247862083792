import { ProColumns } from "@ant-design/pro-table";
import { Button, Checkbox, Popconfirm, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import * as React from "react";
import { pathConfig } from "@config/PathConfig";
import { Link } from "react-router-dom";
import { AuthUser } from "@store/auth/type";

export const columns = (
  handleDeleteRecord: (id: string) => Promise<any>,
  currentUser: AuthUser | undefined
): ProColumns[] => [
  {
    title: "STT",
    width: 50,
    align: "center",
    search: false,
    fixed: "left",
    render: (text, record, _) => _ + 1,
  },
  {
    title: "Tên hiển thị",
    dataIndex: "photoURL",
    valueType: "avatar",
    sorter: true,
    showSorterTooltip: true,
    search: false,
    fixed: "left",
    render: (dom, record) => (
      <Space>
        <span>{dom}</span>
        <Link to={`/${pathConfig.owner.company.getFullPath}/${record.uid}`}>
          {record.displayName}
        </Link>
      </Space>
    ),
  },
  {
    title: "Email",
    key: "email",
    dataIndex: "email",
    sorter: true,
    fixed: "left",
  },
  {
    title: "Owner",
    key: "isOwner",
    dataIndex: "isOwner",
    width: 70,
    align: "center",
    render: (text, record, _) => (
      <Checkbox key={record.uid} checked={record.isOwner} disabled={true} />
    ),
  },
  {
    title: "Công ty",
    key: "company",
    dataIndex: "company",
    render: (text, record, _) => (
      <Link
        to={`/${pathConfig.owner.company.getFullPath}/${record.company?.id}`}
      >
        {record.company?.name}
      </Link>
    ),
  },
  {
    title: "Ngày tạo",
    key: "createdAt",
    dataIndex: "createdAt",
    valueType: "dateTime",
    width: 200,
  },
  {
    title: "Ngày sửa đổi",
    key: "updatedAt",
    dataIndex: "updatedAt",
    valueType: "dateTime",
    width: 200,
  },
  {
    title: "Thao tác",
    valueType: "option",
    width: 100,
    fixed: "right",
    render: (text, record, _, action) => (
      <Popconfirm
        disabled={!(currentUser && currentUser.uid !== record.uid)}
        key={record.id}
        title={"Bạn có chắc chắn muốn xoá mục này?"}
        onConfirm={() => {
          handleDeleteRecord(record.uid).then(() => Promise.resolve());
          return Promise.resolve();
        }}
      >
        <Button
          danger
          icon={<DeleteOutlined />}
          disabled={!(currentUser && currentUser.uid !== record.uid)}
        >
          Xoá
        </Button>
      </Popconfirm>
    ),
  },
];
