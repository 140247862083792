import {PaginationLocale} from "rc-pagination";

interface ILocale {
    paginationLocale: PaginationLocale
}

export const vietLocale: ILocale = {
   paginationLocale : {
    items_per_page: "mục/trang",
    next_page: "Trang tiếp theo",
    prev_page: "Trước trước",
    page: "Trang",
    }
}