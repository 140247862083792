import React from "react";
import CommonGalleryPage from "@pages/common/GalleryPage";

type Props = {
  children?: JSX.Element;
};

const AdminGalleryPage: React.FC<Props> = () => {
  return <CommonGalleryPage />;
};

export default AdminGalleryPage;
