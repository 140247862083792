import api, { IApiResponse, IApiResponseList } from "./api";
import { IFindFeatureResponseDTO } from "@dto/response/plan.response";
import {
  FindFeatureRequestDTO,
  FindPlanRequestDTO,
} from "@dto/request/plan.request.dto";

export const PlanService = {
  findFeature: async (
    data?: FindFeatureRequestDTO
  ): Promise<IApiResponseList<IFindFeatureResponseDTO>> => {
    return api
      .callWithToken()
      .get(`/api/v1/plan/feature`, {
        params: data,
      })
      .then((res) => res.data);
  },

  findPlan: async (
    data: FindPlanRequestDTO
  ): Promise<IApiResponseList<any>> => {
    return api
      .callWithToken()
      .get("/api/v1/plan", {
        params: data,
      })
      .then((res) => res.data);
  },
};
