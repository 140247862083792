import * as React from "react";
import { Button, Card, Layout, Result, Typography } from "antd";
import WelabFooter from "@components/AppFooter/appFooter";
import { useTranslation } from "react-i18next";
import AppLogo from "@components/AppLogo";
import styles from "./index.module.less";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ResetPasswordProcessStep } from "@layouts/account/accepted-request-password-reset";
import { pathConfig } from "@config/PathConfig";

const { Footer } = Layout;
const { Title, Text } = Typography;

type Props = {};

const PasswordChangedSuccessPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  let [params] = useSearchParams();
  const email = params.get("email") || "";
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Layout>
          <div style={{ margin: "50px auto" }}>
            <Card>
              <Result
                // icon={<AppLogo height={100} width={100} />}
                icon={<AppLogo height={80} width={80} />}
                title={
                  <div>
                    Welab
                    <ResetPasswordProcessStep step={4} />
                  </div>
                }
                style={{ marginTop: 0, padding: 0 }}
                subTitle={
                  <>
                    <Title level={5}>
                      {t("login.text.password-has-been-successfully-updated", {
                        ns: "pages",
                        defaultValue: "🎉 Hoàn tất việc cập nhật mật khẩu! 🎉",
                      })}
                    </Title>
                    <Text>
                      {t("login.text.you-can-now-sign-in-with-new-password", {
                        ns: "pages",
                        defaultValue:
                          "Bạn có thể đăng nhập vào hệ thống bằng mật khẩu mới",
                      })}
                    </Text>
                  </>
                }
                extra={
                  <Button
                    type="primary"
                    key="login"
                    onClick={() =>
                      navigate(
                        `/${pathConfig.account.login.getFullPath}?email=${email}`
                      )
                    }
                  >
                    {t("login.text.navigate-to-login-page", {
                      ns: "pages",
                      defaultValue: "Đưa tôi đến trang đăng nhập",
                    })}
                  </Button>
                }
              />
            </Card>
          </div>
          <Footer>
            <WelabFooter />
          </Footer>
        </Layout>
      </div>
    </div>
  );
};

export default PasswordChangedSuccessPage;
