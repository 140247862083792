import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { DepartmentService } from "@services";
import { FetchBranchDetailDTO } from "@dto/request/branchRequest";
import { DepartmentModel } from "../models/department.model";
import {
  actFetchDepartmentDetail,
  actFetchDepartmentDetailFailed,
  actFetchDepartmentDetailSuccess,
} from "@store/department/departmentSlice";

function* fetchDepartmentDetail({
  payload,
}: PayloadAction<FetchBranchDetailDTO>) {
  const { id } = payload;
  try {
    const { data } = yield call(DepartmentService.get, id);
    yield put(actFetchDepartmentDetailSuccess(data as DepartmentModel));
  } catch (error) {
    yield put(actFetchDepartmentDetailFailed(id));
  }
}

function* watchFetchDepartmentDetail() {
  yield takeLatest<any>(actFetchDepartmentDetail.type, fetchDepartmentDetail);
}

export default function* departmentSaga() {
  yield all([fork(watchFetchDepartmentDetail)]);
}
