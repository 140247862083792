import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import ProLayout, { MenuDataItem, PageContainer } from "@ant-design/pro-layout";
import { Affix, Menu, Space } from "antd";
import { iconLib, IconLibAntD } from "@components/AppFaIcon/icon-lib";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useParams,
} from "react-router-dom";
import {
  buildPath,
  SECOND_LEVEL,
  THIRD_LEVEL,
  TOP_LEVEL,
} from "@config/PathConfig";
import { CategoryTabTitle } from "@layouts/admin/category/tabs/category-tab-title";
import { CategoryTabTest } from "@layouts/admin/category/tabs/category-tab-test";
import AppIcon from "@components/AppFaIcon";

type Props = {};

const routes = [
  {
    key: THIRD_LEVEL.TITLE,
    name: "Chức vụ nhân viên",
    icon: <AppIcon icon={iconLib.faUserTag} />,
    path: buildPath([TOP_LEVEL.ADMIN, SECOND_LEVEL.CATEGORY, THIRD_LEVEL.TITLE])
      .getFullPath,
  },
  {
    key: "test",
    name: "test",
    path: buildPath([TOP_LEVEL.ADMIN, SECOND_LEVEL.CATEGORY, "test"])
      .getFullPath,
  },
] as MenuDataItem[];

const CategoryPage: React.FC<Props> = () => {
  const location = useLocation();
  const [windowSize, setWindowSize] = useState<{
    innerWidth: number;
    innerHeight: number;
  }>({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });

  function handleResize() {
    setWindowSize({
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
    });
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const isMobile: boolean = windowSize.innerWidth <= 768;

  return (
    <ProLayout
      logo={false}
      fixSiderbar
      locale="en-US"
      fixedHeader={true}
      headerRender={false}
      // footerRender={() => <AppFooter />}
      navTheme={"light"}
      disableMobile={true}
      disableContentMargin={true}
      isChildrenLayout={false}
      layout={"top"}
    >
      <ProLayout
        title={"Danh mục"}
        logo={
          <Space>
            <IconLibAntD.UnorderedListOutlined style={{ width: 32 }} />
          </Space>
        }
        style={{ zIndex: 0 }}
        navTheme={"light"}
        fixedHeader={true}
        location={{ pathname: location.pathname }}
        headerRender={
          !isMobile
            ? false
            : (props, defaultDom) => (
                <div style={{ marginTop: 48, marginLeft: 48 }}>
                  {defaultDom}
                </div>
              )
        }
        breakpoint={"sm"}
        disableMobile={true}
        menuRender={(props, defaultDom) => (
          <Affix offsetTop={48}>
            <div
              style={{
                minHeight: "100vh",
                backgroundColor: "white",
                overflowY: "auto",
              }}
            >
              {defaultDom}
            </div>
          </Affix>
        )}
        layout={isMobile ? "top" : "side"}
        menuItemRender={(item, dom) => (
          <Link key={item.path} to={item.path || ""}>
            {dom}
          </Link>
        )}
        route={{
          path: buildPath([TOP_LEVEL.ADMIN, SECOND_LEVEL.CATEGORY]).getSortPath,
          routes: routes,
        }}
        menuContentRender={(props, defaultDom) => (
          <div
            style={{
              // overflowY: "scroll",
              maxHeight: windowSize.innerHeight - 120,
              height: windowSize.innerHeight - 120,
            }}
          >
            {defaultDom}
          </div>
        )}
        disableContentMargin={true}
        collapsedButtonRender={!isMobile ? false : undefined}
      >
        {/*<CategoryTabTitle />*/}
        <Routes>
          <Route
            path={`${THIRD_LEVEL.TITLE}/*`}
            element={<CategoryTabTitle />}
          />
          <Route path={"test/*"} element={<CategoryTabTest />} />
        </Routes>
      </ProLayout>
    </ProLayout>
  );
};

export default CategoryPage;
