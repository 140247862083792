import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import React from "react";
import { pathConfig } from "@config/PathConfig";
import { useAppSelector } from "@app/hook";
import {
  getBranchState,
  getCompanyState,
  getDepartmentState,
} from "@selectors";
import { DepartmentModel } from "../../../../../../models/department.model";
import { iconLib, IconLibAntD } from "@components/AppFaIcon/icon-lib";
import AppFaIcon from "@components/AppFaIcon";

type Props = {
  tabKey: string;
  companyId: string;
  branchId?: string;
  departmentId?: string;
};

export const getLink = (
  prefix: string = "",
  companyId: string,
  branchId?: string,
  departmentId?: string,
  officeId?: string,
  itemId?: string
) => {
  const linkPrefix = prefix ? `${prefix}/` : "";
  return `${pathConfig.owner.company.getFullPath}/${companyId}/${linkPrefix}${
    !!branchId ? branchId : ""
  }${!!departmentId ? "/" + departmentId : ""}${
    !!officeId ? "/" + officeId : ""
  }${!!itemId ? "/" + itemId : ""}`;
};

const OrgBreadcrumb: React.FC<Props> = ({
  tabKey,
  companyId,
  branchId,
  departmentId,
}: Props) => {
  const CompanyState = useAppSelector(getCompanyState);
  const BranchState = useAppSelector(getBranchState);
  const DepartmentState = useAppSelector(getDepartmentState);

  return (
    <>
      <Breadcrumb separator=":">
        <Breadcrumb.Item>Sơ đồ tổ chức</Breadcrumb.Item>
        <Breadcrumb.Separator children={" "} />
      </Breadcrumb>
      <Breadcrumb separator={<IconLibAntD.RightOutlined />}>
        <Breadcrumb.Item>
          <AppFaIcon icon={iconLib.faHospital} />
          <Link to={"/" + getLink(tabKey, companyId)}>
            <span>{CompanyState?.data[companyId]?.name}</span>
          </Link>
        </Breadcrumb.Item>
        {/*{CompanyState.branchData[companyId].data[branchId].named}*/}
        <Breadcrumb.Item>
          {/*{CompanyState.branchData[companyId].fetchStatus ===*/}
          {/*  FETCH_STATUS.LOADING && "Loading"}*/}
          {!!(branchId && BranchState.data[branchId]) && (
            <>
              <AppFaIcon icon={iconLib.faHouseMedical} />
              <Link to={"/" + getLink(tabKey, companyId, branchId)}>
                {/*{currentBranch.name}*/}
                {BranchState.data[branchId].name}
              </Link>
            </>
          )}
        </Breadcrumb.Item>
        {departmentId &&
          DepartmentState.data[departmentId]?.ancestors?.map(
            (parentDepartment: DepartmentModel) => (
              <Breadcrumb.Item key={parentDepartment.id}>
                <AppFaIcon icon={iconLib.faSitemap} />
                <Link
                  to={
                    "/" +
                    getLink(tabKey, companyId, branchId, parentDepartment.id)
                  }
                >
                  {/*{currentBranch.name}*/}
                  {parentDepartment.name}
                </Link>
              </Breadcrumb.Item>
            )
          )}
        <Breadcrumb.Item>
          {!!(departmentId && DepartmentState.data[departmentId]) && (
            <>
              {/*<AppIcon icon={iconLib.faStore} />*/}
              <AppFaIcon icon={iconLib.faSitemap} />
              <Link
                to={"/" + getLink(tabKey, companyId, branchId, departmentId)}
              >
                {/*{currentBranch.name}*/}
                {DepartmentState.data[departmentId].name}
              </Link>
            </>
          )}
        </Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default OrgBreadcrumb;
