import { Image, Spin, SpinProps } from "antd";
import React from "react";

const AppIconSpinner: React.FC<SpinProps> = () => (
  <Image src={`${process.env.PUBLIC_URL}/assets/images/welab-spinner.gif`} />
);

const AppSpinner: React.FC<SpinProps> = ({ ...props }: SpinProps) => {
  return <Spin indicator={<AppIconSpinner />} {...props} />;
};
export default AppSpinner;
