import { Image, ImageProps } from "antd";
import React from "react";

const AppLogo: React.FC<ImageProps> = (props) => (
  <Image
    preview={false}
    {...props}
    src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
  />
);

export default AppLogo;
