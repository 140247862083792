import React, { useState } from "react";
import { Button } from "antd";
import { ModalForm } from "@ant-design/pro-form";
import { PlusOutlined } from "@ant-design/icons";
import { DATE_TIME_UNIT, ContractType } from "@constants/index";
import moment from "moment";
import { calculateExpirationDate, systemMessage } from "@utils";
import { APP_DATE_FORMAT } from "@config/AppConfig";
import { merge } from "object-mapper";
import { ContractService } from "@services";
import { CreateContractRequestDTO } from "@dto/request/contractRequest";
import FormAddContract from "@layouts/owner/company/sub-pages/detail-company/contract/form-add-contract";

type Props = {
  companyId: string;
  onSuccess: () => void;
};

const ModalAddContract: React.FC<Props> = ({ companyId, onSuccess }: Props) => {
  const [contractTimeUnit, setContractTimeUnit] =
    useState<DATE_TIME_UNIT>("days");

  const handleSetContractTimeUnit = (timeUnit: DATE_TIME_UNIT) => {
    setContractTimeUnit(timeUnit);
  };

  return (
    <ModalForm<{
      licenseType: ContractType;
      periodLength: number;
      effectiveDate: Date | string;
      expirationDate: Date | string;
    }>
      title="Thêm hợp đồng"
      trigger={
        <Button type="primary">
          <PlusOutlined />
          Thêm hợp đồng
        </Button>
      }
      autoFocusFirstInput
      modalProps={{
        maskClosable: false,
        destroyOnClose: true,
        centered: true,
      }}
      onFinish={async (values) => {
        const formKey = "ASYNC_ACTION_CREATE_CONTRACT";
        try {
          const effectiveDate = moment(
            values.effectiveDate,
            APP_DATE_FORMAT
          ).toISOString();
          const expirationDate = calculateExpirationDate(
            moment(values.effectiveDate, APP_DATE_FORMAT).startOf("day"),
            values.periodLength,
            contractTimeUnit
          ).toISOString();
          const requestData = merge(
            {
              licenseType: values.licenseType,
              expirationDate,
              effectiveDate,
              companyId,
            },
            {
              licenseType: "type",
              effectiveDate: "effectiveDate",
              expirationDate: "expirationDate",
              companyId: "companyId",
            }
          ) as CreateContractRequestDTO;
          await ContractService.create(requestData);
          systemMessage.showSuccess(formKey);
          onSuccess();
          return true;
        } catch (e) {
          systemMessage.showError(formKey);
          return false;
        }
      }}
      submitter={{
        searchConfig: {
          submitText: "Thêm",
          resetText: "Đóng",
        },
      }}
    >
      <FormAddContract
        contractTimeUnit={contractTimeUnit}
        setContractTimeUnit={handleSetContractTimeUnit}
      />
    </ModalForm>
  );
};

export default ModalAddContract;
