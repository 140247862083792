import {
  all,
  call,
  fork,
  take,
  put,
  cancel,
  cancelled,
  takeLatest,
} from "redux-saga/effects";
import {
  actConfirmEmail,
  actConfirmEmailFail,
  actConfirmEmailSuccess,
  actGetProfile,
  actGetProfileSuccess,
  actLogin,
  actLoginFailed,
  actLoginSuccess,
  actLogout,
  actRegister,
  actRegisterFailed,
  actRegisterSuccess,
} from "@store/auth/authSlice";
import {
  confirmEmailRequestDTO,
  LoginRequestDTO,
  RegisterRequestDTO,
} from "@dto/request/authRequest";
import { AuthService } from "@services";
import { AxiosError, AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import { Task } from "redux-saga";
import { AuthUser } from "@store/auth/type";
import { HTTP_COMM } from "@constants/api";
import { getTranslation } from "@utils";

function* login(request: LoginRequestDTO): any {
  try {
    const { data } = yield call(AuthService.login, request);
    yield put(actLoginSuccess(data as AuthUser));
  } catch (error: any) {
    const axiosError = error.response as Pick<AxiosResponse, "data">;
    yield put(
      actLoginFailed({
        data: axiosError?.data,
      })
    );
  } finally {
    if (yield cancelled()) {
      // ... put special cancellation handling code here
    }
  }
}

function* getProfile() {
  try {
    const { data } = yield call(AuthService.getProfile);
    yield put(actGetProfileSuccess(data as AuthUser));
  } catch (error) {
    yield put(actLogout());
  }
}

function* watchGetProfile() {
  yield all([takeLatest<any>(actGetProfile.type, getProfile)]);
}

function* loginFlow() {
  while (true) {
    const { payload }: PayloadAction<LoginRequestDTO> = yield take(
      actLogin.type
    );
    const task: Task = yield fork(login, payload);
    const action: PayloadAction = yield all([
      take([actLogout, actLoginFailed]),
      // takeLatest<any>(actGetProfile, getProfile),
    ]);
    if (action.type === actLogout.type) yield cancel(task);
  }
}

function* registerAccount({ payload }: PayloadAction<RegisterRequestDTO>) {
  try {
    const { status, data } = yield call(AuthService.register, payload);
    if (status === HTTP_COMM.RESPONSE_STATUS_CODE.CREATED) {
      yield put(actRegisterSuccess(data as AuthUser));
    } else {
      throw new Error();
    }
  } catch (error) {
    const axiosError = (error as AxiosError).response as Pick<
      AxiosResponse,
      "data"
    >;
    yield put(
      actRegisterFailed({
        data: axiosError?.data,
      })
    );
  }
}

function* watchSignUp() {
  yield takeLatest<any>(actRegister.type, registerAccount);
}

function* confirmEmail({ payload }: PayloadAction<confirmEmailRequestDTO>) {
  try {
    const { status, data } = yield call(AuthService.confirmEmail, payload);
    if (status === HTTP_COMM.RESPONSE_STATUS_CODE.OK)
      yield put(actConfirmEmailSuccess(data as AuthUser));
  } catch (e) {
    const { response } = e as AxiosError;
    const ErrorMessage = getTranslation(response?.data.error) || "unknown";
    yield put(actConfirmEmailFail(ErrorMessage));
  }
}

function* watchEmailConfirm() {
  yield takeLatest<any>(actConfirmEmail.type, confirmEmail);
}

export default function* authSaga() {
  yield all([
    fork(loginFlow),
    fork(watchGetProfile),
    fork(watchSignUp),
    fork(watchEmailConfirm),
  ]);
}
