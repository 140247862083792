import * as React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import {
  Typography,
  Card,
  Result,
  Skeleton,
  Descriptions,
  Button,
  Badge,
  Space,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@app/hook";
import { getCompanyState } from "@selectors";
import isUUID from "validator/lib/isUUID";
import { useEffect } from "react";
import { actFetchCompanyDetail } from "@store/company/companySlice";
import moment from "moment";
import { APP_DATE_TIME_FORMAT } from "@config/AppConfig";
import { linkify } from "@utils";
import { useTranslation } from "react-i18next";
import { pathConfig } from "@config/PathConfig";
import { Route, Routes, Outlet } from "react-router-dom";
import TabContract from "@layouts/owner/company/sub-pages/detail-company/contract";
import { CompanyModel } from "../../../../../models/company.model";
import SwitchCompanyOnOff from "@components/featured-components/SwitchCompanyOnOff";
import { FETCH_STATUS } from "@api";
import TabCompanyOrganization from "@layouts/owner/company/sub-pages/detail-company/organization";
import TabBranchOrganization from "@layouts/owner/company/sub-pages/detail-company/organization/branch-organization";
import TabDepartmentOrganization from "@layouts/owner/company/sub-pages/detail-company/organization/department-organization";
import { ApartmentOutlined } from "@ant-design/icons";
import AppFaIcon from "@components/AppFaIcon";
import { iconLib, IconLibAntD } from "@components/AppFaIcon/icon-lib";

const { Text } = Typography;
type Props = {};

const TestOffice = ({ companyId }: any) => {
  const { branchId, departmentId, officeId } = useParams();
  return (
    <div>
      <p>Companyid: {companyId}</p>
      <p>branchId: {branchId}</p>
      <p>departmentId: {departmentId}</p>
      <p>officeId: {officeId}</p>
    </div>
  );
};

const DetailedCompanyPage: React.FC<Props> = () => {
  const CompanyState = useAppSelector(getCompanyState);
  const { id, tabKey } = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) dispatch(actFetchCompanyDetail({ id }));
  }, [id, dispatch]);

  if (
    !isUUID(id || "") ||
    !id ||
    CompanyState.fetchStatus[id] === FETCH_STATUS.FAILED
  ) {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        // extra={<Button type="primary">Back Home</Button>}
      />
    );
  }

  const content = (company: CompanyModel) =>
    company ? (
      <Descriptions
        title={
          <Badge
            status={company.active ? "success" : "default"}
            text={
              company.active
                ? t("hospital.status.active", {
                    ns: "common",
                    defaultValue: "Đang hoạt động",
                  })
                : t("hospital.status.inactive", {
                    ns: "common",
                    defaultValue: "Không hoạt động",
                  })
            }
          />
        }
        size="small"
        column={2}
      >
        <Descriptions.Item label="Địa chỉ">
          {<Text>{company.address || "Không có"}</Text>}
        </Descriptions.Item>
        <Descriptions.Item label="Điện thoại">
          {company.phone || "Không có"}
        </Descriptions.Item>
        <Descriptions.Item label="Trang chủ">
          {company.homepage ? (
            <a
              rel="noreferrer"
              href={linkify(company.homepage)}
              target={"_blank"}
            >
              {company.homepage}
            </a>
          ) : (
            "Không có"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Ngày tạo">
          {moment(company.createdAt).format(APP_DATE_TIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          {company.email || "Không có"}
        </Descriptions.Item>
        <Descriptions.Item label="Sửa đổi lần cuối">
          {moment(company.updatedAt).format(APP_DATE_TIME_FORMAT)}
        </Descriptions.Item>
      </Descriptions>
    ) : (
      <Skeleton active paragraph={{ rows: 2 }} />
    );

  const tabList = [
    {
      key: "branch",
      tab: (
        <span>
          <ApartmentOutlined />
          Tổ chức hành chính
        </span>
      ),
    },
    {
      key: "user",
      tab: (
        <span>
          <IconLibAntD.UserOutlined />
          Người dùng
        </span>
      ),
    },
    {
      key: "contract",
      tab: (
        <span>
          <AppFaIcon icon={iconLib.faFileSignature} />
          Hợp đồng
        </span>
      ),
    },
    {
      key: "settings",
      tab: (
        <span>
          <AppFaIcon icon={iconLib.faScrewdriverWrench} />
          Cài đặt
        </span>
      ),
    },
  ];

  const getActiveTabKey = (): string => {
    const activeTabKey = tabList.find((item) => tabKey?.includes(item.key));
    if (activeTabKey) return activeTabKey.key;
    return ""; // tabList[0].key;
  };

  const handleTabChange = (key: string) => {
    navigate(
      `/${pathConfig.owner.company.getFullPath}/${id}${
        key !== tabList[0].key ? `/${key}` : ""
      }`
    );
  };

  const loading = !CompanyState?.data[id];

  return (
    <PageContainer
      loading={loading}
      title={
        loading ? (
          <Skeleton.Input active style={{ width: 200 }} />
        ) : (
          <Space>
            <Text>{CompanyState.data[id].name}</Text>
            <SwitchCompanyOnOff id={id} active={CompanyState.data[id].active} />
          </Space>
        )
      }
      content={content(CompanyState.data[id])}
      extraContent={
        loading ? false : [<Button key={"button-refresh"}>Làm mới</Button>]
      }
      tabList={loading ? undefined : tabList}
      tabActiveKey={getActiveTabKey() || tabList[0].key}
      onTabChange={handleTabChange}
    >
      <Card style={{ minHeight: 250 }}>
        <Outlet />
        <Routes>
          <Route
            path={`/`}
            element={
              <TabCompanyOrganization
                tabKey={getActiveTabKey()}
                companyId={id}
              />
            }
          />
          <Route
            path={`/:branchId/`}
            element={
              <TabBranchOrganization
                tabKey={getActiveTabKey()}
                companyId={id}
              />
            }
          />
          <Route
            path={`/:branchId/:departmentId/`}
            element={
              <TabDepartmentOrganization
                tabKey={getActiveTabKey()}
                companyId={id}
              />
            }
          />
          <Route
            path={`/:branchId/:departmentId/:officeId/`}
            element={<TestOffice companyId={id} />}
          />
          <Route path={tabList[1].key} element={<p>{tabList[1].tab}</p>} />
          <Route
            path={tabList[2].key}
            element={<TabContract companyId={id} />}
          />
          <Route path={tabList[3].key} element={<p>{tabList[3].tab}</p>} />
        </Routes>
      </Card>
    </PageContainer>
  );
};

export default DetailedCompanyPage;
