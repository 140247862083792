import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { CompanyService } from "@services";
import {
  actFetchCompanyDetail,
  actFetchCompanyFailed,
  actFetchCompanySuccess,
} from "@store/company/companySlice";
import { FetchCompanyDetailDTO } from "@dto/request/companyRequest";
import { PayloadAction } from "@reduxjs/toolkit";
import { CompanyModel } from "../models/company.model";

function* getCompanyDetail({ payload }: PayloadAction<FetchCompanyDetailDTO>) {
  const { id } = payload;
  try {
    const { data } = yield call(CompanyService.get, id);
    yield put(actFetchCompanySuccess(data as CompanyModel));
  } catch (error) {
    yield put(actFetchCompanyFailed(id));
  }
}

function* watchGetCompanyDetail() {
  yield takeLatest<any>(actFetchCompanyDetail.type, getCompanyDetail);
}

export default function* companySaga() {
  yield all([fork(watchGetCompanyDetail)]);
}
