import React, { useState } from "react";
import useForm = ProForm.useForm;
import { Button } from "antd";
import ProForm, { ProFormGroup, ProFormText } from "@ant-design/pro-form";
import ProCard from "@ant-design/pro-card";
import useSWR from "swr";
import { useAppSelector } from "@app/hook";
import { getAuthState } from "@selectors";
import { CompanyService } from "@services";
import { systemMessage } from "@utils";
import { UpdateCompanyRequestDTO } from "@dto/request/companyRequest";
import { IconLibAntD } from "@components/AppFaIcon/icon-lib";

enum EnumSection {
  THONG_TIN_BENH_VIEN = "Thông tin bệnh viện",
}

export const TabHospitalInformation = () => {
  const [form] = useForm();
  const { currentUser, loading } = useAppSelector(getAuthState);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<{
    [key: string]: boolean;
  }>({
    [EnumSection.THONG_TIN_BENH_VIEN]: true,
  });
  const { data, error } = useSWR(
    () =>
      currentUser?.company?.id
        ? `GET/api/v1/company/${currentUser.company?.id}`
        : null,
    () =>
      currentUser?.company?.id
        ? CompanyService.get(currentUser.company?.id)
        : null,
    {}
  );

  const handleFormSubmit = async (values: UpdateCompanyRequestDTO) => {
    setIsFormSubmitting(true);
    const formKey = "ASYNC_ACTION_UPDATE_COMPANY";
    try {
      if (!data?.data?.id) throw new Error();
      systemMessage.showLoading(formKey);
      await CompanyService.update(
        data.data.id,
        values as UpdateCompanyRequestDTO
      );
      systemMessage.showSuccess(formKey);
    } catch (error) {
      systemMessage.showError(formKey);
    } finally {
      setIsFormSubmitting(false);
      return Promise.resolve(true);
    }
  };

  return (
    <ProCard
      ghost={false}
      layout={"default"}
      bordered={true}
      style={{ borderRadius: 5 }}
      loading={!data}
      headerBordered={true}
      title={EnumSection.THONG_TIN_BENH_VIEN}
      onCollapse={(collapsed) =>
        setIsCollapsed({
          ...isCollapsed,
          [EnumSection.THONG_TIN_BENH_VIEN]: !collapsed,
        })
      }
      extra={[
        <Button
          loading={isFormSubmitting}
          type={"primary"}
          key={"submit-button"}
          hidden={!isCollapsed[EnumSection.THONG_TIN_BENH_VIEN]}
          icon={<IconLibAntD.SaveOutlined />}
          onClick={(e) => {
            form.submit();
            e.stopPropagation();
          }}
        >
          Lưu
        </Button>,
      ]}
      collapsible={true}
    >
      <ProForm<UpdateCompanyRequestDTO>
        layout={"vertical"}
        labelAlign={"right"}
        submitter={false}
        form={form}
        labelWrap={true}
        initialValues={data?.data}
        onFinish={handleFormSubmit}
        disabled={isFormSubmitting}
      >
        <ProFormGroup>
          <ProFormText
            name="name"
            label="Tên bệnh viện"
            placeholder="Tên bệnh viện/phòng khám"
            width={"lg"}
            rules={[
              {
                required: true,
                transform: (value) => value.trim(),
                message: "Đây là trường bắt buộc",
              },
            ]}
          />
        </ProFormGroup>
        <ProFormGroup>
          <ProFormText
            name="address"
            label="Địa chỉ"
            placeholder="Tên bệnh viện/phòng khám"
            width={"lg"}
          />
          <ProFormText
            name="phone"
            label="Số điện thoại"
            placeholder="Tên bệnh viện/phòng khám"
            width={"lg"}
          />
        </ProFormGroup>
        <ProFormGroup>
          <ProFormText
            name="homepage"
            label="Trang chủ"
            placeholder="Tên bệnh viện/phòng khám"
            width={"lg"}
          />
          <ProFormText
            name="email"
            label="Địa chỉ email"
            placeholder="Tên bệnh viện/phòng khám"
            width={"lg"}
          />
        </ProFormGroup>
      </ProForm>
    </ProCard>
  );
};
