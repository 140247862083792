import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "@app/hook";
import { getAuthState } from "@selectors";
import { pathConfig } from "@config/PathConfig";

interface IRequireAuth {
  children: JSX.Element;
}

interface IRequireOwnerPermission {
  children: JSX.Element;
}
interface IRequireAdminPermission {
  children: JSX.Element;
}

export function RequireAuth({ children }: IRequireAuth) {
  let location = useLocation();
  const AuthState = useAppSelector(getAuthState);
  if (!AuthState.isAuthenticated) {
    return (
      <Navigate
        to={`/${pathConfig.account.login.getFullPath}`}
        state={{
          from: location,
          path: location.pathname,
        }}
      />
    );
  }

  return children;
}

export function RequireOwnerPermission({ children }: IRequireOwnerPermission) {
  let location = useLocation();
  const AuthState = useAppSelector(getAuthState);
  if (!AuthState.isOwner) {
    return (
      <Navigate
        to={`/${pathConfig.error.unauthorized.getFullPath}`}
        state={{
          from: location,
          path: location.pathname,
        }}
      />
    );
  }
  return children;
}

export function RequireAdminPermission({ children }: IRequireAdminPermission) {
  let location = useLocation();
  const AuthState = useAppSelector(getAuthState);
  if (!AuthState.isAdmin) {
    return (
      <Navigate
        to={`/${pathConfig.error.unauthorized.getFullPath}`}
        state={{
          from: location,
          path: location.pathname,
        }}
      />
    );
  }
  return children;
}
