import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FETCH_STATUS } from "@api";
import { FetchBranchDetailDTO } from "@dto/request/branchRequest";
import { DepartmentState } from "@store/department/type";
import { DepartmentModel } from "../../models/department.model";

export const initialState: DepartmentState = {
  fetchStatus: {},
  data: {},
};

export const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    actFetchDepartmentDetail: (
      state,
      { payload }: PayloadAction<FetchBranchDetailDTO>
    ) => {
      state.fetchStatus = {
        ...state.fetchStatus,
        [payload.id]: FETCH_STATUS.LOADING,
      };
    },
    actFetchDepartmentDetailSuccess: (
      state,
      { payload }: PayloadAction<DepartmentModel>
    ) => {
      state.fetchStatus = {
        ...state.fetchStatus,
        [payload.id]: FETCH_STATUS.SUCCESS,
      };
      state.data = {
        ...state.data,
        [payload.id]: payload,
      };
    },
    actFetchDepartmentDetailFailed: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.fetchStatus = {
        ...state.fetchStatus,
        [payload]: FETCH_STATUS.FAILED,
      };
    },
  },
});

export const {
  actFetchDepartmentDetail,
  actFetchDepartmentDetailFailed,
  actFetchDepartmentDetailSuccess,
} = departmentSlice.actions;

export default departmentSlice.reducer;
