import api from "./api";
import {
  CreateBranchDTO,
  FindBranchRequestDTO,
  GetBranchListChildrenDTO,
  GetBranchListDepartmentDTO,
  UpdateBranchDTO,
} from "@dto/request/branchRequest";

export const BranchService = {
  find: (data: FindBranchRequestDTO) => {
    return api
      .callWithToken()
      .get("/api/v1/branch", {
        params: data,
      })
      .then((res) => res.data);
  },
  get: (id: string) => {
    return api
      .callWithToken()
      .get(`/api/v1/branch/${id}`)
      .then((res) => res.data);
  },
  create: (data: CreateBranchDTO) => {
    return api
      .callWithToken()
      .post(`/api/v1/branch`, data)
      .then((res) => res.data);
  },
  update: (id: string, data: UpdateBranchDTO) => {
    return api
      .callWithToken()
      .patch(`/api/v1/branch/${id}`, data)
      .then((res) => res.data);
  },
  delete: (id: string) => {
    return api
      .callWithToken()
      .delete(`/api/v1/branch/${id}`)
      .then((res) => res.data);
  },
  getBranchListDepartment: (id: string, data: GetBranchListDepartmentDTO) => {
    return api
      .callWithToken()
      .get(`/api/v1/branch/${id}/department`, {
        params: data,
      })
      .then((res) => res.data);
  },
  getBranchListChildren: (id: string, data: GetBranchListChildrenDTO) => {
    return api
      .callWithToken()
      .get(`/api/v1/branch/${id}/children`, {
        params: data,
      })
      .then((res) => res.data);
  },
  getBranchListDepartmentTreeView: (id: string) => {
    return api
      .callWithToken()
      .get(`/api/v1/branch/${id}/department/tree-view`)
      .then((res) => res.data);
  },
};
