import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "@app/hook";
import { getAuthState } from "@selectors";
import { pathConfig } from "@config/PathConfig";

const UnauthorizedAccessPage: React.FC = () => {
  const AuthState = useAppSelector(getAuthState);
  return (
    <Result
      status="warning"
      title="401. Unauthorized access"
      subTitle="You are not authorized to access this page"
      extra={[
        (AuthState.isOwner || AuthState.isAdmin) && (
          <Button type="primary">
            <Link
              to={`/${
                pathConfig[AuthState.isOwner ? "owner" : "admin"].dashboard
                  .getFullPath
              }`}
            >
              Navigate to dashboard
            </Link>
          </Button>
        ),
        <Button type="primary">Log out</Button>,
      ]}
    />
  );
};

export default UnauthorizedAccessPage;
