import {
  faCodeBranch,
  faStore,
  faUsers,
  faHouse,
  faGear,
  faBuilding,
  faAddressCard,
  faStethoscope,
  faFileSignature,
  faScrewdriverWrench,
  faSitemap,
  faHospital,
  faHouseMedical,
  faWrench,
  faHospitalUser,
  faBedPulse,
  faBell,
  faLocationDot,
  faPhone,
  faFax,
  faEnvelope,
  faHouseChimney,
  faReceipt,
  faCubes,
  faUserTag,
  faUserShield,
  faHouseChimneyMedical,
  faScrewdriver,
} from "@fortawesome/free-solid-svg-icons";

import {
  PartitionOutlined,
  HomeOutlined,
  RightOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
  SettingOutlined,
  DownOutlined,
  BellOutlined,
  PhoneOutlined,
  BackwardOutlined,
  ArrowLeftOutlined,
  SaveOutlined,
  ReloadOutlined,
  FileImageOutlined,
  FolderFilled,
  UploadOutlined,
  CloudUploadOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  FolderAddOutlined,
  HomeFilled,
  AppstoreOutlined,
  CheckOutlined,
  UnorderedListOutlined,
  RollbackOutlined,
  ToolOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { ReactComponent as SVGIconFolder } from "./icon-library/svg/folder.svg";

export const iconLib = {
  faCodeBranch,
  faStore,
  faUsers,
  faHouse,
  faGear,
  faBuilding,
  faAddressCard,
  faStethoscope,
  faFileSignature,
  faScrewdriverWrench,
  faSitemap,
  faHospital,
  faHouseMedical,
  faWrench,
  faHospitalUser,
  faBedPulse,
  faBell,
  faLocationDot,
  faPhone,
  faFax,
  faEnvelope,
  faHouseChimney,
  faReceipt,
  faCubes,
  faUserTag,
  faUserShield,
  faHouseChimneyMedical,
  faScrewdriver,
};

export const IconLibAntD = {
  PartitionOutlined,
  HomeOutlined,
  RightOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
  SettingOutlined,
  DownOutlined,
  BellOutlined,
  PhoneOutlined,
  BackwardOutlined,
  ArrowLeftOutlined,
  SaveOutlined,
  ReloadOutlined,
  FileImageOutlined,
  FolderFilled,
  UploadOutlined,
  CloudUploadOutlined,
  SortAscendingOutlined,
  FolderAddOutlined,
  HomeFilled,
  AppstoreOutlined,
  SortDescendingOutlined,
  CheckOutlined,
  UnorderedListOutlined,
  RollbackOutlined,
  ToolOutlined,
  QuestionCircleOutlined,
};

export const SVGIconLib = {
  SVGIconFolder,
};
