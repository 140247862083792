import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Input, Upload, Skeleton, Avatar, Image } from "antd";
import ProForm, {
  ProFormCascader,
  ProFormFieldSet,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-form";
import styles from "./tab.account.style.module.less";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@app/hook";
import { getAuthState } from "@selectors";
import { TNPEmpty } from "@components/TNPComponent";
import { asyncUpdateProfileFormSubmit, systemMessage } from "@utils";
import {
  actGetProfileSuccess,
  actUploadProfilePhotoSuccess,
} from "@store/auth/authSlice";
import { URL_UPLOAD_PROFILE_PHOTO } from "@api";
import { CommonService, TokenService } from "@services";

const TabAccount: React.FC = () => {
  const { t } = useTranslation();
  const AuthState = useAppSelector(getAuthState);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleFinish = async (formData: Record<string, any>) => {
    try {
      setLoading(true);
      const { data } = await asyncUpdateProfileFormSubmit(formData);
      dispatch(
        actGetProfileSuccess({
          ...data,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const AvatarView = ({ avatar }: { avatar: string }) => {
    const access_token = TokenService.getLocalAccessToken();
    const props = {
      name: "file",
      action: URL_UPLOAD_PROFILE_PHOTO,
      headers: {
        authorization: `Bearer ${access_token}`,
      },
      beforeUpload: (file: any) => {
        const key = "WARNING_INVALID_FILE_TYPE";
        const validFileType = ["image/png", "image/jpeg"];
        if (!validFileType.includes(file.type)) {
          systemMessage.showWarning(
            key,
            t("layout.rule.invalid-image-type", {
              ns: "common",
              defaultValue: "Chỉ được chọn tập tin ảnh (jpg/png)",
            })
          );
          return Upload.LIST_IGNORE;
        }
        if (file.size > 1024 * 1024) {
          systemMessage.showWarning(
            key,
            t("layout.rule.invalid--image-file-size", {
              ns: "common",
              defaultValue:
                "Chỉ được chọn tập tin ảnh có kích thước tối đa 1MB",
            })
          );
          return Upload.LIST_IGNORE;
        }

        return true;
      },
      onChange(info: any) {
        const key = "FORM_UPLOAD_PROFILE_PHOTO";
        switch (info.file.status) {
          case "uploading":
            systemMessage.showLoading(key);
            break;
          case "done":
            dispatch(
              actUploadProfilePhotoSuccess({
                photoURL: info.file.response.data,
              })
            );
            systemMessage.showSuccess(key);
            break;
          default:
            // Error
            systemMessage.showError(key);
            break;
        }
      },
    };
    return (
      <>
        <div className={styles.avatar_title}>
          {t("layout.label.avatar", {
            ns: "common",
            defaultValue: "Ảnh đại diện",
          })}
        </div>
        <div className={styles.avatar}>
          <Avatar src={avatar} size={144} shape="circle" />
          {/*<img src={avatar} alt="avatar" />*/}
        </div>
        {/*<ImgCrop */}
        {/*  rotate*/}
        {/*  onModalOk={onChange}*/}
        {/*  modalOk={t("layout.text.OK", {*/}
        {/*    ns: "common",*/}
        {/*    defaultValue: "OK",*/}
        {/*  })}*/}
        {/*  modalCancel={t("layout.text.cancel", {*/}
        {/*    ns: "common",*/}
        {/*    defaultValue: "Hủy",*/}
        {/*  })}*/}
        {/*  modalTitle={t("layout.label.upload_photo", {*/}
        {/*    ns: "common",*/}
        {/*    defaultValue: "Tải ảnh lên",*/}
        {/*  })}*/}
        {/*>*/}
        {/* Temporary remove cut & crop dialog, // TODO enhance later*/}
        <Upload showUploadList={false} {...props}>
          <div className={styles.button_view}>
            <Button>
              <UploadOutlined />
              Tải ảnh lên
            </Button>
          </div>
        </Upload>
        {/*</ImgCrop>*/}
      </>
    );
  };

  return (
    <div className={styles.baseView}>
      {AuthState.loading && false ? null : (
        <>
          <div className={styles.left}>
            {!AuthState.currentUser && <Skeleton active />}
            {AuthState.currentUser && (
              <ProForm
                layout="vertical"
                onFinish={handleFinish}
                submitter={{
                  resetButtonProps: {
                    style: {
                      display: "none",
                    },
                  },
                  submitButtonProps: {
                    children: "Cập nhật",
                    loading,
                  },
                  searchConfig: {
                    submitText: t("layout.update", {
                      ns: "common",
                      defaultValue: "Cập nhật",
                    }),
                  },
                }}
                initialValues={{
                  email: AuthState.currentUser.email,
                  displayName: AuthState.currentUser.displayName,
                  // phone: `currentUser?.phone.split("-")`,
                }}
              >
                <ProFormText
                  width="md"
                  name="email"
                  requiredMark={true}
                  disabled
                  placeholder={t("layout.placeholder.email", {
                    ns: "common",
                    defaultValue: "Vui lòng nhập địa chỉ email hợp lệ",
                  })}
                  label={t("label.email", {
                    ns: "common",
                    defaultValue: "Email",
                  })}
                  rules={[
                    {
                      required: true,
                      message: t("rule.email-required", {
                        ns: "common",
                        defaultValue: "Email là trường bắt buộc",
                      }),
                    },
                  ]}
                />
                <ProFormText
                  width="md"
                  name="displayName"
                  label={t("layout.label.display_name", {
                    ns: "common",
                    defaultValue: "Tên hiển thị",
                  })}
                  requiredMark={true}
                  placeholder={t("layout.placeholder.display_name", {
                    ns: "common",
                    defaultValue: "Vui lòng nhập Tên hiển thị",
                  })}
                  rules={[
                    {
                      required: true,
                      message: "Tên hiển thị không được để trống!",
                    },
                  ]}
                />
                <ProFormTextArea
                  name="profile"
                  label="Tiểu sử"
                  placeholder="Tiểu sử cá nhân"
                />
                {/*<ProFormSelect*/}
                {/*  width="sm"*/}
                {/*  name="country"*/}
                {/*  label={t("layout.label.country_territory", {*/}
                {/*    ns: "common",*/}
                {/*    defaultValue: "Quốc gia/Vùng lãnh thổ",*/}
                {/*  })}*/}
                {/*  placeholder={t("layout.placeholder.country_territory", {*/}
                {/*    ns: "common",*/}
                {/*    defaultValue: "Vui lòng chọn Quốc gia/Vùng lãnh thổ",*/}
                {/*  })}*/}
                {/*  options={[*/}
                {/*    {*/}
                {/*      label: "Việt Nam",*/}
                {/*      value: "VIETNAM",*/}
                {/*    },*/}
                {/*  ]}*/}
                {/*/>*/}

                <ProForm.Group
                  title="Địa chỉ"
                  size={8}
                  tooltip={
                    <p>
                      <strong>Chú thích</strong>
                      <br />
                      Tp: Thành phố, T: Tỉnh;
                      <br />
                      Q: Quận, H: Huyện, Tx: Thị xã;
                      <br />
                      X: Xã, P: Phường; Tt: Thị trấn
                    </p>
                  }
                >
                  {/*<ProFormCascader*/}
                  {/*  width="md"*/}
                  {/*  fieldProps={{*/}
                  {/*    notFoundContent: <TNPEmpty description={"Trống"} />,*/}
                  {/*    showSearch: true,*/}
                  {/*    displayRender: (labels, selectedOptions) => {*/}
                  {/*      const shortLables = labels.map((item) =>*/}
                  {/*        item*/}
                  {/*          ? item*/}
                  {/*              .toString()*/}
                  {/*              .replace("Tỉnh", "T. ")*/}
                  {/*              .replace("Thành phố", "Tp. ")*/}
                  {/*              .replace("Quận", "Q. ")*/}
                  {/*              .replace("Thị xã", "Tx. ")*/}
                  {/*              .replace("Huyện", "H. ")*/}
                  {/*              .replace("Phường", "P. ")*/}
                  {/*              .replace("Xã", "X. ")*/}
                  {/*              .replace("Thị trấn", "Tt. ")*/}
                  {/*          : ""*/}
                  {/*      );*/}
                  {/*      return shortLables.reverse().join(", ");*/}
                  {/*    },*/}
                  {/*  }}*/}
                  {/*  name="address"*/}
                  {/*  placeholder={t("layout.placeholder.address", {*/}
                  {/*    ns: "common",*/}
                  {/*    defaultValue: "Vui lòng nhập địa chỉ",*/}
                  {/*  })}*/}
                  {/*  className={styles.item}*/}
                  {/*  request={async () => CommonService.getProvinceData()}*/}
                  {/*/>*/}
                </ProForm.Group>
                <ProFormText
                  width="md"
                  name="street-address"
                  label={t("layout.label.address", {
                    ns: "common",
                    defaultValue: "Số nhà",
                  })}
                  placeholder={t("layout.placeholder.address", {
                    ns: "common",
                    defaultValue: "Vui lòng nhập số nhà, đường, khu phố",
                  })}
                />
                <ProFormFieldSet
                  name="phone"
                  label="Liên hệ"
                  /*rules={[
                    {
                      required: true,
                      message: "请输入您的联系电话!",
                    },
                    { validator: validatorPhone },
                  ]}*/
                >
                  <Input className={styles.area_code} />
                  <Input className={styles.phone_number} />
                </ProFormFieldSet>
              </ProForm>
            )}
          </div>
          <div className={styles.right}>
            {!AuthState.currentUser || (
              <AvatarView
                avatar={
                  AuthState.currentUser.photoURL ||
                  `${process.env.PUBLIC_URL}/assets/images/no-profile-photo.jpg`
                }
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TabAccount;
