import i18n from "@i18n";

interface IMessageTranslation {
  [key: string]: string;
}

export const keyValuePairTranslation: IMessageTranslation = {
  "auth/too-many-requests": i18n.t(
    "login.firebase.errors.auth_too_many_request",
    {
      ns: "pages",
      defaultValue:
        "Tài khoản bị khóa tạm thời do đăng nhập quá nhiều lần, để kích hoạt lại tài khoản vui lòng chọn Quên mật khẩu và đặt mật khẩu mới",
    }
  ),
  "auth/wrong-password": i18n.t("login.firebase.errors.auth_wrong_password", {
    ns: "pages",
    defaultValue: "Sai mật khẩu hoặc tên đăng nhập",
  }),
  "auth/user-not-found": i18n.t("login.firebase.errors.auth_user_not_found", {
    ns: "pages",
    defaultValue: "Không tìm thấy tài khoản email này",
  }),
  "auth/user-disabled": i18n.t("login.firebase.errors.auth_user_disabled", {
    ns: "pages",
    defaultValue: "Tài khoản này chưa kích hoạt hoặc bị vô hiệu hóa",
  }),
  "auth/network-request-failed": i18n.t(
    "login.firebase.errors.auth_network_request_failed",
    {
      ns: "pages",
      defaultValue: "Kết nối đến máy chủ xác thực thất bại",
    }
  ),
  "auth/invalid-action-code": i18n.t(
    "login.firebase.errors.auth_invalid_action_code",
    {
      ns: "pages",
      defaultValue:
        "Yêu cầu đặt lại mật khẩu của bạn đã hết hạn hoặc liên kết đã được sử dụng",
    }
  ),
  "auth/timeout": i18n.t("login.firebase.errors.timeout", {
    ns: "pages",
    defaultValue: "Hết thời gian chờ kết nối máy chủ",
  }),
  // Prisma Error
  P2002: i18n.t("error.prisma.P2002", {
    ns: "common",
    defaultValue:
      "Lỗi máy chủ dữ liệu, vui lòng báo cáo quản trị viên để khắc phục",
  }),
  // Server error:
  "server/disabled-feature": i18n.t("error.server.disabled-feature", {
    ns: "common",
    defaultValue:
      "Website đang trong giai đoạn xây dựng, server tạm khóa tính năng đăng ký tài khoản mới. Xin lỗi về sự bất tiện này...",
  }),
};
