import React, { useState, useEffect } from "react";
import { ClockCircleTwoTone, CalendarTwoTone } from "@ant-design/icons";
import { Space, Typography } from "antd";

const { Text } = Typography;
type Props = {
  hideDate?: boolean;
};

const AppClock: React.FC<Props> = (
  { hideDate }: Props = { hideDate: false }
) => {
  const [dateState, setDateState] = useState(new Date());
  useEffect(() => {
    let isMounted = true; // note mutable flag
    setInterval(() => {
      if (isMounted) setDateState(new Date());
    }, 500);
    return () => {
      isMounted = false;
    }; // use cleanup to toggle value, if unmounted
  }, []);
  return (
    <Space>
      {/* DATE */}
      {!hideDate && (
        <>
          <CalendarTwoTone />
          <Text style={{ cursor: "pointer" }}>
            {dateState.toLocaleDateString("vi-VN", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </Text>
        </>
      )}
      {/* CLOCK */}
      <>
        <ClockCircleTwoTone />
        <Text style={{ cursor: "pointer" }}>
          {dateState.toLocaleString("vi-VN", {
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
          })}
        </Text>
      </>
    </Space>
  );
};

export default AppClock;
