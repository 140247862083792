import api from "./api";
import { LanguageService } from "./language.service";

const baseURL = process.env.REACT_APP_COMMON_API_URL;
export const CommonService = {
  getProvinceData: () => {
    return api
      .call(baseURL)
      .get("/api/v1/don-vi-hanh-chinh/tinh-thanh-pho?depth=3")
      .then((response) =>
        Promise.resolve(
          response.data.data.map(({ name, code, districts }: any) => ({
            label: name,
            value: code,
            children: districts.map(({ code, name, wards }: any) => ({
              label: name,
              value: code,
              children: wards.length
                ? wards.map(({ code, name }: any) => ({
                    label: name,
                    value: code,
                  }))
                : undefined,
            })),
          }))
        )
      );
  },
  getMasterdata: (
    groupCode: string,
    language: string = LanguageService.getSystemLanguage()
  ) => {
    return api
      .call(baseURL)
      .get(`/api/v1/welab/masterdata/${groupCode}/${language}`)
      .then((response) => {
        return Promise.resolve(response.data.data);
      });
  },
  getSelect: (from: string, where: any, query?: any) => {
    return api
      .callWithToken()
      .get(`api/v1/common/select/${from}`, {
        params: {
          ...query,
          condition: where,
        },
      })
      .then((res) => res.data);
  },
};
