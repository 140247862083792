import React from "react";
import {
  DownOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Menu, Spin } from "antd";
import HeaderDropdown from "../HeaderDropdown";
import styles from "./index.module.less";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@app/hook";
import { getAuthState } from "@selectors";
import { MenuInfo } from "rc-menu/lib/interface";
import { actLogout } from "@store/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { IconLibAntD } from "@components/AppFaIcon/icon-lib";
import Text from "antd/es/typography/Text";
import AppClock from "@components/AppClock";

export type GlobalHeaderRightProps = {
  menu?: boolean;
};

const menuItemKey = {
  logout: "logout",
  settings: "settings",
  // profile: "profile",
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({ menu }) => {
  const { t } = useTranslation();
  const AuthState = useAppSelector(getAuthState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!AuthState.currentUser) return loading;

  const handleMenuItemClick = ({ key }: MenuInfo) => {
    switch (key) {
      case menuItemKey.logout:
        dispatch(actLogout());
        break;
      // case menuItemKey.profile:
      //   navigate(`/${pathConfig.owner.profile.getFullPath}`);
      //   break;
      default:
        break;
    }
  };

  const menuHeaderDropdown = (
    <Menu
      className={styles.menu}
      selectedKeys={[]}
      onClick={handleMenuItemClick}
      items={
        menu
          ? [
              // {
              //   label: t("layout.profile", {
              //     ns: "common",
              //     defaultValue: "Tài khoản",
              //   }),
              //   key: menuItemKey.profile,
              //   icon: <UserOutlined />,
              // },
              {
                label: t("layout.settings", {
                  ns: "common",
                  defaultValue: "Cài đặt",
                }),
                key: menuItemKey.settings,
                icon: <SettingOutlined />,
              },
              {
                label: t("layout.log_out", {
                  ns: "common",
                  defaultValue: "Đăng xuất",
                }),
                key: menuItemKey.logout,
                icon: <LogoutOutlined />,
              },
            ]
          : []
      }
    />
  );
  return (
    <HeaderDropdown overlay={menuHeaderDropdown} arrow={false}>
      <span className={`${styles.action} ${styles.notificationBellIcon}`}>
        <Avatar
          size="small"
          icon={<UserOutlined />}
          src={AuthState.currentUser.photoURL}
          className={styles.avatar}
          style={{ marginRight: 10 }}
          alt="avatar"
        />
        <span style={{ maxWidth: 100 }} className={`${styles.name} anticon`}>
          {/*<Text ellipsis={{}}>{AuthState.currentUser.displayName}</Text>*/}
        </span>
        <IconLibAntD.DownOutlined />
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
