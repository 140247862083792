import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, AuthUser } from "@store/auth/type";
import { TokenService } from "@services";
import {
  confirmEmailRequestDTO,
  LoginRequestDTO,
  RegisterRequestDTO,
} from "@dto/request/authRequest";
import { getTranslation, systemMessage } from "@utils";
import i18n from "@i18n";
import { AxiosResponse } from "axios";
import { UploadProfilePhotoDTO } from "@dto/response/authResponse";
import { history } from "../../router/history/browser";
import { pathConfig } from "@config/PathConfig";

export const initialState: AuthState = {
  isAuthenticated: false,
  error: "",
  loading: false,
  isOwner: false,
  isAdmin: false,
};

// noinspection JSUnusedLocalSymbols
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    actLogin: (state, { payload, type }: PayloadAction<LoginRequestDTO>) => {
      state.error = "";
      state.loading = true;
    },
    actLoginSuccess: (state, { payload }: PayloadAction<AuthUser>) => {
      TokenService.updateLocalAccessToken(
        payload.accessToken,
        payload.refreshToken
      );
      state.error = "";
      state.isAuthenticated = true;
      state.currentUser = payload;
      state.isOwner = payload.isOwner;
      state.company = payload.company;
      state.isAdmin = payload.isAdmin;
      state.loading = false;
    },
    actLoginFailed: (
      state,
      { payload }: PayloadAction<Pick<AxiosResponse, "data">>
    ) => {
      // Set state
      state.loading = false;
      state.isAuthenticated = false;
      state.currentUser = undefined;
      // Process error message and show to user
      const { data } = payload;
      state.error = getTranslation(
        data?.error,
        i18n.t("login.firebase.errors.unknown_error", {
          ns: "pages",
          defaultValue:
            "Có lỗi xảy ra, vui lòng liên hệ với quản trị viên để được hỗ trợ",
        })
      );
      systemMessage.showError(actLoginFailed.type, state.error);
      TokenService.removeAllLocalTokens();
    },
    actLogout: (state) => {
      TokenService.removeAllLocalTokens();
      return initialState;
    },
    actGetProfile: (state) => {
      state.loading = true;
    },
    actGetProfileSuccess: (state, { payload }: PayloadAction<AuthUser>) => {
      state.isAuthenticated = true;
      state.currentUser = payload;
      state.loading = false;
      state.isOwner = payload.isOwner;
      state.company = payload.company;
      state.isAdmin = payload.isAdmin;
    },
    actUploadProfilePhotoSuccess: (
      state,
      { payload }: PayloadAction<UploadProfilePhotoDTO>
    ) => {
      if (state.currentUser) state.currentUser.photoURL = payload.photoURL;
    },
    actRegister: (state, { payload }: PayloadAction<RegisterRequestDTO>) => {
      state.error = "";
      state.loading = true;
      state.currentUser = undefined;
      state.registeredUser = undefined;
      state.isAuthenticated = false;
      TokenService.removeAllLocalTokens();
    },
    actRegisterSuccess: (state, { payload }: PayloadAction<AuthUser>) => {
      state.error = "";
      state.loading = false;
      state.isAuthenticated = false;
      state.registeredUser = payload;
      // Cần nha
      history.push(`/${pathConfig.account.welcome.getFullPath}`);
    },
    actRegisterWelcome: (state) => {
      state.registeredUser = undefined;
    },
    actRegisterFailed: (
      state,
      { payload }: PayloadAction<Pick<AxiosResponse, "data">>
    ) => {
      const { data } = payload;
      state.error = getTranslation(data?.error);
      systemMessage.showError(actLoginFailed.type, state.error);
      state.loading = false;
      state.isAuthenticated = false;
      state.registeredUser = undefined;
      state.currentUser = undefined;
    },
    actSetLoading: (state, { payload }: PayloadAction<boolean>) => {
      // Set loading for actions other than register and login
      state.loading = payload;
    },
    actResetError: (state) => {
      state.error = "";
    },
    actConfirmEmail: (
      state,
      { payload }: PayloadAction<confirmEmailRequestDTO>
    ) => {
      state.error = "";
      state.loading = true;
      state.confirmedUser = undefined;
    },
    actConfirmEmailSuccess: (state, { payload }: PayloadAction<AuthUser>) => {
      state.error = "";
      state.loading = false;
      state.confirmedUser = payload;
    },
    actConfirmEmailFail: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
      state.confirmedUser = undefined;
    },
  },
});

export const {
  actLogin,
  actLogout,
  actLoginFailed,
  actLoginSuccess,
  actGetProfile,
  actGetProfileSuccess,
  actUploadProfilePhotoSuccess,
  actRegister,
  actRegisterFailed,
  actRegisterSuccess,
  actRegisterWelcome,
  actSetLoading,
  actResetError,
  actConfirmEmail,
  actConfirmEmailSuccess,
  actConfirmEmailFail,
} = authSlice.actions;

export default authSlice.reducer;
