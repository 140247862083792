import React from "react";
import ProForm, {
  ModalForm,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-form";
import { BranchService } from "@services";
import { CreateBranchDTO, UpdateBranchDTO } from "@dto/request/branchRequest";
import { systemMessage } from "@utils";
import { ActionType } from "@ant-design/pro-table";
import { BranchModel } from "@models/branch.models";

type Props = {
  id?: string;
  companyId?: string;
  trigger: JSX.Element;
  parentRef: React.MutableRefObject<ActionType | undefined>;
  data?: BranchModel;
};

interface IFormData {
  id?: string;
  name: string;
  address: string;
}

const ModalUpsertBranch: React.FC<Props> = ({
  id,
  companyId,
  trigger,
  parentRef,
  data,
}: Props) => {
  const handleOnFinish = async (values: IFormData): Promise<boolean> => {
    const formKey = id
      ? "ASYNC_ACTION_UPDATE_BRANCH"
      : "ASYNC_ACTION_CREATE_BRANCH";
    try {
      systemMessage.showLoading(formKey);
      if (!id) {
        await BranchService.create({
          companyId,
          ...values,
        } as CreateBranchDTO);
      } else {
        await BranchService.update(id, values as UpdateBranchDTO);
      }
      systemMessage.showSuccess(formKey);
      parentRef.current?.reload();
    } catch (error) {
      systemMessage.showError(formKey);
    }
    return Promise.resolve(true);
  };
  return (
    <ModalForm<IFormData>
      title={id ? "Chỉnh sửa" : "Thêm mới"}
      trigger={trigger}
      autoFocusFirstInput
      modalProps={{
        maskClosable: false,
        destroyOnClose: true,
        centered: true,
      }}
      width={420}
      onFinish={handleOnFinish}
      submitter={{
        searchConfig: {
          submitText: id ? "Cập nhật" : "Thêm mới",
          resetText: "Đóng",
        },
      }}
      initialValues={
        data && {
          name: data.name,
          address: data.address,
        }
      }
    >
      <ProForm.Group>
        <ProFormText
          width="xl"
          name="name"
          label="Tên chi nhánh"
          rules={[
            {
              required: true,
              message: "Trường bắt buộc",
            },
            {
              max: 255,
              message: "Tối đa 255 ký tự",
            },
          ]}
          fieldProps={{
            maxLength: 255,
          }}
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormTextArea
          rules={[
            {
              required: true,
              message: "Trường bắt buộc",
            },
          ]}
          width="xl"
          name="address"
          label="Địa chỉ"
        />
      </ProForm.Group>
    </ModalForm>
  );
};

export default ModalUpsertBranch;
