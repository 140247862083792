import api, { IApiResponseList } from "./api";
import { SortingQuery } from "../interfaces/ModalMeta.interface";

export const UserService = {
  find: (data: any, sort?: SortingQuery): Promise<IApiResponseList<any>> => {
    return api
      .callWithToken()
      .get("/api/v1/user", {
        params: { ...data, ...sort },
      })
      .then((res) => res.data);
  },
};
