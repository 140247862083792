import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  actFetchBranchDetail,
  actFetchBranchDetailFailed,
  actFetchBranchDetailSuccess,
} from "@store/branch/branchSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { BranchService } from "@services";
import { FetchBranchDetailDTO } from "@dto/request/branchRequest";
import { BranchModel } from "../models/branch.models";

function* fetchBranchDetail({ payload }: PayloadAction<FetchBranchDetailDTO>) {
  const { id } = payload;
  try {
    const { data } = yield call(BranchService.get, id);
    yield put(actFetchBranchDetailSuccess(data as BranchModel));
  } catch (error) {
    yield put(actFetchBranchDetailFailed(id));
  }
}

function* watchFetchBranchDetail() {
  yield takeLatest<any>(actFetchBranchDetail.type, fetchBranchDetail);
}

export default function* branchSaga() {
  yield all([fork(watchFetchBranchDetail)]);
}
