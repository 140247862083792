import React, { useState, useRef, useLayoutEffect, useCallback } from "react";
import { GridContent } from "@ant-design/pro-layout";
import { Menu } from "antd";
import styles from "./style.module.less";
import TabAccount from "@pages/common/ProfilePage/components/TabAccount";
import TabSecutiry from "@pages/common/ProfilePage/components/TabSecurity";

type SettingsStateKeys = "account" | "security";
type SettingsState = {
  mode: "inline" | "horizontal";
  selectKey: SettingsStateKeys;
};

export const ProfilePage: React.FC = () => {
  const menuMap: Record<string, React.ReactNode> = {
    account: "Thông tin tài khoản",
    security: "Bảo mật",
  };

  const [initConfig, setInitConfig] = useState<SettingsState>({
    mode: "inline",
    selectKey: "account",
  });
  const dom = useRef<HTMLDivElement>();

  const resize = useCallback(() => {
    requestAnimationFrame(() => {
      if (!dom.current) {
        return;
      }
      let mode: "inline" | "horizontal" = "inline";
      const { offsetWidth } = dom.current;
      if (dom.current.offsetWidth < 641 && offsetWidth > 400) {
        mode = "horizontal";
      }
      if (window.innerWidth < 768 && offsetWidth > 400) {
        mode = "horizontal";
      }
      setInitConfig((initConfig) => ({
        ...initConfig,
        mode: mode as SettingsState["mode"],
      }));
    });
  }, []);

  useLayoutEffect(() => {
    if (dom.current) {
      window.addEventListener("resize", resize);
      resize();
    }
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resize]);

  const getMenu = () => {
    return Object.keys(menuMap).map((item) => ({
      label: menuMap[item],
      key: item,
    }));
  };

  const renderChildren = () => {
    const { selectKey } = initConfig;
    switch (selectKey) {
      case "account":
        return <TabAccount />;
      case "security":
        return <TabSecutiry />;
      default:
        return null;
    }
  };

  return (
    <GridContent>
      <div
        className={styles.main}
        ref={(ref) => {
          if (ref) {
            dom.current = ref;
          }
        }}
      >
        <div className={styles.leftMenu}>
          <Menu
            mode={initConfig.mode}
            selectedKeys={[initConfig.selectKey]}
            onClick={({ key }) => {
              setInitConfig({
                ...initConfig,
                selectKey: key as SettingsStateKeys,
              });
            }}
            items={getMenu()}
          />
        </div>
        <div className={styles.right}>
          <div className={styles.title}>{menuMap[initConfig.selectKey]}</div>
          {renderChildren()}
        </div>
      </div>
    </GridContent>
  );
};
