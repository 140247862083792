import { ProColumns } from "@ant-design/pro-table";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import * as React from "react";
import { pathConfig } from "@config/PathConfig";
import { Link } from "react-router-dom";
import SwitchCompanyOnOff from "@components/featured-components/SwitchCompanyOnOff";

export const columns = (
  handleDeleteRecord: (id: string) => Promise<any>
): ProColumns[] => [
  {
    title: "STT",
    width: 50,
    align: "center",
    search: false,
    render: (text, record, _) => _ + 1,
  },
  {
    title: "Công ty",
    key: "name",
    dataIndex: "name",
    render: (text, record, _, action) => (
      <Link to={`/${pathConfig.owner.company.getFullPath}/${record.id}`}>
        {record.name}
      </Link>
    ),
    // fieldProps: {
    //   // placeholder: "Nhập dữ liệu"
    // },
    // fieldProps: {},
  },
  {
    title: "Địa chỉ",
    key: "address",
    dataIndex: "address",
    // fieldProps: {
    //   // placeholder: "Nhập dữ liệu",
    // },
  },
  {
    title: "Điện thoại",
    key: "phone",
    dataIndex: "phone",
  },
  {
    title: "Trang chủ",
    key: "homepage",
    dataIndex: "homepage",
  },
  {
    title: "Trạng thái",
    key: "active",
    dataIndex: "active",
    valueType: "switch",
    width: 100,
    render: (text, record, _, action) => {
      return <SwitchCompanyOnOff id={record.id} active={record.active} />;
    },
  },
  {
    title: "Thao tác",
    valueType: "option",
    width: 100,
    render: (text, record, _, action) => [
      <Popconfirm
        key={record.id}
        title={"Bạn có chắc chắn muốn xoá mục này?"}
        onConfirm={() => {
          handleDeleteRecord(record.id).then(() => Promise.resolve());
          return Promise.resolve();
        }}
      >
        <Button danger icon={<DeleteOutlined />}>
          Xoá
        </Button>
      </Popconfirm>,
    ],
  },
];
