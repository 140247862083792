import React, { useRef } from "react";
import { Button, Empty, Popconfirm, Tooltip } from "antd";
import ProList from "@ant-design/pro-list";
import { useAppSelector } from "@app/hook";
import { getCompanyState } from "@selectors";
import { Link } from "react-router-dom";
import isUUID from "validator/lib/isUUID";
import OrgBreadcrumb, {
  getLink,
} from "@layouts/owner/company/sub-pages/detail-company/organization/org-breadcrumb";
import { BranchService } from "@services";
import Text from "antd/es/typography/Text";
import ModalUpsertBranch from "@layouts/owner/company/sub-pages/detail-company/organization/sub-component/modal-upsert-branch";
import { PlusOutlined } from "@ant-design/icons";
import { IconLibAntD } from "@components/AppFaIcon/icon-lib";
import { ActionType } from "@ant-design/pro-table";
import { systemMessage, truncateWithEllipses } from "@utils";
import { AxiosResponse } from "axios";
import { BranchModel } from "@models/branch.models";

type Props = {
  companyId: string;
  tabKey: string;
};

const CompanyOrganization: React.FC<Props> = ({ companyId, tabKey }: Props) => {
  const CompanyState = useAppSelector(getCompanyState);
  const ref = useRef<ActionType>();
  if (companyId && !isUUID(companyId)) return <p>Incorrect object id</p>;

  const handleDeleteBranch = async (id: string): Promise<any> => {
    const formKey = "ASYNC_ACTION_DELETE_BRANCH";
    try {
      systemMessage.showLoading(formKey);
      const result = await BranchService.delete(id);
      systemMessage.showSuccess(formKey);
      ref.current?.reload();
      return result.data;
    } catch (error) {
      systemMessage.showError(formKey);
      return Promise.reject(error);
    }
  };

  return (
    <ProList<any>
      actionRef={ref}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
      }}
      pagination={
        CompanyState.data[companyId] && {
          defaultPageSize: 50,
          showSizeChanger: false,
          hideOnSinglePage: true,
          showTotal: (total, range) => (
            <div>{`Hiển thị ${range[0]}-${range[1]} trong tổng ${total} chi nhánh`}</div>
          ),
        }
      }
      toolbar={{
        title: <OrgBreadcrumb tabKey={tabKey} companyId={companyId} />,
        actions: [
          <ModalUpsertBranch // Form create
            key="create"
            companyId={companyId}
            trigger={
              <Button type="primary">
                <PlusOutlined />
                Tạo chi nhánh mới
              </Button>
            }
            parentRef={ref}
          />,
        ],
      }}
      request={async ({ pageSize, current, ...params }) => {
        return BranchService.find({
          pageSize,
          current,
        })
          .then((response) => Promise.resolve(response.data))
          .catch((error) => console.log(error));
      }}
      rowKey={(row) => row.id}
      showActions="hover"
      grid={{
        gutter: 16,
        column: 3,
        xs: 1,
        sm: 1,
        md: 2,
        lg: 1,
        xl: 3,
        xxl: 4,
      }}
      onItem={(record: any) => {
        return {
          onMouseEnter: () => {
            // TODO
          },
          onClick: () => {
            // navigate(record.id);
          },
        };
      }}
      metas={{
        title: {
          dataIndex: "name",
          render: (_, row) => (
            <Link to={"/" + getLink(tabKey, companyId, row.id)}>
              {row.name.length <= 15 ? (
                row.name
              ) : (
                <Tooltip title={row.name}>
                  {truncateWithEllipses(row.name, 15)}
                </Tooltip>
              )}
            </Link>
          ),
        },
        subTitle: {
          // render: (_, row) => <Tag color="#5BD8A6">Tag</Tag>,
        },
        type: {},
        avatar: {
          render: (_, row) =>
            "https://gw.alipayobjects.com/zos/antfincdn/UCSiy1j6jx/xingzhuang.svg",
        },
        content: {
          render: (_, row) => (
            <Text ellipsis={{ tooltip: row.address }}>{row.address}</Text>
          ),
        },
        actions: {
          cardActionProps: "extra",
          render: (text, row) => [
            <ModalUpsertBranch //Form Edit
              id={row.id}
              companyId={row.companyId}
              trigger={<Button icon={<IconLibAntD.EditOutlined />} />}
              parentRef={ref}
              key={`update_${row.id}`}
              data={row as BranchModel}
            />,
            <Popconfirm
              key={`delete_${row.id}`}
              title="Bạn có chắc chắn muốn xoá chi nhánh này?"
              placement={"topRight"}
              onConfirm={() => {
                return handleDeleteBranch(row.id);
              }}
            >
              <Button danger icon={<IconLibAntD.DeleteOutlined />} />
            </Popconfirm>,
          ],
        },
      }}
    />
  );
};

export default CompanyOrganization;
