import api from "./api";
import {
  CreateCategoryTitleRequestDTO,
  FindCategoryTitleRequestDTO,
  UpdateCategoryTitleRequestDTO,
} from "@dto/request/categoryRequest";

const baseUrl = "/api/v1/category";

const title = {
  find: (data: FindCategoryTitleRequestDTO) => {
    return api
      .callWithToken()
      .get(`${baseUrl}/title`, {
        params: data,
      })
      .then((res) => res.data);
  },
  update: (id: string, data: UpdateCategoryTitleRequestDTO) => {
    return api
      .callWithToken()
      .patch(`${baseUrl}/title/${id}`, data)
      .then((res) => res.data);
  },
  create: (data: CreateCategoryTitleRequestDTO) => {
    return api
      .callWithToken()
      .post(`${baseUrl}/title/`, data)
      .then((res) => res.data);
  },
  delete: (id: string) => {
    return api
      .callWithToken()
      .delete(`${baseUrl}/title/${id}`)
      .then((res) => res.data);
  },
};

export const CategoryService = {
  title,
};
