import { en, vi } from "@config/LanguageConfig";
import { LanguageService } from "@services";

export enum ContractType {
  Paid = "PAID",
  Trial = "TRIAL",
}

export enum OrgType {
  Branch = "BRANCH",
  Office = "OFFICE",
  Department = "DEPARTMENT",
}

const getTranslation = (
  language: string,
  obj: {
    [key: string]: string;
  }
) => {
  if (obj[language]) return obj[language];
  return "Error";
};

export const MASTERDATA = {
  LICENSE_TYPE: (language: string = LanguageService.getSystemLanguage()) => [
    {
      value: ContractType.Trial,
      get label() {
        return getTranslation(language, {
          [vi]: "Dùng thử",
          [en]: "Trial",
        });
      },
    },
    {
      value: ContractType.Paid,
      get label() {
        return getTranslation(language, {
          [vi]: "Trả phí",
          [en]: "Paid",
        });
      },
    },
  ],
  DATE_UNIT: (language: string = LanguageService.getSystemLanguage()) => [
    {
      value: "days" as DATE_TIME_UNIT,
      get label() {
        return getTranslation(language, {
          [vi]: "Ngày",
          [en]: "Days",
        });
      },
    },
    {
      value: "months" as DATE_TIME_UNIT,
      get label() {
        return getTranslation(language, {
          [vi]: "Tháng",
          [en]: "Months",
        });
      },
    },
    {
      value: "years" as DATE_TIME_UNIT,
      get label() {
        return getTranslation(language, {
          [vi]: "Năm",
          [en]: "Years",
        });
      },
    },
  ],
};

export type DATE_TIME_UNIT = "days" | "months" | "years";
export const NEW_RECORD_PREFIX = "new-record";
