import React, { useEffect } from "react";
import ProForm, {
  ModalForm,
  ProFormDependency,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  ProFormTreeSelect,
} from "@ant-design/pro-form";
import { ActionType } from "@ant-design/pro-table";
import {
  BranchService,
  CommonService,
  CompanyService,
  DepartmentService,
  OfficeService,
} from "@services";
import { systemMessage } from "@utils";
import { OfficeModel } from "@models/office.model";
import { TNPEmpty } from "@components/TNPComponent";
import { useAppSelector } from "@app/hook";
import { getOrganizationState } from "@selectors";
import { Select, TreeSelect } from "antd";
import ProFormItem from "@ant-design/pro-form/es/components/FormItem";
import useForm = ProForm.useForm;
import { CreateOfficeDTO, UpdateOfficeDTO } from "@dto/request/officeRequest";

type Props = {
  id?: string;
  companyId: string;
  branchId?: string;
  departmentId: string | undefined | null;
  trigger?: JSX.Element;
  parentRef?: React.MutableRefObject<ActionType | undefined>;
  data?: OfficeModel;
  visible?: boolean;
  onVisibleChange?: ((visible: boolean) => void) | undefined;
  reloadOrganizationTree?: () => void | undefined;
};

interface IFormData {
  id?: string;
  name: string;
  description?: string;
  departmentId: string;
  branchId?: string;
}

const ModalUpsertOffice: React.FC<Props> = ({
  id,
  companyId,
  branchId,
  trigger,
  parentRef,
  data,
  visible,
  onVisibleChange,
  departmentId,
  reloadOrganizationTree,
}: Props) => {
  const [form] = useForm();
  const OrganizationState = useAppSelector(getOrganizationState);
  const handleOnFinish = async (values: IFormData): Promise<boolean> => {
    const formKey = id
      ? "ASYNC_ACTION_UPDATE_OFFICE"
      : "ASYNC_ACTION_CREATE_OFFICE";
    try {
      systemMessage.showLoading(formKey);
      // TODO
      if (!id) {
        // Create
        const data: CreateOfficeDTO = {
          name: values.name,
          departmentId: values.departmentId,
          description: values.description,
        };
        await OfficeService.create(data);
      } else {
        //Edit
        const data: UpdateOfficeDTO = {
          name: values.name,
          departmentId: values.departmentId,
          description: values.description,
        };
        await OfficeService.update(id, data);
      }
      parentRef?.current?.reload();
      reloadOrganizationTree && reloadOrganizationTree();
      systemMessage.showSuccess(formKey);
    } catch (error) {
      systemMessage.showError(formKey);
    }
    return Promise.resolve(true);
  };
  return (
    <ModalForm<IFormData>
      form={form}
      title={id ? "Chỉnh sửa" : "Thêm mới"}
      trigger={trigger}
      visible={visible}
      autoFocusFirstInput
      modalProps={{
        maskClosable: false,
        destroyOnClose: true,
        centered: true,
      }}
      width={420}
      onFinish={handleOnFinish}
      onVisibleChange={(visible) => {
        onVisibleChange && onVisibleChange(visible);
        form.setFieldsValue({
          name: id ? data?.name : "",
          description: "",
          branchId,
          departmentId,
        } as IFormData);
      }}
      initialValues={
        data
          ? {
              // LOAD FROM DATA IN CASE EDIT
              // ...data,
              name: data.name,
              description: data?.json_data?.["description"],
              departmentId: data?.json_data?.["departmentId"],
            }
          : ({
              // DEFAULT IN CASE CREATE NEW
              name: "",
              description: "",
              departmentId: departmentId,
            } as IFormData)
      }
      submitter={{
        searchConfig: {
          submitText: id ? "Cập nhật" : "Thêm mới",
          resetText: "Đóng",
        },
      }}
    >
      <ProForm.Group>
        <ProFormText
          width="xl"
          name="name"
          label="Tên phòng"
          rules={[
            {
              required: true,
              message: "Trường bắt buộc",
            },
            {
              max: 255,
              message: "Tối đa 255 ký tự",
            },
          ]}
          fieldProps={{
            maxLength: 255,
            // addonBefore: (
            //   <Select style={{ width: 60 }} notFoundContent={"Trống"}>
            //     <Option>
            //       <AppIconGallery iconName={"first-aid-kit-5961.svg"} />
            //     </Option>
            //   </Select>
            // ),
          }}
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormTextArea width="xl" name="description" label="Địa chỉ" />
      </ProForm.Group>
      <ProFormSelect
        label={"Cơ sở"}
        name={"branchId"}
        rules={[{ required: true, message: "Trường bắt buộc" }]}
        fieldProps={{
          notFoundContent: <TNPEmpty description={"Trống"} />,
          onChange: () => {
            form.setFieldsValue({
              departmentId: null,
            });
          },
        }}
        request={async () =>
          CommonService.getSelect(
            "branch",
            {
              deleted: false,
            },
            {
              orderBy: "name",
              direction: "ascend",
            }
          ).then((result) =>
            result.data.map((item: any) => ({
              value: item.id,
              label: item.name,
            }))
          )
        }
      />
      <ProFormDependency
        name={["branchId"]}
        rules={[{ required: true, message: "Trường bắt buộc" }]}
      >
        {({ branchId }) => {
          const map = (e: any, rootId?: string) => {
            const match = e.id === rootId;
            return {
              value: e.id,
              title: e.name,
              children: e.children.map((item: any) =>
                map(item, match ? item.id : rootId)
              ), // recursive call
              disabled: match,
            } as { value: any; title: any; children: any; disabled?: boolean };
          };
          const treeData =
            OrganizationState.data?.[companyId]?.[branchId]?.children;
          const tree = treeData
            ? treeData.map((item: any) => map(item, id))
            : [];
          return (
            <ProFormItem
              label={"Đơn vị chủ quản"}
              name={"departmentId"}
              rules={[
                {
                  required: true,
                  message: "Trường bắt buộc",
                },
                {
                  validator: async () => {
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <TreeSelect
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                treeData={tree}
                placeholder="Please select"
                allowClear={true}
                treeLine={true}
              />
            </ProFormItem>
          );
        }}
      </ProFormDependency>
    </ModalForm>
  );
};

export default ModalUpsertOffice;
