import React, { useEffect } from "react";
import ProForm, {
  ModalForm,
  ProFormDependency,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  ProFormTreeSelect,
} from "@ant-design/pro-form";
import { ActionType } from "@ant-design/pro-table";
import {
  BranchService,
  CommonService,
  CompanyService,
  DepartmentService,
} from "@services";
import { systemMessage } from "@utils";
import {
  CreateDepartmentDTO,
  UpdateDepartmentDTO,
} from "@dto/request/departmentRequest";
import { DepartmentModel } from "@models/department.model";
import { TNPEmpty } from "@components/TNPComponent";
import { useAppSelector } from "@app/hook";
import { getOrganizationState } from "@selectors";
import { FETCH_STATUS } from "@api";
import { Select, TreeSelect } from "antd";
import ProFormItem from "@ant-design/pro-form/es/components/FormItem";
import useForm = ProForm.useForm;
import { AppIconGallery } from "@components/AppIconGallery";
const { Option } = Select;

type Props = {
  id?: string;
  companyId: string;
  branchId?: string;
  parentId?: string; // Initial parentId (departmentId) of existing data when edit
  trigger?: JSX.Element;
  parentRef?: React.MutableRefObject<ActionType | undefined>;
  data?: DepartmentModel;
  visible?: boolean;
  onVisibleChange?: ((visible: boolean) => void) | undefined;
  reloadOrganizationTree?: () => void | undefined;
  departmentId?: string; // Initial parentId (departmentId) when create new department
};

interface IFormData {
  id?: string;
  name: string;
  address?: string;
  branchId: string;
  parentId: string | null;
}

const ModalUpsertDepartment: React.FC<Props> = ({
  id,
  companyId,
  trigger,
  parentRef,
  data,
  visible,
  onVisibleChange,
  parentId,
  branchId,
  departmentId,
  reloadOrganizationTree,
}: Props) => {
  const [form] = useForm();
  const OrganizationState = useAppSelector(getOrganizationState);
  const handleOnFinish = async (values: IFormData): Promise<boolean> => {
    const formKey = id
      ? "ASYNC_ACTION_UPDATE_DEPARTMENT"
      : "ASYNC_ACTION_CREATE_DEPARTMENT";
    try {
      systemMessage.showLoading(formKey);
      if (!id) {
        if (!branchId) return Promise.resolve(true);
        const data: CreateDepartmentDTO = {
          ...values,
          branchId: values.branchId ? values.branchId : branchId,
          parentId: values.parentId ? values.parentId : null,
        };
        await DepartmentService.create(data);
      } else {
        const data: UpdateDepartmentDTO = {
          ...values,
          parentId: !values.parentId ? null : values.parentId,
        };
        await DepartmentService.update(id, data as UpdateDepartmentDTO);
      }
      parentRef?.current?.reload();
      reloadOrganizationTree && reloadOrganizationTree();
      systemMessage.showSuccess(formKey);
    } catch (error) {
      systemMessage.showError(formKey);
    }
    return Promise.resolve(true);
  };
  return (
    <ModalForm<IFormData>
      form={form}
      title={id ? "Chỉnh sửa" : "Thêm mới"}
      trigger={trigger}
      visible={visible}
      autoFocusFirstInput
      modalProps={{
        maskClosable: false,
        destroyOnClose: true,
        centered: true,
      }}
      width={420}
      onFinish={handleOnFinish}
      onVisibleChange={(visible) => {
        onVisibleChange && onVisibleChange(visible);
        form.setFieldsValue({
          name: data ? data.name : "",
          address: "",
          parentId: departmentId,
          branchId,
        } as IFormData);
      }}
      initialValues={
        data
          ? {
              // LOAD FROM DATA IN CASE EDIT
              // ...data,
              name: data.name,
              address: data?.json_data?.["address"],
              branchId: data?.json_data?.["branch_id"],
              parentId: data?.json_data?.["parent_id"],
            }
          : {
              // DEFAULT IN CASE CREATE NEW
              name: "",
              address: "",
              branchId,
              parentId: departmentId,
            }
      }
      submitter={{
        searchConfig: {
          submitText: id ? "Cập nhật" : "Thêm mới",
          resetText: "Đóng",
        },
      }}
    >
      <ProForm.Group>
        <ProFormText
          width="xl"
          name="name"
          label="Tên khoa"
          rules={[
            {
              required: true,
              message: "Trường bắt buộc",
            },
            {
              max: 255,
              message: "Tối đa 255 ký tự",
            },
          ]}
          fieldProps={{
            maxLength: 255,
            // addonBefore: (
            //   <Select style={{ width: 60 }} notFoundContent={"Trống"}>
            //     <Option>
            //       <AppIconGallery iconName={"first-aid-kit-5961.svg"} />
            //     </Option>
            //   </Select>
            // ),
          }}
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormTextArea width="xl" name="address" label="Địa chỉ" />
      </ProForm.Group>
      <ProFormSelect
        label={"Cơ sở"}
        name={"branchId"}
        rules={[{ required: true, message: "Trường bắt buộc" }]}
        fieldProps={{
          notFoundContent: <TNPEmpty description={"Trống"} />,
          onChange: () => {
            form.setFieldsValue({
              parentId: null,
            });
          },
        }}
        request={async () =>
          CommonService.getSelect(
            "branch",
            {
              deleted: false,
            },
            {
              orderBy: "name",
              direction: "ascend",
            }
          ).then((result) => {
            console.log(result);
            return result.data.map((item: any) => ({
              value: item.id,
              label: item.name,
            }));
          })
        }
      />
      <ProFormDependency name={["branchId"]}>
        {({ branchId }) => {
          const map = (e: any, rootId?: string) => {
            const match = e.id === rootId;
            return {
              value: e.id,
              title: e.name,
              children: e.children.map((item: any) =>
                map(item, match ? item.id : rootId)
              ), // recursive call
              disabled: match,
            } as { value: any; title: any; children: any; disabled?: boolean };
          };

          const treeData =
            OrganizationState.data?.[companyId]?.[branchId]?.children;
          const tree = treeData
            ? treeData.map((item: any) => map(item, id))
            : [];

          return (
            <ProFormItem
              label={"Đơn vị chủ quản"}
              name={"parentId"}
              rules={[
                {
                  validator: async () => {
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <TreeSelect
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                treeData={tree}
                placeholder="Please select"
                allowClear={true}
                treeLine={true}
              />
            </ProFormItem>
          );
        }}
      </ProFormDependency>
    </ModalForm>
  );
};

export default ModalUpsertDepartment;
