import React, { useState } from "react";
import { Button, Checkbox } from "antd";
import ProForm, { ModalForm, ProFormText } from "@ant-design/pro-form";
import {
  EnvironmentOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import ProCard from "@ant-design/pro-card";
import { DATE_TIME_UNIT, ContractType } from "@constants/index";
import moment from "moment";
import { calculateExpirationDate, systemMessage } from "@utils";
import { APP_DATE_FORMAT } from "@config/AppConfig";
import { merge } from "object-mapper";
import { CompanyService } from "@services";
import { CreateCompanyRequestDTO } from "@dto/request/companyRequest";
import isURL from "validator/lib/isURL";
import isEmail from "validator/lib/isEmail";
import FormAddContract from "@layouts/owner/company/sub-pages/detail-company/contract/form-add-contract";

type Props = {
  onSuccess: () => void;
};

const ModalAddCompany = ({ onSuccess }: Props) => {
  const [contractTimeUnit, setContractTimeUnit] =
    useState<DATE_TIME_UNIT>("days");
  const [createContract, setCreateContract] = useState<boolean>(false);

  const handleSetContractTimeUnit = (timeUnit: DATE_TIME_UNIT) => {
    setContractTimeUnit(timeUnit);
  };

  return (
    <ModalForm<{
      name: string;
      address?: string;
      email?: string;
      phone?: string;
      homepage?: string;
      licenseType: ContractType;
      periodLength: number;
      effectiveDate: Date | string;
      expirationDate: Date | string;
    }>
      title="Thêm công ty"
      trigger={
        <Button type="primary">
          <PlusOutlined />
          Thêm công ty
        </Button>
      }
      autoFocusFirstInput
      modalProps={{
        maskClosable: false,
        destroyOnClose: true,
        centered: true,
      }}
      onFinish={async (values) => {
        const formKey = "ASYNC_ACTION_CREATE_COMPANY";
        try {
          const effectiveDate = moment(
            values.effectiveDate,
            APP_DATE_FORMAT
          ).toISOString();
          const expirationDate = calculateExpirationDate(
            moment(values.effectiveDate, APP_DATE_FORMAT).startOf("day"),
            values.periodLength,
            contractTimeUnit
          ).toISOString();
          const requestData = merge(
            {
              name: values.name?.trim(),
              address: values.address?.trim() || null, // Object-mapper will not map null (in case empty string)
              phone: values.phone?.trim() || null,
              homepage: values.homepage?.trim() || null,
              email: values.email?.trim() || null,
              licenseType: values.licenseType,
              expirationDate: createContract ? expirationDate : undefined,
              effectiveDate: createContract ? effectiveDate : undefined,
            },
            {
              name: "name",
              address: "address",
              phone: "phone",
              homepage: "homepage",
              email: "email",
              licenseType: "contract.type",
              effectiveDate: "contract.effectiveDate",
              expirationDate: "contract.expirationDate",
            }
          ) as CreateCompanyRequestDTO;
          await CompanyService.create(requestData);
          systemMessage.showSuccess(formKey);
          onSuccess();
          return true;
        } catch (error) {
          systemMessage.showError(formKey);
          return false;
        }
      }}
      submitter={{
        searchConfig: {
          submitText: "Thêm",
          resetText: "Đóng",
        },
      }}
    >
      <ProCard
        // title="Thông tin chung"
        // bordered
        // headerBordered
        // collapsible
        style={{
          marginBottom: 0,
          // minWidth: 800,
          maxWidth: "100%",
        }}
      >
        <ProForm.Group>
          <ProFormText
            width="md"
            name="name"
            label="Tên công ty"
            placeholder="Vui lòng nhập tên công ty"
            fieldProps={{
              maxLength: 80,
            }}
            // normalize={(value) => value.replace(/\s+/g, " ").trimStart()}
            normalize={(value) => value.trimStart()}
            rules={[
              {
                whitespace: true,
                required: true,
                message: "Tên công ty là trường bắt buộc",
              },
            ]}
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormText
            width="md"
            name="address"
            label="Địa chỉ"
            placeholder="Vui lòng nhập địa chỉ công ty"
            normalize={(value) => value.trimStart()}
            fieldProps={{
              prefix: <EnvironmentOutlined />,
            }}
          />
          <ProFormText
            width="md"
            name="phone"
            label="Điện thoại"
            placeholder="Nhập số điện thoại, hotline,..."
            normalize={(value) => value.trimStart()}
            fieldProps={{
              prefix: <PhoneOutlined />,
            }}
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormText
            width="md"
            name="homepage"
            label="Website"
            placeholder="Nhập địa chỉ trang chủ"
            normalize={(value) => value.trimStart()}
            rules={[
              {
                validator: async (rule, value = "") => {
                  // This is optional fields so there empty fields should pass validator
                  if (isURL(value) || !value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("URL không hợp lệ"));
                },
              },
            ]}
            fieldProps={{
              prefix: <GlobalOutlined />,
            }}
          />
          <ProFormText
            width="md"
            name="email"
            label="Email"
            placeholder="Nhập địa chỉ email"
            normalize={(value) => value.trimStart()}
            rules={[
              {
                validator: async (rule, value = "") => {
                  // This is optional fields so there empty fields should pass validator
                  if (isEmail(value) || !value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Email không hợp lệ"));
                },
              },
            ]}
            fieldProps={{
              prefix: <MailOutlined />,
            }}
          />
        </ProForm.Group>
        <Checkbox
          checked={createContract}
          onChange={(event) => setCreateContract(event.target.checked)}
        >
          Lập hợp đồng mới
        </Checkbox>
      </ProCard>
      {createContract && (
        <ProCard
          title="Hợp đồng"
          bordered
          headerBordered
          collapsible
          style={{
            marginBottom: 0,
            // minWidth: 800,
            maxWidth: "100%",
          }}
        >
          <FormAddContract
            contractTimeUnit={contractTimeUnit}
            setContractTimeUnit={(timeUnit) =>
              handleSetContractTimeUnit(timeUnit)
            }
          />
        </ProCard>
      )}
    </ModalForm>
  );
};

export default ModalAddCompany;
