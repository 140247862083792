import * as React from "react";
import { ProColumns } from "@ant-design/pro-table";
import { Button, DatePicker, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, StopOutlined } from "@ant-design/icons";
import viVN from "antd/lib/locale-provider/vi_VN";
import Text from "antd/es/typography/Text";
import { getContractStatus } from "@utils";

export const columns = (
  handleDeleteRecord: (id: string) => Promise<any>
): ProColumns[] => [
  {
    title: "STT",
    width: 50,
    align: "center",
    search: false,
    render: (text, record, _) => _ + 1,
  },
  {
    title: "Mã hợp đồng",
    width: 150,
    key: "contractNo",
    dataIndex: "contractNo",
    sorter: true,
  },
  {
    title: "Loại",
    key: "type",
    dataIndex: "type",
    valueType: "select",
    sorter: true,
    valueEnum: {
      PAID: { text: "Trả phí" },
      TRIAL: { text: "Dùng thử" },
    },
  },
  {
    title: "Có hiệu lực từ",
    key: "effectiveDate",
    dataIndex: "effectiveDate",
    valueType: "dateTime",
    formItemProps: {
      label: "Ngày có hiệu lực",
    },
    renderFormItem: (_, { type, defaultRender, ...rest }, form) => {
      return <DatePicker locale={viVN.DatePicker} />;
    },
  },
  {
    title: "Có hiệu lực đến",
    key: "expirationDate",
    dataIndex: "expirationDate",
    valueType: "dateTime",
    formItemProps: {
      label: "Ngày hết hiệu lực",
    },
    renderFormItem: (_, { type, defaultRender, ...rest }, form) => {
      return <DatePicker locale={viVN.DatePicker} />;
    },
  },
  {
    title: "Ngày ký",
    key: "signedDate",
    dataIndex: "signedDate",
    search: false,
  },
  {
    title: "Tình trạng",
    key: "signedDate",
    dataIndex: "signedDate",
    search: false,
    render: (text, record, _) => (
      <Text>
        {getContractStatus(record.effectiveDate, record.expirationDate)}
      </Text>
    ),
  },

  {
    title: "Thao tác",
    valueType: "option",
    width: 100,
    render: (text, record, _, action) => [
      <Tooltip
        title={"Chấm dứt đồng cung cấp dịch vụ"}
        placement={"left"}
        key={`button-stop-contract-${record.id}`}
      >
        <Button icon={<StopOutlined />} />
      </Tooltip>,
      <Popconfirm
        key={`button-delete-contract-${record.id}`}
        title={"Bạn có chắc chắn muốn xoá mục này?"}
        placement={"topRight"}
        onConfirm={() => {
          handleDeleteRecord(record.id).then(() => Promise.resolve());
          return Promise.resolve();
        }}
      >
        <Tooltip title={"Xoá"} placement={"bottom"}>
          <Button danger icon={<DeleteOutlined />} />
        </Tooltip>
      </Popconfirm>,
    ],
  },
];
